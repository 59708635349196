import { AxiosError, AxiosResponse } from "axios";
import { Roles_AND_RESPONSIBILITY_URL } from "./endpoints/contractRR";

import { axiosInstance } from "./config/axiosConfig";

const getRolesAndResponsiblityList = (requesrParams: any): Promise<any> =>
  axiosInstance
    .get(Roles_AND_RESPONSIBILITY_URL.GetRolesAndResponsibility, { params: requesrParams })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getProjectDetail = (requesrParams: any): Promise<any> =>
  axiosInstance
    .get(Roles_AND_RESPONSIBILITY_URL.getProjectDetail, { params: requesrParams })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addRoleAndResponsiblity = (requestBody: any): Promise<any> =>
  axiosInstance
    .put(Roles_AND_RESPONSIBILITY_URL.UpdateRRWorkFlowprocess, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateRoleAndResponsiblity = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(Roles_AND_RESPONSIBILITY_URL.UpdateRolesAndResponsibility, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteRoleAndResponsiblity = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(Roles_AND_RESPONSIBILITY_URL.DeleteRolesAndResponsibility, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getRoleAndResponsiblityById = (req: any): Promise<any> =>
  axiosInstance
    .get(Roles_AND_RESPONSIBILITY_URL.GetRolesAndResponsibilityById, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getIsProjectExists = (req: any): Promise<any> =>
  axiosInstance
    .get(Roles_AND_RESPONSIBILITY_URL.IsProjectExists, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getRRMasterByworkflowId = (req: any): Promise<any> =>
  axiosInstance
    .get(Roles_AND_RESPONSIBILITY_URL.GetRRMasterByworkflowId, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getGetVCWorkflowProcesses = (req: any): Promise<any> =>
  axiosInstance
    .post(Roles_AND_RESPONSIBILITY_URL.GetVCWorkflowProcesses, req)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const contractRolesAndResponsibilityService = {
  getRolesAndResponsiblityList,
  addRoleAndResponsiblity,
  updateRoleAndResponsiblity,
  deleteRoleAndResponsiblity,
  getRoleAndResponsiblityById,
  getIsProjectExists,
  getProjectDetail,
  getRRMasterByworkflowId,
  getGetVCWorkflowProcesses,
};

export default contractRolesAndResponsibilityService;
