export const Roles_AND_RESPONSIBILITY_URL={
    AddRolesAndResponsibility: `/addProjectDetails`,
    IsProjectExists:`/isProjectExists`,
    getProjectDetail:`/getProjectDetail`,
    UpdateRolesAndResponsibility:`/`,
    DeleteRolesAndResponsibility:`/`,
    GetRolesAndResponsibility:`/`,
    GetRolesAndResponsibilityById: `/`,
    GetRRMasterByworkflowId: `/GetRRSetupmasterByWorkflowId `,
    GetVCWorkflowProcesses: "/VendorContract/GetVCWorkflowProcesses",
    UpdateRRWorkFlowprocess: "VendorContract/UpdateRRWorkflowProcesses"
}