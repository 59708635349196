import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { RootState, useAppDispatch } from "../../../store/store";
import { addVendorSignOff, getVendorSignOffbyId } from "../../../store/slices/vendorSignOff";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { LovConfig, serviceCriticality } from "../../../config/config";
import { getLovList } from "../../../store/slices/lovMasterData";
import { VOBProcessPreviews } from "../vendorOnBoardingProcessPreview/VOBProcessPreview";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { DownLoadAFile } from "../../../store/slices/fileHandler";
import { VendorHistory } from "../../vendorHistory/VendorHistory";

interface VendorOnboardingSignOffProps {
  vendor_Id: any;
  onClose: () => void;
  currentProcessData: any;
}

export const VendorOnboardingSignOff: React.FC<VendorOnboardingSignOffProps> = ({
  vendor_Id,
  onClose,
  currentProcessData,
}) => {
  const dispatch = useAppDispatch();
  const { themeMode } = useSelector((state: RootState) => state.menu);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { VendorSignOffdataTable } = useSelector((state: RootState) => state.vendorSignOff);
  const currentDate = dayjs().format("DD-MMM-YYYY");
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [signoffData, setSignoffData] = useState<any>({});
  const [remark, setRemark] = useState("");
  const [remarkerror, setErrorRemark] = useState<any>("");
  const [currentStatus, setcurrentStatus] = useState("signoff");
  const [VendorStatus, setVendorStatus] = useState<any>([]);
  const [previewsection, setpreviewsection] = useState<any>(false);
  const [isConform, setisConform] = useState<any>(false);
  const [submitstatus, setsubmitstatus] = useState<any>("");
  const [logo, setLogo] = useState<string>("");

  const isBase64Image = (image: any) => {
    return (
      image &&
      (image.startsWith("data:image/jpeg;base64,") ||
        image.startsWith("data:image/png;base64,") ||
        image.startsWith("data:image/jpg;base64,"))
    );
  };
  const handleImageDisplay = async (imageUrl: string): Promise<string | null> => {
    if (imageUrl && !isBase64Image(imageUrl)) {
      const resultAction = await dispatch(DownLoadAFile({ fileUrl: imageUrl }));
      if (DownLoadAFile.fulfilled.match(resultAction)) {
        const payloadData = resultAction.payload;
        if (payloadData && payloadData.length > 0) {
          const blob = "data:image/jpeg;base64," + payloadData;
          setLogo(blob);
          return blob;
        }
      }
    } else if (isBase64Image(imageUrl)) {
      return imageUrl;
    }
    return null;
  };
  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    setVendorStatus(lovTable.filter((item: any) => item.Name === LovConfig.VendorStatus) || []);
  }, [lovTable]);
  useEffect(() => {
    if (currentProcessData) {
      dispatch(
        getVendorSignOffbyId({
          vendor_Id: currentProcessData?.VendorId,
          project_Detail_Id: currentProcessData?.ProjectId,
        })
      );
    }
  }, [currentProcessData]);
  useEffect(() => {
    if (VendorSignOffdataTable.Vendor_Id === vendor_Id) {
      console.log(VendorSignOffdataTable, "VendorSignOffdataTable");
      setSignoffData(VendorSignOffdataTable);
      if (VendorSignOffdataTable?.Logo) {
        handleImageDisplay(VendorSignOffdataTable?.Logo);
      }
    }
  }, [VendorSignOffdataTable]);

  const closeFormValue = () => {
    if (onClose) {
      onClose();
    }
  };

  const conformTheSignoff = (val: any) => {
    if (remark) {
      setsubmitstatus(val);
      setisConform(true);
    } else {
      setErrorRemark("Remarks is required");
    }
  };

  const closeConformationPopup = () => {
    setsubmitstatus("");
    setisConform(false);
  };

  const submitvendorSignOff = () => {
    const val = submitstatus;
    if (remark) {
      setErrorRemark("");
      let findedStatus =
        VendorStatus.find((item: any) => item?.Options == (val == 1 ? "Active" : "Terminated"))?.LovId || 0;

      let obj: any = {
        ModuleName: currentProcessData?.FormName,
        Activity: val == 1 ? "Create" : "Rejected",
        UserId: userLoginResponse?.UserId,
        CreatedBy: userLoginResponse?.UserEmail,
        MenuId: currentPagePermissionData?.MenuId,
        FormId: currentProcessData?.ProcessId,
        Vendor_Id: currentProcessData?.VendorId,
        Project_Detail_Id: currentProcessData ? currentProcessData?.ProjectId : 0,
        IsRenewal: currentProcessData?.IsRenewal ? currentProcessData?.IsRenewal : false,
        Workflow_InstanceId: currentProcessData ? currentProcessData?.Workflow_InstanceId : 0,
        Process_InstanceId: currentProcessData ? currentProcessData?.Id : 0,
        Remarks: remark,
        Status: findedStatus,
      };

      dispatch(addVendorSignOff(obj));
    } else {
      setErrorRemark("Remarks is required");
    }
  };
  const getCriticalityColor = (criticality: any) => {
    switch (criticality) {
      case serviceCriticality.material:
        return "#F44336"; // Red
      case serviceCriticality.low_critical:
        return "#4CAF50"; // Green
      case serviceCriticality.critical:
        return "#FFC107"; // Amber
      default:
        return "#939393";
    }
  };

  const getdeviationColor = (criticality: any) => {
    switch (criticality) {
      case serviceCriticality.material:
        return "#F44336"; // Red
      case serviceCriticality.low_critical:
        return "#4CAF50"; // Green
      case serviceCriticality.critical:
        return "#FFC107"; // Amber
      default:
        return "#939393";
    }
  };

  const criticalityColor = getCriticalityColor(signoffData?.Service_Criticality);

  return (
    <>
      {!previewsection ? (
        <Box>
          <Stack spacing={4}>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
              <CustomBreadcrumbs
                list={[
                  { value: 0, name: "Pending Actions" },
                  { value: 1, name: `Sign off` },
                ]}
                onchange={closeFormValue}
              />

              {currentProcessData && (
                <Box sx={{width: "20", }}>
                <VendorHistory isEditData={currentProcessData} />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography variant="h6" color={"text.primary"}>
                {`Vendor Final Sign Off`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box sx={{ display: "flex", width: "100px", justifyContent: "center" }}>
                <Avatar
                  sx={{ width: 64, height: 64 }}
                  // alt="Travis Howard"
                  src={logo}
                >
                  {" "}
                  {/* N */}
                  {logo ? logo : "N"}
                </Avatar>
              </Box>
              <Box sx={{ display: "flex", width: "60%" }}>
                <Box sx={{ width: "100%" }}>
                  <Stack spacing={2}>
                    <Box
                      sx={{
                        height: "64px",
                        width: "100%",
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "20px !important", textAlign: "left" }} color={"text.primary"}>
                        {signoffData?.Legal_Name}
                      </Typography>

                      <Box sx={{ display: "flex", gap: 2, justifyContent: "start", mt: 1 }}>
                        <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                          {signoffData?.Project_Name} -
                        </Typography>
                        <Typography
                          onClick={() => {
                            setpreviewsection(true);
                          }}

                          color={"text.primary"}
                          sx={{ textAlign: "left", cursor: "pointer", textDecoration: "underline" }}
                        >
                          Vendor Assessment Details
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                            <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                              {`Services Mapped`}
                            </Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                              {signoffData?.No_Of_Service_Mapped}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                            <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                              {`Service Period `}
                            </Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                              {signoffData?.Project_Start_Date
                                ? dayjs(signoffData.Project_Start_Date).format("DD-MMM-YYYY")
                                : ""}
                              {" - "}
                              {signoffData?.Project_End_Date
                                ? dayjs(signoffData.Project_End_Date).format("DD-MMM-YYYY")
                                : ""}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                            <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                              {`Service Owner `}
                            </Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                              {signoffData?.Service_Owner_Name !== ""
                                ? signoffData?.Service_Owner_Name
                                : ""}
                            </Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                              {signoffData?.Service_Owner_Department !== "" ? `[${signoffData?.Service_Owner_Department}]` : ""}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                            <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                              {`TP Risk`}
                            </Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                              {signoffData?.TP_Risk_Rating !== "" ? signoffData?.TP_Risk_Rating : "-"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                            <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                              {`Assessment  Summary`}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              backgroundColor: themeMode === 'dark' ? 'background.paper' : "#F7F7F7",
                              padding: 2,
                              flexDirection: "column",
                              gap: 4,
                            }}
                          >
                            <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                              <Typography sx={{ textAlign: "left", width: "30%" }} color={"text.primary"}>
                                {`Number of Questions`}
                              </Typography>
                              <Typography sx={{ textAlign: "left", fontWeight: 600 }} color={"text.primary"}>
                                {signoffData?.No_Of_Questions}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                              <Typography sx={{ textAlign: "left", width: "30%" }} color={"text.primary"}>
                                {`Number of Outliers`}
                              </Typography>
                              <Typography sx={{ textAlign: "left", fontWeight: 600 }} color={"text.primary"}>
                                {signoffData?.No_Of_Outliers}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                              <Typography sx={{ textAlign: "left", width: "30%" }} color={"text.primary"}>
                                {`Assessment Score Deviation `}
                              </Typography>
                              <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                                {signoffData?.Assessment_Score_Deviation !== undefined
                                  ? `${signoffData.Assessment_Score_Deviation.toFixed(2)} %`
                                  : "0 %"}
                              </Typography>
                              {signoffData?.Assessment_Score_Deviation_Rating && <Box
                                sx={{
                                  backgroundColor: criticalityColor,
                                  padding: "4px",
                                  borderRadius: "8px",
                                  height: "24px",
                                  color: "white !important",
                                }}
                              >
                                <Typography sx={{ textAlign: "center" }} color={"white"}>
                                  {signoffData?.Assessment_Score_Deviation_Rating}
                                </Typography>
                              </Box>}
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                              <Typography sx={{ textAlign: "left", width: "30%" }} color={"text.primary"}>
                                {`Inherent Risk Score`}
                              </Typography>
                              <Typography sx={{ textAlign: "left" }} color="text.primary">
                                {signoffData?.Inherent_Risk_Score !== undefined
                                  ? `${signoffData.Inherent_Risk_Score.toFixed(2)} %`
                                  : "0.00 %"}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                              <Typography sx={{ textAlign: "left", width: "30%" }} color={"text.primary"}>
                                {`Service Criticality`}
                              </Typography>
                              <Box
                                sx={{
                                  backgroundColor: criticalityColor,
                                  padding: "4px",
                                  borderRadius: "8px",
                                  height: "24px",
                                  color: "white !important",
                                }}
                              >
                                <Typography sx={{ textAlign: "center" }} color={"white"}>
                                  {signoffData?.Service_Criticality}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                            <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                              {`Remarks`}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <CustomMultilineTextField
                            label={"Remarks"}
                            value={remark}
                            rows={4}
                            required={true}
                            onChange={(e) => {
                              setRemark(e);
                              if (e) {
                                setErrorRemark("");
                              } else {
                                setErrorRemark("Remark is required");
                              }
                            }}
                            error={Boolean(remarkerror)}
                            helperText={remarkerror}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                            <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                              {`Approved By:`}
                            </Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                              {userLoginResponse?.UserName} {" - ("} {userLoginResponse?.UserRole}
                              {")"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Box sx={{ display: "flex", width: "100%", gap: 2, justifyContent: "right" }}>
                            <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                              {`Approved Date:`}
                            </Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                              {currentDate}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                  <Box sx={{ display: "flex", gap: 2, justifyContent: "start", mt: 4 }}>
                    {false && currentStatus === "terminate" && (
                      <>
                        <CustomButton
                          type="submit"
                          variant="contained"
                          color="error"
                          name={"Terminate"}
                          onClick={() => {
                            console.log();
                          }}
                        />
                      </>
                    )}

                    {currentStatus === "signoff" && (
                      <>
                        <CustomButton
                          type="submit"
                          variant="contained"
                          color="success"
                          name={"Sign Off"}
                          onClick={() => conformTheSignoff(1)}
                        />
                        <CustomButton
                          type="submit"
                          variant="contained"
                          color="error"
                          name={"Terminate"}
                          onClick={() => conformTheSignoff(0)}
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Stack>
          <ModalPopUp
            onDeleteMethod={() => submitvendorSignOff()}
            isDelete={true}
            onHide={() => closeConformationPopup()}
            show={isConform}
            ActionName={"Complete"}
            header={"Confirm"}
            text={`Are you sure you want to ${submitstatus === 1 ? "Sign off" : "Terminate"}  the Vendor ?`}
          />
        </Box>
      ) : (
        <VOBProcessPreviews
          vendor_Id={vendor_Id}
          currentProcessData={currentProcessData}
          onClose={(e: any) => {
            if (e === 0) {
              closeFormValue();
            } else if (e === 1) {
              setpreviewsection(false);
            }
          }}
        />
      )}
    </>
  );
};
