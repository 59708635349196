import dayjs from "dayjs";
import { createContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { VOBPreview } from "./VendorOnBoardPreview";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { VOBSections } from "./VendorOnboardingSections";
import { ApplicationParameterName, Default_threshold, MetrixMasterConfig, vendorassetroles, VOBWorflowPages } from "../../../config/config";
import {
  addVendorAssessment,
  getVendorAssessment,
  getVendorAssessmentbyVendorId,
} from "../../../store/slices/vendorAssessment";
import { ConvertGetData, validateAssessmentAll } from "./AssessmentValidation";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { FinalAssessmentScoring } from "./FinalAssesmentScoring";
import { RecordSearch } from "../../recordSearch/RecordSearch";
import { getRiskRateList } from "../../../store/slices/riskRateMasterData";
import { getLovList } from "../../../store/slices/lovMasterData";
import { Mockdata } from "./mockData";
import { ViewChanges } from "./viewChanges";
import { getViewChanges } from "../../../store/slices/vendorData";
import { DownLoadAFile } from "../../../store/slices/fileHandler";
import { VendorHistory } from "../../vendorHistory/VendorHistory";
import { getMetricsMasterList } from "../../../store/slices/metricsMasters";
import { getApplicationParameterList } from "../../../store/slices/applicationParameterData";
import workFlowService from "../../../store/services/workFlowService";
import CustomSvg from "../../../common/CustomSvg";
import { ThresholdChangeForm } from "./ThresholdChangeForm";

interface assesmentContextProps {
  selectedCatagoryPreview: any[];
  validationErrors: any;
  setselectedCatagoryPreview: (value: any) => void;
  setvalidationErrors: (value: any) => void;
}

export interface VOBAssesmentProps {
  issmeReview?: any;
  vendor_Id: any;
  onClose?: () => void;
  currentProcessData?: any;
  isPreviewMode?: boolean;
  isdisableBreadCrums?: boolean;
}

export const AssessmentDetailContext = createContext<assesmentContextProps | undefined>(undefined);

export const VendorOnboardingAssessment: React.FC<VOBAssesmentProps> = ({
  issmeReview,
  vendor_Id,
  onClose,
  currentProcessData,
  isPreviewMode,
  isdisableBreadCrums,
}) => {
  const dispatch = useAppDispatch();
  const { VendorAssessmentdata , thresholdforVendorAssessment } = useSelector((state: RootState) => state.vendorAssessmentData);
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const theme = useTheme()
  const { metricsMasterData } = useSelector((state: RootState) => state.metricsMasters);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const { viewChanges } = useSelector((state: RootState) => state.vendorData);
  const [currentRole, setcurrentRole] = useState<any>("");
  const [isEditData, setisEditData] = useState<any>({});
  const [view, setview] = useState<boolean>(false);
  const [changethreshold, setchangethreshold] = useState<boolean>(false);
  const [oncloseScoring, setoncloseScoring] = useState<boolean>(false);
  const [viewChangesShown, setViewChangesShown] = useState<boolean>(false);
  const [selectedCatagoryPreview, setselectedCatagoryPreview] = useState<any[]>([]);
  const [validationErrors, setvalidationErrors] = useState<any>({});
  const [vendorChanges, setVendorChanges] = useState<any[]>([]);
  const [deviationmetricsRiskType,setdeviationmetricsRiskType] = useState<any[]>([]);
  const [processStepsList,setprocessStepsList] = useState<any[]>([]);
  const [question, setQuestion] = useState<string | null>(null);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [ThresholdData, setThresholdData] = useState<any>("");

  const handleRecordSearch = (Question: string) => {
    setQuestion(Question);
    setIsDialogVisible(true);
  };
 
  useEffect(() => {
    try {
      if (isEditData && isEditData.Vendor_Id) {
        dispatch(getViewChanges({ Vendor_Id: isEditData.Vendor_Id }));
      }
    } catch (e: any) {
      console.error("Error fetching view changes:", e);
    }
  }, [isEditData]);

  const findDefaultthreshold = () => {
    const data =  applicationParameterData ? applicationParameterData
      .filter((param: any) => param?.Parameter_Name === ApplicationParameterName.Threshold && param?.IsActive === "Active")
      .map((param) => param.Parameter_Value) : Default_threshold ;
    return  Number(data) 
  };
  useEffect(() => {
    const callInstanceData = async () => {
      try {
        if (currentProcessData) {
          const instanceValue = await workFlowService.getWorkflowInstanceByVendorId({
            vendorId:  currentProcessData?.VendorId,
            Project_Detail_Id: currentProcessData?.ProjectId,
          });
          let resposseData = instanceValue?.data;
          try{
            let processSteps : any = JSON.parse(resposseData?.WorkflowProcessInstanceAsJson)
           
            setprocessStepsList(processSteps)
          }catch{

          }
         
         
        }}catch{
             
        }
    }

    if(currentProcessData){
      callInstanceData()
    }
 
  },[])

  useEffect(() => {
   
    if(thresholdforVendorAssessment){
      setThresholdData(thresholdforVendorAssessment);
    }
  },[thresholdforVendorAssessment])

  console.log(vendorChanges, "vendorChangesvendorChanges")

  let formData = {
    Assessment_Questionaire_Mapping_Id: isEditData ? isEditData?.Assessment_Questionaire_Mapping_Id : 0,
    Assessment_Number: isEditData ? isEditData?.Assessment_Number : "",
    Vendor_Id: isEditData ? isEditData?.Vendor_Id : "",
    Validity_in_Months: isEditData ? isEditData?.Validity_in_Months : 12,
    Assessment_Type_1: isEditData ? isEditData?.Assessment_Type_1 : "",
    Assessment_Type_2: isEditData ? isEditData?.Assessment_Type_2 : "",
    WorkFlow_Id: isEditData ? isEditData?.WorkFlow_Id : "",
    Start_Date: isEditData ? dayjs(isEditData?.Start_Date) : null,
    Target_Date: isEditData ? dayjs(isEditData?.Target_Date) : null,
  };

  const vendorAssesmentDetailsFormMethods = useForm<any>({ defaultValues: formData, mode: "onChange" });

  const {
    handleSubmit: vendorAssesmentDetailsHandleSubmit,
    setValue: vendorAssesmentDetailssetValue,
    formState: { errors: errorsVendorAssessmentDetailsform, isDirty: vendorassessmentDetailsIsDirty },
    getValues: vendorAssessmentDetailsGetValues,
  } = vendorAssesmentDetailsFormMethods;

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(
      getRiskRateList({
        FormID: 2,
      })
    );
    dispatch(getMetricsMasterList({}));
    dispatch(
      getApplicationParameterList({
        FormID: 2,
      })
    );
  }, []);
  useEffect(() => {
    if (currentProcessData) {
      console.log(currentProcessData, "currentProcessData");
      if (isPreviewMode) {
        dispatch(
          getVendorAssessmentbyVendorId({
            Vendor_Id: vendor_Id,
            Project_Detail_Id: currentProcessData?.ProjectId || 0,
          })
        );
      } else {
        dispatch(
          getVendorAssessment({
            Vendor_Id: vendor_Id,
            RoleId: userLoginResponse?.RoleId || "",
            UserId: userLoginResponse?.UserId || "",
            IsSmeReview: issmeReview ? true : 0,
            Project_Detail_Id: currentProcessData?.ProjectId || 0,
          })
        );
      }
    }
  }, [currentProcessData]);

  useEffect(() => {
    if (metricsMasterData) {
      console.log(metricsMasterData, "metricsMasterData");
      let findeddeviationmetrixType = metricsMasterData.filter(
        (risk_item) => risk_item?.Metrics === MetrixMasterConfig.Deviation_Matrix && risk_item?.IsActive === "Active"
      );
      setdeviationmetricsRiskType(findeddeviationmetrixType || []);
        
    }
  }, [metricsMasterData]);

  useEffect(() => {
    if (userLoginResponse?.UserRole && !isPreviewMode) {
      console.log(userLoginResponse?.UserRole, "userLoginResponse?.UserRole");

      if (userLoginResponse?.UserRole === vendorassetroles.SME) {
        if (issmeReview) {
          setcurrentRole("SME");
        } else {
          setcurrentRole("SMEE");
        }

        setview(true);
      }
      if (userLoginResponse?.UserRole === vendorassetroles.DO) {
        setcurrentRole("DO");
      }

      if (userLoginResponse?.UserRole === vendorassetroles.SO) {
        setcurrentRole("SO");
      }
    }
    if (isPreviewMode) {
      setcurrentRole("Cxo");
    }
  }, [userLoginResponse]);

  const validateAllFields = () => {
    let isValid = true;
    let allErrors: { [key: string]: string } = validateAssessmentAll(selectedCatagoryPreview, currentRole);

    setvalidationErrors(allErrors);
    console.log(allErrors, "allErrors");

    if (Object.keys(allErrors).length > 0) {
      isValid = false;
    }

    return isValid;
  };

  const handleSubmitValidData = (iscondition?: any) => {
    if (validateAllFields()) {
      handleSubmit(selectedCatagoryPreview, iscondition);
    }
  };
  console.log(currentProcessData, currentRole, "currentProcessData");

  useEffect(() => {
    console.log(selectedCatagoryPreview, "selectedCatagoryPreviewdo");
  }, [selectedCatagoryPreview]);

  const checkdocheckornot = () => {
    try {
      let valData = false;

      if (selectedCatagoryPreview) {
        selectedCatagoryPreview.map((item) => {
          if (item.Questions) {
            item.Questions?.map((qitem: any) => {
              if (qitem.IsAddInDo && qitem.IsAddInDo == true) {
                valData = true;
              }
            });
          }
        });
        return valData;
      }

      return true;
    } catch {
      return true;
    }
  };

  const handleSubmit = (value: any, conditions?: any) => {
    console.log("submitted", value);
    let final_data: any = {
      ModuleName: currentProcessData?.FormName,
      Activity: "Create",
      UserId: userLoginResponse?.UserId,
      CreatedBy: userLoginResponse?.UserEmail,
      MenuId: currentPagePermissionData?.MenuId,
      Workflow_InstanceId: currentProcessData ? currentProcessData?.Workflow_InstanceId : 0,
      Project_Detail_Id: currentProcessData ? currentProcessData?.ProjectId : 0,
      IsRenewal: currentProcessData?.IsRenewal ? currentProcessData?.IsRenewal : false,
      Process_InstanceId: currentProcessData ? currentProcessData?.Id : 0,
      VendorId: currentProcessData ? currentProcessData?.VendorId : 0,
      FormId: currentProcessData?.ProcessId,
      ConditionValue:
        currentProcessData?.ConditionName == "IsDOApprovalNeeded"
          ? conditions || conditions === 0
            ? conditions == 1
              ? 1
              : 0
            : null
          : null,
    };

    let VendorAssessementMappingsData: any[] = [];
    value &&
      value.map((item: any) => {
        item?.Questions &&
          item.Questions.map((q_item: any) => {
            let obj = {
              Assessement_MappingId: q_item.Assessement_MappingId,
              Assessment_Questionaire_Mapping_Id: q_item.Assessment_Questionaire_Mapping_Id,
              Assessment_Questionaire_Id: q_item.Assessment_Questionaire_Id,
              Weightage: q_item.Weightage,
              Risk_Scorig: JSON.stringify(q_item.ApplicableRisksScore),
              Attachment: q_item.Attachment,
              Best_Score: q_item.Best_Score,
              Response_Received: q_item?.Response_Received || "",
              Actual_Score: q_item.Actual_Score,
              Deviation: q_item.Deviation,
              SME_ReviewStatus: q_item?.SME_ReviewStatus ? q_item?.SME_ReviewStatus : null,
              SME_Remarks: q_item.SME_Remarks,
              SO_Remarks: q_item.SO_Remarks,
              IsAddInDo: q_item.IsAddInDo,
              DO_ReviewStatus: q_item.DO_ReviewStatus ? q_item.DO_ReviewStatus : null,
              DO_Remarks: q_item.DO_Remarks,
              IsAddInPM: q_item.IsAddInPM,
              Status: q_item.Status,
              // Updated_By: userLoginResponse?.UserEmail,
              Updated_By: user?.UserName,
            };
            VendorAssessementMappingsData.push(obj);
          });
      });
    final_data.VendorAssessmentMappings = VendorAssessementMappingsData;
    console.log(final_data, "final_data");

    dispatch(addVendorAssessment(final_data));
  };

  useEffect(() => {
    let data: any = VendorAssessmentdata;
    if (data) {
      console.log(data, "/data");

      setisEditData(data);
      vendorAssesmentDetailssetValue("Assessment_Number", data?.Assessment_Number);
      vendorAssesmentDetailssetValue("Vendor_Id", data?.Vendor_Id);
      vendorAssesmentDetailssetValue("Vendor_Name", data?.Vendor_Name);
      vendorAssesmentDetailssetValue("Validity_in_Months", data?.Validity_in_Months);
      vendorAssesmentDetailssetValue("Assessment_Type_1", data?.Assessment_Type_1);
      vendorAssesmentDetailssetValue("Assessment_Type_2", data?.Assessment_Type_2);
      vendorAssesmentDetailssetValue("Start_Date", dayjs(data?.Start_Date) || null);
      vendorAssesmentDetailssetValue("Target_Date", dayjs(data?.Target_Date) || null);
      vendorAssesmentDetailssetValue("WorkFlow_Id", data?.WorkFlow_Id);
      vendorAssesmentDetailssetValue("Workflow_Name", data?.WorkFlow_Name);
      let updatedCategory: any = ConvertGetData(VendorAssessmentdata);

      setselectedCatagoryPreview(updatedCategory); // Set the new category with parsed risks
    }
  }, [VendorAssessmentdata]);

  const closeFormValue = () => {
    if (onClose) {
      onClose();
    }
  };

  const findQuestionavailableornot = () => {
    let findQ: any = false;
    if (selectedCatagoryPreview) {
      selectedCatagoryPreview.forEach((item) => {
        if (item.Questions && item.Questions?.length > 0) {
          findQ = true;
        }
      });
    }

    return findQ;
  };
  return (
    <>
      <Box>
        <Stack spacing={4}>
          {!isdisableBreadCrums && (
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
              <CustomBreadcrumbs
                list={[
                  { value: 0, name: "Pending Actions" },
                  { value: 1, name: `Edit` },
                ]}
                onchange={closeFormValue}
              />

              {currentProcessData && (
                <Box sx={{ width: "20", }}>
                  <VendorHistory isEditData={currentProcessData} />
                </Box>
              )}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {`Third Party Assessment`}
            </Typography>
          </Box>
          <FormProvider {...vendorAssesmentDetailsFormMethods}>
            <VOBSections />
          </FormProvider>
          <Box sx={{display:'flex' , gap:1 , alignItems:'center'}}> 
          <Typography  sx={{ fontSize: "8px", color:  "red" ,  }}> Note : { `Default threshold for Outlier : ${findDefaultthreshold()}`}  {isEditData?.ThresholdValue ? `, Edited Threshold for Outliers : ${ThresholdData}` : ""}   </Typography> {currentProcessData.FormName ==  VOBWorflowPages.SOReview && <IconButton onClick={() => setchangethreshold(true)}> <CustomSvg color={`${theme.palette.info.main}`} size={"16px"} name={"edit"} /> </IconButton>} 
          </Box>

          <Box>
            <AssessmentDetailContext.Provider
              value={{ selectedCatagoryPreview, setselectedCatagoryPreview, validationErrors, setvalidationErrors }}
            >
              {currentRole ? (
                <VOBPreview
                  currentProcessData={currentProcessData}
                  IsUser={currentRole}
                  isPreviewMode={isPreviewMode}
                  processStepsList={processStepsList}
                  isEditData={isEditData}
                />
              ) : null}
            </AssessmentDetailContext.Provider>
          </Box>
          <>

            {selectedCatagoryPreview && findQuestionavailableornot() ? (
              <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
                {/* <CustomButton type="reset" variant="outlined" name={"Cancel"} onClick={() => {}} /> */}

                {currentRole === "SMEE" && (
                  <>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      name={"Submit"}
                      onClick={() => handleSubmitValidData()}
                    />
                  </>
                )}

                {currentRole === "SME" && (
                  <>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      name={"Send To SO"}
                      onClick={() => handleSubmitValidData()}
                    />
                  </>
                )}

                {currentRole === "SO" && (
                  <>
                    <Typography
                      onClick={() => setViewChangesShown(true)}
                      sx={{ cursor: "pointer", mt: 1, textDecoration: "underline" }}
                      color={"text.primary"}
                    >
                      View Changes
                    </Typography>
                  </>
                )}

                {(currentRole === "SO" || currentRole === "Cxo") && (
                  <>
                    <Typography
                      onClick={() => setoncloseScoring(true)}
                      sx={{ cursor: "pointer", mt: 1, textDecoration: "underline" }}
                      color={"text.primary"}
                    >
                      View Scoring
                    </Typography>
                  </>
                )}
                {currentRole === "SO" && (
                  <>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      name={"Send To CXO"}
                      onClick={() =>
                        handleSubmitValidData(currentProcessData?.ConditionName == "IsDOApprovalNeeded" ? 0 : null)
                      }
                    />
                  </>
                )}

                {currentProcessData?.ConditionName == "IsDOApprovalNeeded" ? (
                  <CustomButton
                    type="submit"
                    variant="contained"
                    disabled={!checkdocheckornot()}
                    name={"Send To Deviation Officer"}
                    onClick={() => handleSubmitValidData(1)}
                  />
                ) : null}

                {currentRole === "DO" && (
                  <>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      name={"Send To SO"}
                      onClick={() => handleSubmitValidData()}
                    />
                  </>
                )}
              </Box>
            ) : (
              <Box sx={{ width: "100%", overflowX: "auto" }}>
                {/* <Typography>No Assessment data Available for you</Typography> */}
              </Box>
            )}
          </>
        </Stack>
      </Box>

      <CustomDialog
        show={oncloseScoring}
        onHide={() => {
          setoncloseScoring(false);
        }}
        maxWidth={"md"}
        header={"Aggregated Deviation"}
        contentNode={
          <>
            <FinalAssessmentScoring currentProcessData={currentProcessData}  processStepsList={processStepsList} ThresholdData={ThresholdData} deviationMetrix={deviationmetricsRiskType} data={selectedCatagoryPreview} onclose={() => setoncloseScoring(false)} />
          </>
        }
      />
      <CustomDialog
        show={viewChangesShown}
        onHide={() => {
          setViewChangesShown(false);
        }}
        key={'Vendor_Master_Viewchanges_in_assessment_Screen'}
        maxWidth={"lg"}
        header={"Third Party Changes"}
        contentNode={
          <>
            {viewChanges && Object?.entries(viewChanges) && Object.entries(viewChanges).length > 0 && viewChanges && (
              <ViewChanges  data={viewChanges} onclose={() => setViewChangesShown(false)} dispatch={dispatch} />
            )}
            {/* <FinalAssessmentScoring data={selectedCatagoryPreview} onclose={() => setViewChangesShown(false)} /> */}
          </>
        }
      />
   <CustomDialog
          show={changethreshold}
          onHide={() => {
            setchangethreshold(false);
          }}
          maxWidth={"sm"}
          header={"Change Threshold"}
          contentNode={ <>
              <ThresholdChangeForm currentProcessData={currentProcessData}  ThresholdData={ThresholdData} onclose={() => setchangethreshold(false)} />
          </>}
        />
      {false && (
        <CustomDialog
          show={isDialogVisible}
          onHide={() => {
            setIsDialogVisible(false);
            setQuestion(null);
          }}
          maxWidth={"md"}
          header={"Add Question"}
          contentNode={question ? <RecordSearch Question={question} setQuestion={setQuestion} /> : null}
        />
      )}
    </>
  );
};
