import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { VendorOnboardingAssessment } from "../vendorOnBoarding/VendorOnBoardingPreview";
import { ActionScreens } from "./ActionScreens";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import {
  addPendingActions,
  getPendingActionsbyId,
  updatePendingActions,
  getAllWorkFlowInstance,
} from "../../store/slices/pendingAction";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import { addUser, getUsersList, updateUserDetails } from "../../store/slices/userData";
import { VOBWorflowPages, WorkFlowStatusName } from "../../config/config";
import PageMainHeading from "../../common/CommonHeadings/PageMainHeading";
import { WorkFlowHistorySteper } from "../workFlowHistory/WorkFlowHistorySteper";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { useNavigate, useParams } from "react-router-dom";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import { LovConfig, WorkFlowType } from "../../config/config";
import {
  deleteLov,
  getLOVHistory,
  getLovList,
  getLovNamesList,
  updateLovAddandEdit,
  updateVersionHistory,
} from "../../store/slices/lovMasterData";

export const PendingActions: React.FC<any> = ({ }) => {
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const navigate = useNavigate();
  const { isUserAdded, allUsers, isUserUpdated } = useSelector((state: RootState) => state.userData);
  const [workFlowCategoryOption, setWorkFlowCategoryOption] = useState<any[]>([]);
  const [selectedWorkflowCategory,setselectedWorkflowCategory]=useState(0)
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { process_Id } = useParams();
  const { PendingActionsdataTable, isPendingActionsAdded, isAddEditPendingActionsLoading, PendingActionsToasterMsg } =
    useSelector((state: RootState) => state.pendingActions);
  const dispatch = useAppDispatch();
  const [isRun, setIsRun] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isRunData, setisRunData] = useState<any>("");
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [toggleSwitch, setToggleSwitch] = useState<boolean>(false);
  const [IsHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);
  const [HeaderData, setHeaderData] = useState("");
  const [pendingActions, setpendingActions] = useState<any>([
    { id: 13, vendorId: 119, isreveiew: false, processName: "Assessment Questionatire Mapping" },
    { id: 12, vendorId: 119, isreveiew: false, processName: "Vendor Details" },
    { id: 1, vendorId: 119, isreveiew: false, processName: "Sign Off" },
    { id: 2, vendorId: 119, isreveiew: true, processName: "SME Review" },
    { id: 2, vendorId: 117, isreveiew: false, processName: "SO Review" },
  ]);
  const columns: any = {
    table_name: "Pending Actions",
    isAction: toggleSwitch ? false : true,
    columns: [
      { Field: "Assessment_Number", FieldType: "string", DisplayName: "Assessment No." },
      {
        Field: "ProjectName",
        FieldType: "string",
        DisplayName: "Service / Project",
      },
      // {
      //   Field: "Updated_On",
      //   FieldType: "date",
      //   DisplayName: "Updated On",
      // },
      {
        Field: "Workflow_Name",
        FieldType: "string",
        DisplayName: "Workflow",
      },

      {
        Field: "FormName",
        FieldType: "string",
        DisplayName: "Process",
      },
      {
        Field: "Vendor_Name",
        FieldType: "string",
        DisplayName: "Vendor Name ",
      },
      {
        Field: "RoleName",
        FieldType: "string",
        DisplayName: "Role",
      },
      {
        Field: "All_Users",
        FieldType: "string",
        DisplayName: "Assign To",
      },

      {
        Field: "Status",
        FieldType: "string",
        DisplayName: "Status ",
      },
      {
        Field: "Exit_StatusName",
        FieldType: "string",
        DisplayName: "Exit Status",
      },

      {
        Field: "TAT",
        FieldType: "string",
        DisplayName: "Estimated Time(In Days)",
      },
      {
        Field: "Workflow_CategoryName",
        FieldType: "string",
        DisplayName: "Workflow CategoryName",
      },
    ],
  };
  const [HeaderColumn, setHeaderColumn] = useState(columns);

  useEffect(() => {
    dispatch(getPendingActionsbyId({ userId: userLoginResponse?.UserId }));
    dispatch(
      getUsersList({
        FormID: 2,
      })
    );
  }, []);
  useEffect(()=>{
    dispatch(getLovList({ Name: "" }))
  },[])
  //console.log(allUsers, "getUsersList");
  useEffect(() => {
    if (lovTable && lovTable.length) {
      setWorkFlowCategoryOption(lovTable.filter((item: any) => item.Name === LovConfig.WorkflowCategory) || []);
    }
  }, [lovTable]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    setpendingActions(PendingActionsdataTable);
    console.log(PendingActionsdataTable, "PendingActionsdataTable");
  }, [PendingActionsdataTable]);

  useEffect(() => {
    dispatch(updateLoader(isAddEditPendingActionsLoading));
  }, [isAddEditPendingActionsLoading]);

  useEffect(() => {
    if (isPendingActionsAdded) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: PendingActionsToasterMsg }));
      dispatch(updatePendingActions(false));
      dispatch(getPendingActionsbyId({ userId: userLoginResponse?.UserId }));
      closeRunandApprove();
    }
  }, [isPendingActionsAdded]);
  const allOption = {
    LovId: 0,
    Options: "All",
    
  };
  const handleOpen = (data: any, option: any) => {
    //console.log(data);
    if (option === "run") {
      setIsRun(true);
      setisRunData(data);
    }

    if (option === "view") {
      setHeaderData(data?.Vendor_Name);
      // console.log(data, "data");
      setIsHistoryOpen(true);
      setisRunData(data);
    }

    if (option === "next") {
      setIsNext(true);
      setisRunData(data);
    }
  };
  const closeRunandApprove = () => {
    setIsNext(false);
    setIsRun(false);
    setisRunData("");
    dispatch(getPendingActionsbyId({ userId: userLoginResponse?.UserId }));
    navigate("/pendingActions");
  };
  const handleChange = (event: any) => {
    setselectedWorkflowCategory(event);
    updatedPendingTable=updatedPendingTable.filter((item:any)=>item.Workflow_Category===selectedWorkflowCategory)
  };
  const CompleteProcess = () => {

    let obj: any = {
      Workflow_InstanceId: isRunData?.Workflow_InstanceId,
      Process_InstanceId: isRunData?.Id,
      Project_Detail_Id: isRunData?.ProjectId,
      IsRenewal: isRunData?.IsRenewal ? isRunData?.IsRenewal : false,
      VendorId: isRunData?.VendorId ? isRunData?.VendorId : 0,
      Status: true,
      Updated_By: userLoginResponse?.UserEmail,
      ModuleName: isRunData?.FormName,
      Form_Id: isRunData?.ProcessId,
      Activity: "Approved",
      Updated_On: new Date().toISOString(),
      Menu_Id: currentPagePermissionData?.MenuId,
    };

    dispatch(addPendingActions(obj));
  };

  const handleToggleSwitch = (data: any) => {
    setToggleSwitch(data);
    if (data) {
      dispatch(getPendingActionsbyId({ userId: 0 }));
    } else {
      dispatch(getPendingActionsbyId({ userId: userLoginResponse?.UserId }));
    }
  };
  const userLookup = allUsers.reduce(
    (acc: Record<number, { name: string; email: string; role: string }>, user: any) => {
      acc[user.UserId] = {
        name: `${user.FirstName} ${user.LastName}`.trim(),
        email: user.EmailId,
        role: user.RoleName,
      };
      return acc;
    },
    {}
  );
 
  //console.log(allUsers, "FirstNameFirstName");
  var updatedPendingTable = PendingActionsdataTable
    ? PendingActionsdataTable.map((item: any) => {
      const primaryUserIds = item.Primary_User
        ? item.Primary_User.split(",").map((id: any) => Number(id.trim()))
        : [];
      const secondaryUserIds = item.Secondary_User
        ? item.Secondary_User.split(",").map((id: any) => Number(id.trim()))
        : [];

      const primaryUsers = primaryUserIds.map((id: any) => userLookup[id]).filter((user: any) => user);
      const secondaryUsers = secondaryUserIds.map((id: any) => userLookup[id]).filter((user: any) => user);

      const allUsers = [...primaryUsers, ...secondaryUsers];
      let SMEIDS: any[] = [];
      try {
        if (item?.SmeDepartments) {
          let SMEItems = JSON.parse(item?.SmeDepartments);
          const smeValues: any[] = SMEItems.map((item: any) => Number(item.SME));
          SMEIDS = smeValues;
        }
      } catch { }
      const Status = userLoginResponse?.Department
        ? item?.SmeDepartments && !SMEIDS.includes(userLoginResponse.Department)
          ? `Pending (Others ${item.Status})`
          : item.Status
        : item.Status;
   
      if (allUsers?.length > 0) {
        const uniqueRoles = Array.from(new Set(allUsers.map((user: any) => user.role)));
        const uniquePrimaryNames = Array.from(new Set(primaryUsers.map((user: any) => user.email)));
        const uniqueSecondaryNames = Array.from(new Set(secondaryUsers.map((user: any) => user.email)));
        const allUserNames = Array.from(new Set(allUsers.map((user: any) => user.email)));

        if (
          !toggleSwitch &&
          ((item?.FormName === VOBWorflowPages?.ConsentForm && item?.Status == WorkFlowStatusName.NotStarted) ||
            (item?.FormName === VOBWorflowPages?.VendorResponse && item?.Status == WorkFlowStatusName.NotStarted))
        ) {
          return null;
        }

        return {
          ...item,
          Primary_User: uniquePrimaryNames.join(", "),
          Secondary_User: uniqueSecondaryNames.join(", "),
          RoleName: uniqueRoles.join(", "),
          All_Users: allUserNames.join(", "),
          Status: Status,
        };
      }
      return item;
    }).filter((item: any) => item !== null).filter((item:any)=>{
      if(selectedWorkflowCategory!==0){
          return item.Workflow_Category===selectedWorkflowCategory}
          else{
            return item!==null
          }
})
    : [];
  console.log("tabledata",updatedPendingTable)
  useEffect(() => {
    let findprocess_IdData: any = PendingActionsdataTable?.find((actionitem: any) => actionitem?.Id == process_Id);
    if (process_Id && findprocess_IdData) {
      const isInPrimary = findprocess_IdData?.Primary_User.split(",")?.map(Number)?.includes(userLoginResponse?.UserId);
      const isInSecondary = findprocess_IdData?.Secondary_User.split(",")
        ?.map(Number)
        ?.includes(userLoginResponse?.UserId);
      const isUserIdInEither = isInPrimary || isInSecondary;

      if (
        isUserIdInEither &&
        findprocess_IdData?.Status == WorkFlowStatusName.NotStarted &&
        findprocess_IdData.FormName !== VOBWorflowPages?.ConsentForm &&
        findprocess_IdData.FormName !== VOBWorflowPages?.VendorResponse
      ) {
        handleOpen(findprocess_IdData, "run");
      } else {
        navigate("/pendingActions");
      }
    } else if (PendingActionsdataTable && PendingActionsdataTable.length > 0) {
      navigate("/pendingActions");
    }
  }, [process_Id, PendingActionsdataTable]);

  return (
    <>
      {!isRun ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <PageMainHeading title={permssionPage?.MenuName} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Grid
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", width: "250px" }}>
              <CustomSelect
                  name={"Options"}
                  valueKey={"LovId"}
                  showAll={allOption}
                  showAllOption={true}
                  optionLabelKey="Options"
                  options={workFlowCategoryOption}
                  value={selectedWorkflowCategory}
                  onChange={handleChange}
                  disabled={false}
                  required={true}
                  label={"Workflow Category"}
                />
              </Box>
              <Box sx={{ display: "flex", width: "250px" }}>
              <CustomToogleSwitch
                label={""}
                dynamicLabel={toggleSwitch ? "All Task" : "My Task"}
                value={toggleSwitch}
                onChange={(e) => {
                  handleToggleSwitch(e);
                }}
              />
              </Box>
            </Grid>
          </Box>
         
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <CustomToogleSwitch
                label={""}
                dynamicLabel={toggleSwitch ? "All Task" : "My Task"}
                value={toggleSwitch}
                onChange={(e) => {
                  handleToggleSwitch(e);
                }}
              />
             
                <CustomSelect
                  name={"Location_Name"}
                  valueKey={"Location_Id"}
                  // showAll={allOption}
                  showAllOption={true}
                  optionLabelKey="Location_Name"
                  options={["d", "d"]}
                  onChange={() => { }}
                  value=""
                  //    options={modifiedLovNames}
                  //   value={selectedValue}
                  //   onChange={handleChange}
                  disabled={false}
                  required={true}
                  label={"Country"}
                />
             
            </Box> */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                // rows={PendingActionsdataTable}
                rows={updatedPendingTable}
                handleAction={handleOpen}
                enableZoomIcon={true}
                disableselect={false}
                showAdd={false}
                showDelete={false}
                showEdit={false}
                showView={true}
                showhistory={false}
                showRun={true}
                showNext={true}
                showExports={permssionPage?.IsExport === 1}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                pageName="Pending Action"
                isRunDisableByValue={[{ key: "Status", value: WorkFlowStatusName.NotStarted }]}
                isNextDisableByValue={{ key: "Status", value: WorkFlowStatusName.Pending }}
              />
            </Box>
          </Stack>

          <CustomDialog
            show={IsHistoryOpen}
            onHide={() => {
              setIsHistoryOpen(false);
            }}
            maxWidth={"lg"}
            header={HeaderData !== "" ? `${HeaderData} History` : "Work Flow History"}
            contentNode={
              <>
                {" "}
                <WorkFlowHistorySteper
                  view={false}
                  isEditData={isRunData}
                  closePopUp={() => {
                    setIsHistoryOpen(false);
                  }}
                />
              </>
            }
          />

          <ModalPopUp
            onDeleteMethod={() => CompleteProcess()}
            isDelete={true}
            onHide={() => closeRunandApprove()}
            show={isNext}
            ActionName={"Complete"}
            header={"Confirm"}
            text={`Are you sure you want to Complete the selected Process?`}
          />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <ActionScreens data={isRunData} closeForm={() => closeRunandApprove()} />
        </Box>
      )}
    </>
  );
};
