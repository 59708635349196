import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import textEditorService from "../services/textEditorService";

export interface ITextEditorState {
  isLoading: boolean;
  documentData: any[];
  isDocumentCreated: boolean;
  isDocumentDeleted: boolean;
  toasterMsg: { message: string; status: string };
}

export const initialState: ITextEditorState = {
  isLoading: false,
  documentData: [],
  isDocumentCreated: false,
  isDocumentDeleted: false,
  toasterMsg: { message: "", status: "" },
};

export const createDocument = createAsyncThunk(
  "textEditor/createDocument",
  async (reqparams: any, { rejectWithValue }) => {
    try {
      const response = await textEditorService.createDocument(reqparams);
      const configMatch = response.match(/config\s*=\s*(\{[\s\S]*?\});/);

      if (configMatch && configMatch[1]) {
        const configString = configMatch[1];
        let config = JSON.parse(configString);

        // config.editorConfig.plugins.pluginsData.pop();
        // config.editorConfig.plugins.pluginsData.push("http://localhost:3000/plugins/clife/config.json");

        console.log(config, "configff");
        return config;
      } else {
        console.error("Config not found in response.");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDocument = createAsyncThunk("textEditor/getDocument", async (reqparams: any, { rejectWithValue }) => {
  try {
    const response = await textEditorService.getDocument(reqparams);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteDocument = createAsyncThunk(
  "textEditor/deleteDocument",
  async (reqparams: any, { rejectWithValue }) => {
    try {
      const response = await textEditorService.deleteDocument(reqparams);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const textEditorSlice = createSlice({
  name: "textEditor",
  initialState,
  reducers: {
    resetDocumentState: (state) => {
      state.isDocumentCreated = false;
      state.isDocumentDeleted = false;
      state.toasterMsg = { message: "", status: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDocument.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDocumentCreated = true;
        state.isLoading = false;
        state.documentData = action.payload;
        state.toasterMsg.message = action?.payload?.message;
        state.toasterMsg.status = action?.payload?.status;
      })
      .addCase(createDocument.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.toasterMsg.message = action.payload.message || "Failed to create document.";
        state.toasterMsg.status = "error";
      });

    builder
      .addCase(getDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocument.fulfilled, (state, action: PayloadAction<any>) => {
        state.documentData = action.payload;
        state.isLoading = false;
      })
      .addCase(getDocument.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.toasterMsg.message = action.payload.message || "Failed to fetch document.";
        state.toasterMsg.status = "error";
      });

    builder
      .addCase(deleteDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDocumentDeleted = true;
        state.isLoading = false;
        state.toasterMsg.message = action?.payload?.message;
        state.toasterMsg.status = action?.payload?.status;
      })
      .addCase(deleteDocument.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.toasterMsg.message = action.payload.message || "Failed to delete document.";
        state.toasterMsg.status = "error";
      });
  },
});

export const { resetDocumentState } = textEditorSlice.actions;
export default textEditorSlice.reducer;
