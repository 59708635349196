import React, { useEffect, useRef, useState } from "react";
import { Box, Collapse, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { RootState, useAppDispatch, useAppDispatchThunk } from "../../../store/store";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import CustomAutoComplete from "../../../common/dynamicInputs/CustomAutoComplete";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SearchServices from "../SearchService/SearchServices";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import CustomFileUploader from "../../../common/dynamicFileUploader/CustomFileUploader";
import AddClause from "../ClauseLibrary/AddClause";
import { createTemplate, getContractType } from "../../../store/contractManagement/slices/templateData";
import contractTemplateService from "../../../store/contractManagement/services/templateService";
import clauseService from "../../../store/contractManagement/services/clauseService";
import { useSelector } from "react-redux";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import dayjs from "dayjs";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import CustomSvg from "../../../common/CustomSvg";
import CustomDateTimePicker from "../../../common/dynamicInputs/CustomDateTimePicker";
import CustomTimePicker from "../../../common/dynamicInputs/CustomTimePicker";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomChip from "../../../common/dynamicChip/CustomChip";
import { initialData } from "./InitialData";
import EmailForm from "./EmailForm/EmailForm";
import RichTextEditor, { EditorValue } from "react-rte";
import { getCurrencyList } from "../../../store/slices/currencyData";
import { ACTIVE, getDateOrConvertSameTime } from "../../../utils/constants";
import { getEmailTemplateList } from "../../../store/slices/EmailTemplateData";
import { getUsersList } from "../../../store/slices/userData";
import { getLovList } from "../../../store/slices/lovMasterData";
import FilePreviewer from "../../../common/fileViewer/FilePreviewer";
import FilteViewer from "../../../common/fileViewer/FilteViewer";
import {
  costApprovalResetEmailState,
  costApprovalSendEmail,
} from "../../../store/contractManagement/slices/costApprovalSliceEmail";

export type ContractClause = {
  clauseType: string;
  clauseHeader: string;
  service: string;
  sme: string;
  order: number;
  clause_Id: string;
};

interface formDataProps {
  Company: string;
  Vendor: string;
  OnboardingApprovedBy?: string;
  ProjectCost?: any;
  OnboardingApprovedOnDate?: any;
  OnboardingApprovedOnTime?: any;
  currencyCode?: any;
  ApproverName?: any;
  ApprovedDate?: any;
  ApprovedTime?: any;
  RejectedDate?: any;
  RejectedTime?: any;
  Designation?: any;
  ApprovalStatus?: any;
  email_template_id?: any;
  to_email_id?: any;
  notify_email_id?: any;
  subject?: string;
  email_content?: string;
  is_Msword?: boolean;
  is_Pdf?: boolean;
  emailFormat?: string;
  attachment?: File;
}

export interface emailDataProps {
  email_template_id: any;
  to_email_id: any;
  notify_email_id: any;
  subject: string;
  email_content: EditorValue;
  email_content_string?: string;
  is_Msword: boolean;
  is_Pdf: boolean;
  emailFormat: string;
}

export interface CostApprovalProps {
  view?: boolean;
  edit?: boolean;
  formData?: formDataProps;
  onClose?: () => void;
}

const CostApproval: React.FC<CostApprovalProps> = ({ view, edit, formData, onClose }) => {
  const [ActiveServiceCatlogdata, setActiveServiceCatlogdata] = useState<any[]>([]);
  const [isEmailModelOpen, setIsEmailModelOpen] = useState<boolean>(false);
  const [getCurrency, setCurrency] = useState<any[]>([]);
  const { CurrencyData } = useSelector((state: RootState) => state.CurrencyData);
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const [usersData, setUsersData] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [ApprovalStatusOptions, setApprovalStatusOptions] = useState([
    { LovId: 1, Options: "Approve" },
    { LovId: 2, Options: "Reject" },
  ]);
  const {
    isEmailSent,
    emailToasterMsg,
    contractType: contractTypeOptions,
  } = useSelector((state: any) => state.contractManagementReducer?.costApprovalEmail);
  const dispatch = useAppDispatchThunk();
  useEffect(() => {
    if (CurrencyData && CurrencyData.length > 0) {
      const ActiveCurrencies = CurrencyData.filter((item: any) => item.IsActive === ACTIVE);
      const MergedCurrencies = ActiveCurrencies.map((item: any) => ({
        ...item,
        mergedCurrency: `${item.CurrencyCode} (${item.Symbol}) `,
      }));
      setCurrency(MergedCurrencies);
    }
  }, [CurrencyData]);

  useEffect(() => {
    let filteredUsers = allUsers
      .filter((item: any) => item.IsActive === ACTIVE)
      .map((user: any) => ({
        ...user,
        fullName: `${user.FirstName} ${user.LastName}`,
      }));
    setUsersData(filteredUsers);
  }, [allUsers]);

  const callPageloading = () => {
    dispatch(getCurrencyList({ FormID: 0 }));
    dispatch(getEmailTemplateList());
    dispatch(getUsersList({ FormID: 2 }));
    dispatch(getLovList({ Name: "" }));
  };

  useEffect(() => {
    callPageloading();
  }, []);
  const tempData: any = useRef(null);

  const breadcrumb = [
    { value: 0, name: "Contract Management", link: "/contract-templates" },
    { value: 1, name: "Initiate Contract" },
  ];

  let FormData = {
    Company: edit ? formData?.Company : "",
    Vendor: edit ? formData?.Vendor : "",
  };

  let EmailFormData: emailDataProps = {
    email_template_id: edit ? formData?.email_template_id ?? null : null,
    email_content: edit
      ? RichTextEditor.createValueFromString(formData?.email_content ? formData?.email_content : "", "html") ??
        RichTextEditor.createValueFromString(formData?.email_content ? formData?.email_content : "", "html")
      : RichTextEditor.createEmptyValue(),
    is_Msword: edit ? formData?.is_Msword ?? false : false,
    is_Pdf: edit ? formData?.is_Pdf ?? false : false,
    emailFormat: edit ? formData?.emailFormat ?? "" : "",
    notify_email_id: edit ? formData?.notify_email_id ?? null : null,
    subject: edit ? formData?.subject ?? "" : "",
    to_email_id: edit ? formData?.to_email_id ?? null : null,
  };

  useEffect(() => {
    dispatch(getContractType());
  }, []);
  useEffect(() => {
    setActiveServiceCatlogdata(initialData);
  }, []);

  console.log(emailToasterMsg, "emailToasterMsgemailToasterMsg");
  useEffect(() => {
    if (isEmailSent) {
      dispatch(costApprovalResetEmailState());
      dispatch(updateLoader(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: emailToasterMsg.message,
          isTosterFailed: emailToasterMsg.status === false || emailToasterMsg.status !== "Success" ? true : false,
        })
      );
      emailDetailsReset();
      setIsEmailModelOpen(false);
    } else {
      dispatch(updateLoader(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: emailToasterMsg.message,
          isTosterFailed: emailToasterMsg.status === false || emailToasterMsg.status !== "Success" ? true : false,
        })
      );
    }
  }, [isEmailSent]);

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors: errors },
    control,
    watch,
    getValues,
  } = useForm<formDataProps>({
    defaultValues: FormData,
    mode: "onChange",
  });

  const emailDetailsFormMethods = useForm<emailDataProps>({ defaultValues: EmailFormData, mode: "onChange" });

  const {
    handleSubmit: emailDetailFormMethodsHandleSubmit,
    formState: { errors: errorsEmailDetailFormMethodsform, isDirty: emailDetailsFormMethodsIsDirty },
    getValues: emailDetailsFormMethodsGetValues,
    setValue: emailDetailsFormMethodsSetValue,
    reset: emailDetailsReset,
  } = emailDetailsFormMethods;

  const onSubmit = async (data: formDataProps) => {
    try {
      await dispatch(createTemplate(data));
      dispatch(updateToaster({ message: "Form submitted successfully!", severity: "success" }));
      reset();
    } catch (error) {
      dispatch(updateToaster({ message: "Failed to submit form!", severity: "error" }));
    }
  };
  const handleEmail = async (data: formDataProps) => {
    try {
      setIsEmailModelOpen(true);
    } catch (error) {}
  };
  const handleEmailFormSubmit = async (data: emailDataProps) => {
    data.email_content_string = data.email_content.toString("html");
    console.log(data, "Email Content");

    const onboardingApprovedDate = watch("OnboardingApprovedOnDate")?.$d;
    const onboardingApprovedTime = watch("OnboardingApprovedOnTime")?.$d;

    const onboardingApprovedOnDate = onboardingApprovedDate ? new Date(onboardingApprovedDate).toISOString() : null;

    const onboardingApprovedOnTime = onboardingApprovedTime ? new Date(onboardingApprovedTime).toISOString() : null;

    const updatedContent = {
      email_template_id: data.email_template_id,
      to_Email_Id: data.to_email_id,
      notify_email_id: data.notify_email_id,
      subject: data.subject,
      email_content_string: data.email_content_string,
      is_Msword: data.is_Msword,
      is_Pdf: data.is_Pdf,
      // contract_number: "CONTRACT0012345",
      // project_Cost: watch("ProjectCost").toString(),
      // project_Cost_Code: getCurrency?.find((data) => data.CurrencyId === watch("currencyCode"))?.mergedCurrency,
      // approvar_Name: watch("ApproverName"),
      // designation: watch("Designation"),
      // approval_Status: "pending",
      // onboarding_approvedBy: watch("OnboardingApprovedBy"),
      // approval_Date: onboardingApprovedOnDate,
      // approval_Time: onboardingApprovedOnTime,
    };

    console.log(updatedContent, "updatedContent");
    dispatch(updateLoader(false));
    dispatch(costApprovalSendEmail(updatedContent));
  };

  const watchApprovalStatus = watch("ApprovalStatus");
  const isSendEmail = watch("ProjectCost") && watch("currencyCode") && watch("ApproverName") && watch("Designation");
  const [selectedFiles, setSelectedFiles] = useState<File | null>(null);
  const [isFileView, setIsFileView] = useState<boolean>(false);
  const [fileconvert, setFileConvert] = useState<string>("");
  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = e.target.files;
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     if (
  //       file.type === "application/pdf" ||
  //       file.type === "application/msword" ||
  //       file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  //     ) {
  //       setSelectedFiles(file);
  //       setValue("attachment", file);
  //     } else {
  //       dispatch(
  //         updateToaster({
  //           isToaster: true,
  //           toasterMsg: "Please select a PDF or Word document.",
  //           isTosterFailed: false,
  //         })
  //       );
  //     }
  //   }
  // };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setFileConvert(base64String);
          setSelectedFiles(file);
          setValue("attachment", file);
        };
        reader.readAsDataURL(file);
      } else {
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: "Please select a PDF or Word document.",
            isTosterFailed: true,
          })
        );
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteFile = (fileToDelete: File) => {
    setSelectedFiles(null);
  };

  return (
    <Box sx={{ width: "100%", px: 2 }}>
      <Box pt={2}>
        <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
          <CustomBreadcrumbs list={breadcrumb} />
        </Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="h6" color={"text.primary"} fontWeight={700}>
            Cost Approval
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            />
            <CustomButton variant="contained" color="secondary" name={"Submit"} onClick={handleSubmit(onSubmit)} />
          </Box>
        </Stack>
        <Divider sx={{ my: 4, position: "relative", right: "2rem", width: "104%" }} variant="fullWidth" />
      </Box>
      <Box>
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Controller
                name="Company"
                control={control}
                rules={{
                  required: "Company is Required",
                  validate: {
                    maxLength: (value) => {
                      if (value === undefined) return "Value is required";
                      return value.length <= 100 || "Maximum 100 characters allowed";
                    },
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    name={"Company"}
                    label={"Company"}
                    value={field.value}
                    onChange={field.onChange}
                    required={true}
                    error={Boolean(errors.Company)}
                    helperText={errors.Company && errors.Company.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Controller
                name="Vendor"
                control={control}
                defaultValue={"" as any}
                rules={{
                  required: "Vendor is Required",
                  validate: {
                    maxLength: (value) => {
                      if (value === undefined) return "Value is required";
                      return value.length <= 100 || "Maximum 100 characters allowed";
                    },
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    name={"Vendor"}
                    label={"Vendor"}
                    value={field.value}
                    onChange={field.onChange}
                    required={true}
                    error={Boolean(errors.Vendor)}
                    helperText={errors.Vendor && errors.Vendor.message?.toString()}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography fontWeight={"bold"}> Contract Number - </Typography>
                <Typography> SOFTWARE0123456</Typography>
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Controller
                name="OnboardingApprovedBy"
                control={control}
                defaultValue={"" as any}
                rules={{
                  required: "Onboarding Approved By is Required",
                  validate: {
                    maxLength: (value) => {
                      if (value === undefined) return "Value is required";
                      return value.length <= 100 || "Maximum 100 characters allowed";
                    },
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    name={"Onboarding Approved By"}
                    label={"Onboarding Approved By"}
                    value={field.value}
                    onChange={field.onChange}
                    required={true}
                    error={Boolean(errors.OnboardingApprovedBy)}
                    helperText={errors.OnboardingApprovedBy && errors.OnboardingApprovedBy.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Controller
                name="OnboardingApprovedOnDate"
                control={control}
                defaultValue={null}
                rules={{ required: "Onboarding Approved On Date is Required" }}
                render={({ field }) => (
                  <CustomDatePicker
                    minDate={dayjs()}
                    maxDate={dayjs().add(100, "year").toDate()}
                    label={"Onboarding Approved On Date"}
                    required={true}
                    value={field.value ? field.value : null}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    error={Boolean(errors.OnboardingApprovedOnDate)}
                    helperText={errors.OnboardingApprovedOnDate && errors.OnboardingApprovedOnDate.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Controller
                name="OnboardingApprovedOnTime"
                control={control}
                defaultValue={null}
                rules={{ required: "Onboarding Approved On Time is Required" }}
                render={({ field }) => (
                  <CustomTimePicker
                    label="Onboarding Approved On Time"
                    value={field.value ? field.value : null}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    format="HH:mm"
                    required={true}
                    error={Boolean(errors.OnboardingApprovedOnTime)}
                    helperText={errors.OnboardingApprovedOnTime && errors.OnboardingApprovedOnTime.message?.toString()}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "column", mb: 0.5 }}>
                <Typography variant="h6" color={"text.primary"} sx={{ textAlign: "left" }} fontWeight={500}>
                  Selected Services
                </Typography>
                {ActiveServiceCatlogdata.length > 0 ? (
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        justifyContent: "left",
                        display: "flex",
                        paddingTop: "18px",
                        columnGap: "10px",
                      }}
                    >
                      {ActiveServiceCatlogdata.length > 0 &&
                        ActiveServiceCatlogdata.map((filteredServiceItem: any) => {
                          return (
                            <CustomChip
                              label={`${filteredServiceItem.ServiceName} - ${filteredServiceItem.Summary}`}
                              variant="outlined"
                              color="primary"
                            />
                          );
                        })}
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Box sx={{ display: "flex", gap: 0.4 }}>
                <Controller
                  name="ProjectCost"
                  control={control}
                  rules={{
                    required: "Project Cost is Required",
                    validate: {
                      maxLength: (value) => {
                        if (value === undefined) return "Value is required";
                        return value.length <= 100 || "Maximum 100 characters allowed";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <CustomNumberField
                      name={"ProjectCost"}
                      label={"Project Cost"}
                      value={field.value}
                      onChange={field.onChange}
                      required={true}
                      isDecimal={true}
                      error={Boolean(errors.ProjectCost)}
                      helperText={errors.ProjectCost && errors.ProjectCost.message?.toString()}
                    />
                  )}
                />
                <Box sx={{ width: "50%" }}>
                  <Controller
                    name="currencyCode"
                    control={control}
                    rules={{
                      required: "Currency code is Required",
                    }}
                    render={({ field }) => (
                      <CustomAutoComplete
                        optionValueKey={"CurrencyId"}
                        optionLabelKey="mergedCurrency"
                        options={getCurrency || []}
                        value={field.value}
                        onChange={field.onChange}
                        required={true}
                        label={"Code"}
                        error={Boolean(errors.currencyCode)}
                        helperText={errors.currencyCode && errors.currencyCode.message?.toString()}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Controller
                name="ApproverName"
                control={control}
                defaultValue={"" as any}
                rules={{
                  required: "Approver Name is Required",
                }}
                render={({ field }) => (
                  <CustomAutoComplete
                    optionValueKey={"fullName"}
                    optionLabelKey="fullName"
                    options={usersData}
                    value={field.value}
                    onChange={field.onChange}
                    required={true}
                    label={"Approver Name"}
                    error={Boolean(errors.ApproverName)}
                    helperText={errors.ApproverName && errors.ApproverName.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={2} xl={2}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Controller
                  name="Designation"
                  control={control}
                  rules={{
                    required: "Designation is Required",
                    validate: {
                      maxLength: (value) => {
                        if (value === undefined) return "Value is required";
                        return value.length <= 100 || "Maximum 100 characters allowed";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"Designation"}
                      label={"Designation"}
                      value={field.value}
                      onChange={field.onChange}
                      required={true}
                      error={Boolean(errors.Designation)}
                      helperText={errors.Designation && errors.Designation.message?.toString()}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={1} xl={2}>
              <Box sx={{ display: "flex" }}>
                <CustomButton
                  variant="contained"
                  color="secondary"
                  name={"Send Email"}
                  disabled={!isSendEmail}
                  // onClick={handleSubmit(handleEmail)}
                  onClick={() => {
                    setIsEmailModelOpen(true);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Controller
                name="ApprovalStatus"
                control={control}
                defaultValue={"" as any}
                rules={{
                  required: "Approval Status is Required",
                }}
                render={({ field }) => (
                  <CustomAutoComplete
                    optionValueKey={"LovId"}
                    optionLabelKey="Options"
                    options={ApprovalStatusOptions}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    required={true}
                    label={"Approval Status"}
                    error={Boolean(errors.ApprovalStatus)}
                    helperText={errors.ApprovalStatus && errors.ApprovalStatus.message?.toString()}
                  />
                )}
              />
            </Grid>

            {watchApprovalStatus == 1 && (
              <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                <Controller
                  name="ApprovedDate"
                  control={control}
                  defaultValue={null}
                  rules={{ required: "Approved Date is Required" }}
                  render={({ field }) => (
                    <CustomDatePicker
                      minDate={dayjs()}
                      maxDate={dayjs().add(100, "year").toDate()}
                      label={"Approved Date"}
                      required={true}
                      value={field.value ? field.value : null}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      error={Boolean(errors.ApprovedDate)}
                      helperText={errors.ApprovedDate && errors.ApprovedDate.message?.toString()}
                    />
                  )}
                />
              </Grid>
            )}
            {watchApprovalStatus == 2 && (
              <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                <Controller
                  name="RejectedDate"
                  control={control}
                  defaultValue={null}
                  rules={{ required: "Rejected Date is Required" }}
                  render={({ field }) => (
                    <CustomDatePicker
                      minDate={dayjs()}
                      maxDate={dayjs().add(100, "year").toDate()}
                      label={"Rejected Date"}
                      value={field.value ? field.value : null}
                      required={true}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      error={Boolean(errors.RejectedDate)}
                      helperText={errors.RejectedDate && errors.RejectedDate.message?.toString()}
                    />
                  )}
                />
              </Grid>
            )}

            {watchApprovalStatus == 1 && (
              <Grid item xs={12} sm={12} md={5} lg={2} xl={2}>
                <Controller
                  name="ApprovedTime"
                  control={control}
                  defaultValue={"" as any}
                  rules={{
                    required: "Approved Time is Required",
                  }}
                  render={({ field }) => (
                    <CustomTimePicker
                      label="Approved Time"
                      value={field.value ? field.value : null}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      format="HH:mm"
                      required={true}
                      disabled={false}
                      readOnly={false}
                      error={Boolean(errors.ApprovedTime)}
                      helperText={errors.ApprovedTime && errors.ApprovedTime.message?.toString()}
                    />
                  )}
                />
              </Grid>
            )}

            {watchApprovalStatus == 2 && (
              <Grid item xs={12} sm={12} md={5} lg={2} xl={2}>
                <Controller
                  name="RejectedTime"
                  control={control}
                  defaultValue={"" as any}
                  rules={{
                    required: "Rejected Time is Required",
                  }}
                  render={({ field }) => (
                    <CustomTimePicker
                      label="Rejected Time"
                      value={field.value ? field.value : null}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      format="HH:mm"
                      required={true}
                      disabled={false}
                      readOnly={false}
                      error={Boolean(errors.RejectedTime)}
                      helperText={errors.RejectedTime && errors.RejectedTime.message?.toString()}
                    />
                  )}
                />
              </Grid>
            )}

            {!selectedFiles ? (
              <Grid item xs={12} sm={12} md={5} lg={1} xl={2}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ mt: 0.3 }}>
                    <CustomButton
                      variant="outlined"
                      color="secondary"
                      name={"Attach File"}
                      onClick={handleButtonClick}
                      startIcon={<CustomSvg size={"15px"} name={"paperclip"} />}
                    />
                    <input
                      ref={fileInputRef}
                      accept=".pdf,.doc,.docx"
                      style={{ display: "none" }}
                      id="file-input"
                      type="file"
                      onChange={handleFileChange}
                      multiple={false}
                    />
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 0.3 }}>
                  {selectedFiles && (
                    <CustomChip
                      key={selectedFiles.name}
                      label={selectedFiles.name}
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsFileView(true)}
                      onDelete={() => handleDeleteFile(selectedFiles)}
                    />
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <CustomDialog
        show={isEmailModelOpen}
        onHide={() => {
          emailDetailsReset();
          setIsEmailModelOpen(false);
        }}
        maxWidth={"md"}
        header={"Send Email for Cost Approval"}
        contentNode={
          <>
            {" "}
            <FormProvider {...emailDetailsFormMethods}>
              <EmailForm
                // onSubmit={emailDetailsFormMethods.handleSubmit((data) => {
                //   console.log(data);
                //   setIsEmailModelOpen(false);
                // })}
                onSubmit={(data) => {
                  handleEmailFormSubmit(data);
                }}
                onClose={() => {
                  emailDetailsReset();
                  setIsEmailModelOpen(false);
                }}
              />
            </FormProvider>
          </>
        }
      />
      {isFileView && selectedFiles && (
        <CustomDialog
          show={isFileView}
          onHide={() => {
            setIsFileView(false);
          }}
          key={"File_preview_from_file_Previewer"}
          maxWidth={"lg"}
          header={`${selectedFiles?.name?.split(".")[0] || "File Preview"}`}
          contentNode={
            <>
              <FilteViewer URL={fileconvert} fileName={selectedFiles.name} />
            </>
          }
        />
      )}
    </Box>
  );
};

export default CostApproval;
