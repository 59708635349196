import React, { useEffect, useState } from "react";
import {useForm, useFormContext, Controller } from "react-hook-form";
import { Box, Divider, FormHelperText, Grid, Stack, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
//import { emailDataProps } from "../CostApproval";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomSvg from "../../../common/CustomSvg";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import CustomRadioButton from "../../../common/dynamicInputs/CustomRadioButton";
import CustomAutoComplete from "../../../common/dynamicInputs/CustomAutoComplete";
//import RichTextEditor from "react-rte";
import RichTextEditor, { EditorValue } from "react-rte";

import { useSelector } from "react-redux";
import {  RootState, useAppDispatchThunk } from "../../../store/store";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import { LovConfig } from "../../../config/config";
import { ACTIVE } from "../../../utils/constants";
import { getEmailTemplateList } from "../../../store/slices/EmailTemplateData";
import { getUsersList } from "../../../store/slices/userData";
import { getLovList } from "../../../store/slices/lovMasterData";
import { sendEmailForcontractVendor } from "../../../store/contractManagement/slices/contractListSlice";
import { getGetVCWorkflowProcessesData } from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";
import { getCompanyList } from "../../../store/slices/companyMasterData";
import { getVendorDetailsList } from "../../../store/slices/vendorDetails";


interface vendorReview {
  isEditData : any
}

export interface emailDataProps {
    email_template_id: any;
    to_email_id: any;
    notify_email_id: any;
    subject: string;
    email_content: EditorValue;
    email_content_string?: string;
    is_Docx: boolean;
    is_Link: boolean;
    emailFormat: string;
  }
export const VendorReview: React.FC<vendorReview> = ({isEditData}) => {

  const dispatch = useAppDispatchThunk();
  const { dataTable } = useSelector((state: RootState) => state.emailTemplateData);
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [emailTemplateData, setEmailTemplateData] = useState<any[]>([]);
  const [usersData, setUsersData] = useState<any[]>([]);
  const [LOBlov, setLOBlov] = useState<any[]>([]);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { CompanyTable } = useSelector((state: RootState) => state.CompanyMasterData);
  const { vendorDetailsdata } = useSelector((state: RootState) => state.vendorDetails);
  const {
    workFLowInstanceData
  }: any = contractManagementReducer.contractRolesAndResponsibility;

  let formData = {email_template_id: "",
    email_content:"",
    is_Docx:false,
  is_Link:false,
  emailFormat:"",
  notify_email_id: [],
  subject:"",
  to_email_id:""
};

    const [isEdit, setEdit] = useState<boolean>(true);
    const [contractData, setContractData] = useState<any>("");
    const [contractbasicData, setContractBasicData] = useState<any>("");

    let EmailFormData: emailDataProps = {
        email_template_id: isEdit ? formData?.email_template_id ?? null : null,
        email_content: isEdit
          ? typeof formData?.email_content === "string"
            ? RichTextEditor.createValueFromString(formData?.email_content || "", "html")
            : formData?.email_content ?? RichTextEditor.createEmptyValue()
          : RichTextEditor.createEmptyValue(),
        is_Docx: isEdit ? formData?.is_Docx ?? false : false,
        is_Link: isEdit ? formData?.is_Link ?? false : false,
        emailFormat: isEdit ? formData?.emailFormat ?? "" : "",
        notify_email_id: isEdit ? formData?.notify_email_id ?? null : null,
        subject: isEdit ? formData?.subject ?? "" : "",
        to_email_id: isEdit ? formData?.to_email_id ?? null : null,
      };
      
    

      const emailDetailsFormMethods = useForm<emailDataProps>({
        defaultValues: EmailFormData,
        mode: "onChange",
      });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty, isSubmitted,validatingFields,dirtyFields },
  } = emailDetailsFormMethods;

  const handleEmailFormSubmit = async (data: emailDataProps) => {
    data.email_content_string = data.email_content.toString("html");
    console.log(data, "Email Content");
   let toEmailsName : any = usersData.find((item) => item.UserId == data.to_email_id)?.EmailId || ""
    const updatedContent = {
      email_Template_Id: data.email_template_id,
      is_Docx: data.is_Docx,
      is_Link: data.is_Link,
      to_Email: JSON.stringify(data.to_email_id) ,
      // To_Emails: [JSON.stringify(data.to_email_id)] ,
      // to_Email: data.to_email_id ,
      To_Emails: [toEmailsName] ,
      notify_email_id: data.notify_email_id,
      email_content_string: data.email_content_string,
      subject : data.subject ,
      from_Email_Id : "1"
    
};
  dispatch(sendEmailForcontractVendor(updatedContent))
 
    
  };

  const handleCancel = () => {};



  
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    if (isEditData !== null) {
      let request: any = null;
      if (isEditData.ProjectId) {
        request = {
          projectId: isEditData?.ProjectId,
          workflowInstanceId: isEditData?.Workflow_InstanceId,
        };
      } else {
        request = {
          projectId: isEditData?.project_Id,
          workflowInstanceId: isEditData?.workflowInstance?.id,
        };
      }
      dispatch(getGetVCWorkflowProcessesData(request));
    }
    let req : any = {projectId: '723', workflowInstanceId: 688}
    dispatch(getGetVCWorkflowProcessesData(req));
  }, []);
 
  useEffect(() => {
    if (workFLowInstanceData) {
    
      setContractData(workFLowInstanceData);
     
    }
  }, [workFLowInstanceData]);

  useEffect(() => {
    if (workFLowInstanceData) {
      let obj = {
        "Company Name": CompanyTable.find((item: any) => workFLowInstanceData?.company_Id == item?.Company_Id)?.Legal_Name || "" ,
        "Vendor Name": vendorDetailsdata.find((item: any) => workFLowInstanceData?.vendor_Id == item?.Vendor_Id)?.Legal_Name || "",
        "Contract Number": workFLowInstanceData?.contract_Ref_Number,
      }
      setContractBasicData(obj);
      console.log(workFLowInstanceData  , 'workFLowInstanceData' , vendorDetailsdata , CompanyTable );
      
    }
  }, [workFLowInstanceData , CompanyTable , vendorDetailsdata]);

  console.log(isSubmitted,validatingFields,dirtyFields, "dataTable");

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (dataTable?.emailTemplate.length > 0) {
      setEmailTemplateData(dataTable?.emailTemplate);
    }
  }, [dataTable]);

  useEffect(() => {
    setLOBlov(lovTable.filter((item: any) => item.Name === LovConfig.LOB && item.IsActive === "Active") || []);
  }, [lovTable]);

  useEffect(() => {
    let filteredUsers = allUsers.filter((item: any) => item.IsActive === ACTIVE);
    setUsersData(filteredUsers);
  }, [allUsers]);

  const callPageloading = () => {
   
    dispatch(getEmailTemplateList());
    dispatch(getUsersList({ FormID: 2 }));
    dispatch(getLovList({ Name: "" }));
   
      dispatch(
        getCompanyList({
          FormID: 2,
        })
      );
      dispatch(getVendorDetailsList());
  };

  useEffect(() => {
    callPageloading();
  }, []);


  return (
    <Box component="form" noValidate sx={{ mt: 1, width: "100%" }} onSubmit={handleSubmit(handleEmailFormSubmit)}>
     
     
<Box pt={1} >
        <Stack direction="row" alignItems="center" spacing={2}>
          <ChevronLeftIcon />
          <Typography variant="h6" color={"text.primary"} fontWeight={700}>
           Send For Vendor Review
          </Typography>
        </Stack>
        <Divider sx={{ my: 4, mt:0}} variant="fullWidth" />
      </Box>
      <Box sx={{pl:1}}>
      {contractbasicData &&  <Stack spacing={6} direction="row">
          {Object.entries(contractbasicData).map(([label, value]: any) => (
            <Box key={label}>
              <Typography textAlign="left" fontWeight="bold">
                {label} -{" "}
              </Typography>
              <Typography textAlign="left">{value}</Typography>
            </Box>
          ))}
           
        </Stack> }
        <Divider sx={{ my: 4 }} variant="fullWidth" />
        </Box>
        
        <Grid container spacing={2}  sx={{pl:1}}>


        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              width: { xs: "100%", md: "100%", lg: "30%" },
              flexDirection: { xs: "column", md: "row" },
              alignItems: "flex-start",
              mt:0,
              
            }}
          >
            <Controller
              name="email_template_id"
              control={control}
              rules={{
                required: "Email Template is Required",
              }}
              render={({ field }) => (
                <CustomAutoComplete
                  optionValueKey="EmailTempId"
                  optionLabelKey="TemplateName"
                  options={emailTemplateData}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    const selectedEmailTemplate = emailTemplateData?.find(
                      (template: any) => template.EmailTempId === e
                    );
                    if (selectedEmailTemplate) {
                      console.log(selectedEmailTemplate, "Selected Email Template");
                      setValue("subject", selectedEmailTemplate.EmailSubject || "");

                      setValue(
                        "email_content",
                        RichTextEditor.createValueFromString(selectedEmailTemplate?.Template, "html")
                      );
                    }
                  }}
                  required={true}
                  label="Email Template"
                  error={Boolean(errors.email_template_id)}
                  helperText={errors.email_template_id?.message?.toString()}
                />
              )}
            />
          </Box>
          <Divider sx={{ my: 4,mt:1, mb:1 }} variant="fullWidth" />
        </Grid>
         {/* {false && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ mt:0 }}>
              <CustomButton
                variant="outlined"
                color="secondary"
                name={"Add Recipient"}
                onClick={() => {
                  ("");
                }}
                startIcon={<CustomSvg size={"15px"} name={"plus"} />}
              />
            </Box>
          </Grid>
        )} */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <Box sx={{ p: 1 }}>
            <Stack direction={"column"} spacing={2}>
            <Box sx={{width:"50%" }}>
                <Controller
                
                  name="to_email_id"
                  control={control}
                  rules={{
                    required: "To Email is Required",
                  }}
                  render={({ field }) => (
                    <CustomAutoComplete
                      optionValueKey={"UserId"}
                      optionLabelKey="EmailId"
                      options={usersData}
                      value={field.value}
                      onChange={field.onChange}
                      required={true}
                      label={"To"}
                      error={Boolean(errors.to_email_id)}
                      helperText={errors.to_email_id && errors.to_email_id.message?.toString()}
                    />
                  )}
                />
       
        </Box>
        <Divider sx={{ my: 4,mt:1 }} variant="fullWidth" />
            
        <Box sx={{width:"50%" }}>
                  <Controller
                    name="notify_email_id"
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: "Notify Email is Required",
                    }}
                    render={({ field }) => (
                      <CustomMultiSelect
                        optionIdKey="UserId"
                        optionLabelKey="EmailId"
                        options={usersData}
                        required={true}
                        label={"Notify"}
                        value={field.value !== null ? field.value : []}
                        onChange={field.onChange}
                        error={Boolean(errors.notify_email_id)}
                        helperText={errors.notify_email_id && errors.notify_email_id.message?.toString()}
                      />
                    )}
                  />   
               </Box>
               <Divider sx={{ my:4, mt:1 }} variant="fullWidth" />
              
               <Box sx={{width:"50%" }}>
                <Controller
                  name="subject"
                  control={control}
                  rules={{
                    required: "subject is Required",
                    validate: {
                      maxLength: (value) => {
                        if (value === undefined) return "Value is required";
                        return value.length <= 100 || "Maximum 100 characters allowed";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"subject"}
                      label={"Subject"}
                      value={field.value}
                      onChange={field.onChange}
                      required={true}
                      error={Boolean(errors.subject)}
                      helperText={errors.subject && errors.subject.message?.toString()}
                    />
                  )}
                />
              </Box>
              <Box sx={{mt:2}}>
              <Typography variant="body1" sx={{ textAlign: "left", fontWeight: "bold" }}>
  Email Body
</Typography>

              </Box>
              <Grid item xs={10}>
                <Controller
                  name="email_content"
                  control={control}
                  rules={{
                    required: "Content is Required",
                  }}
                  render={({ field }) => (
                    <>
                      <RichTextEditor toolbarStyle={{display:"flex"}}
                        editorClassName="editor"
                        editorStyle={{ fontSize: "20px", height: width >= 768 ? "40vh" : "30vh",  
                          textAlign: "left", 
                          border: "none",  }}
                        value={field.value}
                        onChange={field.onChange}
                        
                        
                      />
                      {errors.email_content ? (
                        <span>{errors.email_content && errors.email_content.message?.toString()}</span>
                      ) : null}
                    </>
                  )}
                />
              </Grid>
              <Box sx={{textAlign: "left"}} >
                <Typography variant="body1">What Format would you like to send the contract in?</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 2,  }}>
                <Controller
                  name="is_Docx"
                  control={control}
                  // rules={{
                  //   validate: validateFormatSelection,
                  // }}
                  render={({ field }) => (
                    <CustomCheckBox
                      value={field.value}
                      onChange={field.onChange}
                      isStyle={true}
                      required={false}
                      label={"Docx"}
                      error={Boolean(errors.is_Docx)}
                      helperText={errors.is_Docx && errors.is_Docx.message?.toString()}
                    />
                  )}
                />
                <Controller
                  name="is_Link"
                  control={control}
                  // rules={{
                  //   validate: validateFormatSelection,
                  // }}
                  render={({ field }) => (
                    <CustomCheckBox 
                      value={field.value}
                      onChange={field.onChange}
                      isStyle={true}
                      required={false}
                      label={"Link"}
                      error={Boolean(errors.is_Link)}
                      helperText={errors.is_Link && errors.is_Link.message?.toString()}
                    />
                  )}
                />
              </Box>
              {!watch("is_Link") && !watch("is_Docx") && (
                <FormHelperText error={true} id="my-helper-text">
                  You must select at least one format (Docx or Link).
                </FormHelperText>
              )}
             

              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "right",
                  mt: 2,
                }}
              >
                <CustomButton type="button" variant="outlined" name={"Cancel"} onClick={handleCancel} />
                <CustomButton
                  type="submit"
                  disabled={!isDirty}
                  name={"Send"}
                  onClick={handleSubmit(handleEmailFormSubmit)}
                  startIcon={<CustomSvg size={"20px"} name={"send"} />}
                />
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};





