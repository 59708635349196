import { Box, Stack, Typography } from "@mui/material";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../../store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import {
  getTemplateById,
  getTemplateList,
  updateTemplateStatus,
  updateAddandEdit,
} from "../../../store/contractManagement/slices/templateData";
import ContractTemplate from "./ContractTemplate";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";

const columns = {
  table_name: "Contract Templates",
  isAction: true,
  columns: [
    {
      Field: "contract_Template_Name",
      FieldType: "string",
      DisplayName: "Template Name",
    },
    {
      Field: "contractType",
      FieldType: "string",
      DisplayName: "Contract Type",
    },
    {
      Field: "services",
      FieldType: "string",
      DisplayName: "Services",
    },
    {
      Field: "status",
      DisplayName: "Status",
      FieldType: "string",
    },
    {
      Field: "Updated_By",
      FieldType: "string",
      DisplayName: "Modified By",
    },
  ],
};

const ContractTemplateList = () => {
  const dispatch = useAppDispatch();
  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, DraftToasterMsg } = useSelector(
    (state: RootState) => state.saveAsDraft
  );

  const { isLoading, templateList, isTemplateDeleted, templateToasterStatus, ToasterMsg } = useSelector(
    (state: any) => state.contractManagementReducer.templateData
  );

  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [HeaderColumn] = useState(columns);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [Option, setOption] = useState("");
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [templateDetails, setTemplateDetails] = useState<any[]>([]);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);

  const columns_version: any = {
    table_name: "Contract Templates",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = () => {
    dispatch(updateLoader(true));
    dispatch(getTemplateList());
  };

  useEffect(() => {
    setTemplateDetails(templateList);
    dispatch(updateLoader(false));
  }, [templateList]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (isTemplateDeleted === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      dispatch(updateAddandEdit(false));
      initializeData();
      setIsDeleteData("");
      setOption("");
    }
  }, [isTemplateDeleted, templateToasterStatus]);

  const handleOpen = async (data: any, option: string) => {
    if (option === "history") {
      setOption(option);
      setIsAddorEdit(false);
    } else if (option === "delete") {
      setOption(option);
      setIsDeleteData(data);
    } else {
      dispatch(getTemplateById(data?.contract_Template_Id))
        .unwrap()
        .then((templateData: any) => {
          setIsEditDate({ ...templateData });
          setIsAddorEdit(true);
          setOption(option);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const setAddFormcloseoropen = (value: any) => {
    setIsAddorEdit(value);
    setIsEditDate(null);
    // setIsDraftDate(null)
    setOption("");
  };

  const active_Inactive_Template = () => {
    let inactiveFormData = {
      id: isDeleteData.contract_Template_Id,
      status: isDeleteData?.status === "Active" ? false : true,
    };
    dispatch(updateTemplateStatus(inactiveFormData));
  };
console.log(templateDetails,"HeaderColumnHeaderColumn")
  return (
    <>
      {!isAddorEdit ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography variant="h6" color={"text.primary"}>
                Contract Templates
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={HeaderColumn}
                rows={templateDetails}
                handleAction={handleOpen}
                handleAdd={() => setIsAddorEdit(true)}
                enableZoomIcon={true}
                showhistory={false}
                isEnableDraft={false}
                showAdd={true}
                showDelete={true}
                showView={true}
                showEdit={true}
                isActivekey="status"
                // showView={permssionPage?.IsView}
                // showEdit={permssionPage?.IsEdit}
                showExports={permssionPage?.IsExport}
                userId={userLoginResponse?.UserId || 0}
                pageName="Contract Templates"
              />
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <ContractTemplate
            view={Option === "view" ? true : false}
            edit={Option === "edit" ? true : false}
            contractData={isEditData}
            onClose={() => setAddFormcloseoropen(false)}
          />
        </Box>
      )}
      <ModalPopUp
        onDeleteMethod={() => active_Inactive_Template()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.status === "Active" ? "deactivate" : "activate"
        } the selected Template`}
        ActionName={isDeleteData?.status === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
};

export default ContractTemplateList;
