import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import eventLogService from "../services/eventLogs";
import { IEventLogRequest, IEventLogResponse } from "../../models/eventLogs.types";

const initialState = {
  isEventLogAdded: false,
  filteredEvents: [],
  isEventLogFetching: false,
  isEventLogFetchingError: false,
};

export interface addEventLogRequestParam {
  ModuleName: string;
  Action: string;
  Description: string;
  UserId: number;
  CreatedBy: string;
}

export const addEventLog = createAsyncThunk("addEventLog", async (requestParams: addEventLogRequestParam, { rejectWithValue }) => {
  try {
    const response: any = await eventLogService.addEventLog(requestParams);
    if (response === "" || response) {
      return requestParams;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getEventLogsByFilter = createAsyncThunk("getEventLogsByFilter", async (requestParams: IEventLogRequest, { rejectWithValue }) => {
  try {
    const response: IEventLogResponse = await eventLogService.getEventLogsByFilter(requestParams);
    if (response) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const eventLogsSlice = createSlice({
  name: "eventLogsSlice",
  initialState,
  reducers: {
    resetAddEventLog: (state, action) => {
      state.isEventLogAdded = false;
      state.isEventLogFetchingError = false;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(addEventLog.pending, (state) => {
        state.isEventLogAdded = false;
      })
      .addCase(addEventLog.fulfilled, (state, action: PayloadAction<any>) => {
        state.isEventLogAdded = true;
      })
      .addCase(addEventLog.rejected, (state, action: PayloadAction<any>) => {
        state.isEventLogAdded = false;
      })
      .addCase(getEventLogsByFilter.pending, (state) => {
        state.isEventLogFetching = true;
        state.isEventLogFetchingError = false;
      })
      .addCase(getEventLogsByFilter.fulfilled, (state, action: PayloadAction<any>) => {
        state.isEventLogFetching = false;
        state.isEventLogFetchingError = false;
        state.filteredEvents = action.payload;
      })
      .addCase(getEventLogsByFilter.rejected, (state, action: PayloadAction<any>) => {
        state.isEventLogFetching = false;
        state.isEventLogFetchingError = true;
      }),
});

export const { resetAddEventLog } = eventLogsSlice.actions;
export default eventLogsSlice.reducer;
