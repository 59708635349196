import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import axios from "axios";

import { localConfig, localhostConfig } from "./mockData";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { createDocument, deleteDocument, getDocument, resetDocumentState } from "../../store/slices/textEditorSlice";
import { textEditorPortVersion } from "../../store/services/config/constant";
import "./OnlyOfficeTextEditor.css";
import PluginStepper from "./plugin/PluginStepper/PluginStepper";
interface IContractTemplate {
  templateId?: any;
  fileName?: any;
  editorsMode?: "edit" | "view" | "filter" | "blockContent" | "review";
  isNewContract?: boolean;
}
const onDocumentReady = async (event: any) => {
  // console.log("Document is loaded");
  // loadIframeAndSendData();
  // console.log(window, "windowOnlyOffice");

  // const iframe: any = document.querySelector('iframe[name="frameEditor"]');
  // iframe.onload = function () {
  //   const iframeDocument = iframe.contentWindow.document.getElementsByClassName("layout-region");
  //   console.log(iframeDocument, "iframeDocumentiframeDocument");
  //   const activePluginTab = iframeDocument.querySelector("li.ribtab.canedit.active");
  //   if (activePluginTab) {
  //     activePluginTab.classList.remove("active");
  //   }

  //   const pluginTab = iframeDocument.querySelector('li.ribtab[data-layout-name="toolbar-plugins"]');
  //   if (pluginTab) {
  //     pluginTab.classList.add("active");
  //   }
  // };
  console.log(window.Asc.plugin);
  
  window.addEventListener("message", (message) => {
    // setInterval(()=>{
    //     console.log(document.getElementsByName("pluginFrameEditor"))
    // },1000)
    if ((message.origin === "http://localhost:3000" || message.origin === "https://dev.clife.ai" || message.origin === "https://qa.clife.ai" ) && message.data === "message") {
      const iframe = document.querySelector('iframe[name="frameEditor"]') as HTMLIFrameElement | null;
      if (iframe && iframe.contentWindow) {        
        if (iframe) {
          let clauseData = localStorage.getItem("ClauseData");
          let loginData = localStorage.getItem("persist:root");
          let object = {
            ClauseData: clauseData,
            loginData: loginData
          }
          if (iframe) {
            iframe?.contentWindow?.postMessage(JSON.stringify(object), "https://onlyoffice.clife.ai");
          }
        }
      }
    }
  })
};

const loadIframeAndSendData = () => {
  const iframe = document.createElement("iframe");
  iframe.id = "myIframe";
  iframe.src = "http://127.0.0.1:5500/public/plugins/clife/plugin.html";
  iframe.style.width = "0";
  iframe.style.height = "0";
  iframe.style.border = "none";
  iframe.style.position = "absolute";
  document.body.appendChild(iframe);

  iframe.onload = () => {
    const ClauseData = localStorage.getItem("ClauseData");
    if (ClauseData) {
      const data = { ClauseData: ClauseData };
      iframe.contentWindow?.postMessage(JSON.stringify(data), "http://127.0.0.1:5500");
    }
  };
};

const onLoadComponentError = (errorCode: number, errorDescription: string) => {
  switch (errorCode) {
    case -1: // Unknown error loading component
    case -2: // Error loading DocsAPI from document server
    case -3: // DocsAPI is not defined
      console.log(errorDescription);
      break;
    default:
      console.log("Unhandled error: ", errorDescription);
  }
};

const OnlyOfficeTextEditor: React.FC<IContractTemplate> = ({ templateId, fileName, editorsMode, isNewContract }) => {
  console.log(templateId, "templateId");
  const dispatch = useAppDispatchThunk();
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { REACT_APP_TEXT_EDITOR, REACT_APP_TEXT_EDITOR_SERVER } = process.env;
  const { editorDocumentID } = useSelector((state: any) => state.contractManagementReducer.editorDoc);
  console.log(editorDocumentID, "editorDocumentID");
  const { isLoading, documentData, isDocumentCreated, toasterMsg } = useSelector(
    (state: RootState) => contractManagementReducer.textEditor
  );
  const [selectedFileConfig, setSelectedFileConfig] = useState<any>(null);
  // const serviceUrl = "http://20.235.43.6:3000";
  // const baseUrl = `http://20.235.43.6:3000/Editor?fileName=${fileName}.docx&directUrl=false`;
  const serviceUrl = `${REACT_APP_TEXT_EDITOR}`;
  console.log(REACT_APP_TEXT_EDITOR, "REACT_APP_TEXT_EDITOR");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  //local storage:
  localStorage.setItem("templateId", templateId);
  const handleCreateNewFile = async () => {
    if (!fileName) return;

    const req = {
      filename: fileName,
      directUrl: false,
      fileExt: "docx",
      templateId: templateId,
      userid: userLoginResponse?.UserId,
      editorsMode: editorsMode ? editorsMode : "review",
      
    };

    try {
      // Add an iframe
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = "http://127.0.0.1:5501";
      document.body.appendChild(iframe);

      // Listen for messages from the iframe
      // window.addEventListener("message", (event) => {
      //   if (event.origin === "https://4.186.11.70") {
      //     console.log(event.data);
      //   }
      //   if (event.origin === "http://127.0.0.1:5501") {
      //     const templateId = event.data;
      //     console.log(templateId, "templateId");
      //   }
      // });

      const response = await dispatch(createDocument(req)).unwrap();      
      setSelectedFileConfig(response);
      // console.log(response,"responseresponse")
      // localStorage.setItem("Config", JSON.stringify(response));
    } catch (error) {
      console.error("Config not found in response.");
    }
  };
  const handleDeletePreview = async (fileName: any) => {
    if (!fileName) return;

    const req = {
      filename: fileName,
    };

    try {
      const response = await dispatch(deleteDocument(req)).unwrap();
      return true;
    } catch (error) {
      console.error("Config not found in response.");
    }
  };
  // useEffect(() => {
  //   handleDeletePreview(documentData?.document?.title || "Preview Contract");
  // }, []);
  useEffect(() => {
    if (templateId) {
      handleCreateNewFile();
    }
  }, []);
  // console.log(isDocumentCreated, documentData, "isDocumentCreatedisDocumentCreated");
  // useEffect(() => {
  //   if (isDocumentCreated && toasterMsg.status === "success") {
  //     console.log("Document created successfully:", toasterMsg.message);
  //     // setSelectedFileConfig(localhostConfig);
  //     dispatch(resetDocumentState());
  //   } else if (toasterMsg.status === "error") {
  //     console.error("Error:", toasterMsg.message);
  //   }
  // }, [isDocumentCreated, toasterMsg, dispatch]);

  // const onSelectFile = async (e?: any, index?: number) => {
  //   const fileIndex = e ? parseInt(e.target.value) : index;
  //   if (fileIndex === undefined || fileIndex === null) return;

  //   const fileObj = documentData[fileIndex];
  //   if (!fileObj) return;

  //   setSelectedFile(fileObj);
  //   try {
  //     const file = await dispatch(
  //       getDocument({
  //         fileName: fileObj.title,
  //         userId: userLoginResponse?.UserId,
  //         lang: "en",
  //         directUrl: false,
  //       })
  //     );
  //     setSelectedFileConfig(localhostConfig);
  //   } catch (error) {
  //     console.error("Error fetching file data: ", error);
  //   }
  // };

  const onRequestHistory = async (event: any) => {
    const fileName = selectedFile?.title || null;
    const directUrl = null;

    try {
      const response = await axios.post(`${serviceUrl}/historyObj`, {
        fileName,
        directUrl,
      });
      window.DocEditor.instances.docxEditor.refreshHistory({
        currentVersion: response.data.countVersion,
        history: response.data.history,
      });

      setSelectedFile((prev: any) => ({ ...prev, ...response.data }));
    } catch (error) {
      console.error("Error requesting history:", error);
    }
  };

  const onRequestHistoryData = async (event: any) => {
    const version = event.data;
    const historyData = selectedFile?.historyData[version - 1] || null;
    window.DocEditor.instances.docxEditor.setHistoryData(historyData);
  };

  const onRequestHistoryClose = () => {
    document.location.reload();
  };

  console.log(selectedFileConfig, "selectedFileConfigselectedFileConfig");
  return (
    <>
      <div style={{ height: "100vh" }}>
        {selectedFileConfig && (
          <DocumentEditor
            id="docxEditor"
            documentServerUrl={`${REACT_APP_TEXT_EDITOR_SERVER}`}
            //documentServerUrl={`https://20.235.43.6`}
            // documentServerUrl={`https://172.20.20.100`}
            config={selectedFileConfig}
            events_onDocumentReady={onDocumentReady}
            onLoadComponentError={onLoadComponentError}
            events_onRequestHistory={onRequestHistory}
            events_onRequestHistoryClose={onRequestHistoryClose}
            events_onRequestHistoryData={onRequestHistoryData}
          />
        )}

        {isLoading && <p>Loading...</p>}
      </div>
    </>
  );
};

export default OnlyOfficeTextEditor;
