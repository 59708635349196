import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { COST_APPROVAL } from "./endpoints/costApproval";

const costApprovalSendEmail = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(COST_APPROVAL.sendEmail, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const costApprovalService = {
  costApprovalSendEmail,
};

export default costApprovalService;
