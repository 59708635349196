import { AxiosError, AxiosResponse } from "axios";
import { axiosTextEditorInstance } from "./config/TextEditorConfig";
import { ONLYOFFICEURL } from "./endpoints/onlyOffice";
import { textEditorPortVersion } from "./config/constant";

const { REACT_APP_TEXT_EDITOR } = process.env;

const createDocument = async (reqparams: any): Promise<any> => {
  const url = `${ONLYOFFICEURL.createDocument}?filename=${encodeURIComponent(reqparams.filename)}&fileExt=${
    reqparams.fileExt
  }&templateId=${reqparams.templateId}&directUrl=${encodeURIComponent(reqparams.directUrl)}&editorsMode=${
    reqparams.editorsMode
  }&userid=${reqparams.userid}`;
console.log(url,"Create~url")
  return axiosTextEditorInstance
    .post(url)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error creating document: " + JSON.stringify(response.data));
    })
    .catch((error: AxiosError | string) => {
      console.error("Error in createDocument:", error);
      throw error;
    });
};

const getDocument = (reqparams: any): Promise<any> => {
  return axiosTextEditorInstance
    .get(ONLYOFFICEURL.getDocument, {
      params: {
        userid: reqparams.userid,
        filename: reqparams.filename,
        templateId: reqparams.templateId,
        lang: reqparams.lang,
        directUrl: reqparams.directUrl,
      },
    })
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error retrieving document: " + JSON.stringify(response.data));
    })
    .catch((error: AxiosError | string) => {
      console.error("Error in getDocument:", error);
      throw error;
    });
};

const deleteDocument = (reqparams: any): Promise<any> => {
  const url = `${REACT_APP_TEXT_EDITOR}/webeditor.ashx?type=remove&filename=${encodeURIComponent(
    reqparams.filename
  )}`;

  return axiosTextEditorInstance
    .get(url)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error deleting document: " + JSON.stringify(response.data));
    })
    .catch((error: AxiosError | string) => {
      console.error("Error in deleteDocument:", error);
      throw error;
    });
};

const textEditorService = {
  createDocument,
  getDocument,
  deleteDocument,
};

export default textEditorService;
