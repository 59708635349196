import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import Divider from "@mui/material/Divider";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { ACTIVE, REGULATION, textFieldWidth } from "../../../utils/constants";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { useTheme } from "@mui/material/styles";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import SearchServices from "../SearchService/SearchServices";
import Editor from "../../../common/editor/Editor";
import { getThirdPartyList } from "../../../store/slices/thirdPartyData";
import { useSelector } from "react-redux";
import { getUsersList } from "../../../store/slices/userData";
import { ContractManagemnt_InternalCode, LovConfig } from "../../../config/config";
import { Controller, useForm } from "react-hook-form";
import {
  clearData,
  createClause,
  getClauseTypeList,
  updateClause,
  updateClauseDetailsAddandEdit,
} from "../../../store/contractManagement/slices/clauseDataSlice";
import { getDepartmentList } from "../../../store/slices/departmentData";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import {
  createTemplate,
  deletePreviewTemplateData,
  getContractType,
  getTemplateById,
  getTemplateList,
  savePreviewTemplate,
  updateAddandEdit,
} from "../../../store/contractManagement/slices/templateData";
import { getLovList, getLovNamesList } from "../../../store/slices/lovMasterData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import AddClauseTypeMaster from "../ClauseTypeMaster/AddClauseTypeMaster";
import styles from "./initiateContract.module.scss";
import { getCompanyList } from "../../../store/slices/companyMasterData";
import { getVendorDetailsList } from "../../../store/slices/vendorDetails";
import { getServiceCatalogList } from "../../../store/slices/serviceCatalog";
import { getWorkList } from "../../../store/slices/workFlowSlice";
import vendorDetailsService from "../../../store/services/vendorDetails";
import AddContractTypeMaster from "../ContractTypeMaster/AddContractTypeMaster";
import { useNavigate } from "react-router-dom";
import {
  createContract,
  getContractList,
  updateContract,
  updateContractDetailsAddandEdit,
} from "../../../store/contractManagement/slices/contractListSlice";
import { getCurrencyList } from "../../../store/slices/currencyData";
import CustomAutoComplete from "../../../common/dynamicInputs/CustomAutoComplete";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import ContractMaster from "./ContractList";
import OnlyOfficeTextEditor from "../../../common/textEditor/OnlyOfficeTextEditor";
import { generateClauseHTML } from "../../../common/textEditor/generateClauseHTML";
import { asBlob } from "html-docx-js-typescript";
import clauseService from "../../../store/contractManagement/services/clauseService";
import { ContractClause } from "../ContractTemplate/ContractTemplate";
import { PluginRole, setPluginUserRole } from "../../../store/contractManagement/slices/pluginUserRole";
import { setEditorDocsId } from "../../../store/slices/docsIdData";
import { updateHeaderMenu, updateMenu } from "../../../store/slices/menu";
import dayjs from "dayjs";
import { ContractRolesandResponsibility } from "../rolesAndResponsibility/ContractRolesandResponsibility";

export interface InitiateContractProps {
  view?: boolean;
  isEditData?: any;
  clone?: boolean;
  renewal?: boolean;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: boolean;
  ModuleId?: number | undefined;
  isDisableBreadCrums?: boolean;
  isDisableActionButton?: boolean;
  onSaveClause?: (savedClause: any) => void;
  workFlowTrigger?: boolean;
  prePageName?: string;
  workFlowData?: any;
}

export interface AddContractForm {
  vendor_Contract_Id?: number;
  company_Id: number;
  vendor_Id: number;
  contract_Ref_Number: string;
  contract_Type: number;
  project_Id: number;
  contract_Template_Id: number | null;
  workflow_Id: number;
  static_Key_Pointers?: string;
  contract_File?: string;
  hasParentContract?: boolean;
  parent_Id?: number;
  isBoardApprovalNeeded: boolean;
  isPerpetualContract: boolean;
  isStampingNeeded: boolean;
  isCostApprovalNeeded: boolean;
  project_Cost?: number;
  currency_Id?: number;
  serviceMappings: number[];
  process_Type_Id: number;
  cloned_Vendor_Id: number;
  isActive: boolean;
  ProcessSeqno?: string;
  ProcessName?: string;
  ProcessType?: string;
  ProcessId?: string;
  ProcessNextStep?: string;
  ConditionTobeChecked?: string;
  IfYes_GoTo?: string;
  IfNo_GoTo?: string;
}

interface serviceMapping {
  vC_ServiceMapping_Id: number;
  serviceCatalog_Id: number;
  isDeleted: boolean;
  isActive: boolean;
}

const InitiateContract: React.FC<InitiateContractProps> = ({
  view,
  isEditData =null,
  edit,
  onClose,
  isDraftData,
  isDisableBreadCrums,
  isDisableActionButton,
  workFlowTrigger = false,
  prePageName = "",
  workFlowData = null,
  clone = false,
  renewal = false,
}) => {
  const {
    isLoading,
    isTemplateAdded,
    isTemplateUpdated,
    templateToasterStatus,
    ToasterMsg: ToasterMsgTemplate
  } = useSelector((state: any) => state.contractManagementReducer?.templateData);
  const dispatch = useAppDispatchThunk();
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { CompanyTable } = useSelector((state: RootState) => state.CompanyMasterData);
  const { vendorDetailsdata } = useSelector((state: RootState) => state.vendorDetails);
  const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const { contractType: contractTypeOptions, templateList } = useSelector(
    (state: any) => state.contractManagementReducer?.templateData
  );
  const { CurrencyData } = useSelector((state: RootState) => state.CurrencyData);

  const { contractDetailsData, isContractDetailsAdded, isContractDetailsUpdated, ToasterMsg, workFLowInstanceData } = useSelector(
    (state: any) => state.contractManagementReducer?.contractlist
  );
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { workFlowsData } = useSelector((state: RootState) => state.workFlow);

  const [contractTemplates, setContractTemplates] = useState<any[]>([]);

  const [updatedservicesMappings, setUpdatedServicesMappings] = useState<any>([]);
  const [updatedTemplates, setUpdatedtemplates] = useState<any>([]);
  const [isAddNewClauseType, setIsAddNewContractTemplate] = useState<boolean>(false);
  const [contractAction, setContactAction] = useState<any[]>([]);
  const [availableServices, setAvailableServices] = useState<any[]>([]);
  const [viewAllContracts, setViewAllContracts] = useState<boolean>(false);
  const [parentContracts, setParentContract] = useState<any[]>([]);
  const [getCurrency, setCurrency] = useState<any[]>([]);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const [appParamGroup, setAppParamGroup] = useState<any>("");
  const [newVendorOptions, setNewVendotOptions] = useState<any[]>([]);
  const [costApproval, setCostApproval] = useState<boolean>(false);
  const [isPreviewContract, setIsPreviewContract] = useState<boolean>(false);
  const { previewId } = useSelector((state: any) => state.contractManagementReducer?.templateData);
  const [clauses, setClauses] = useState<ContractClause[]>([]);
  const [isContentData, setIsContentData] = useState<any>(null);
  const [workflowDataOptions, setWorkFlowDataOptions] = useState<any[]>([]);
  const [onload, setOnLoad] = useState(true);
  const [workFlowDataSequenceData,setWorkFlowDataSequenceData ] = useState<any>();
  const [navigateRR, setNavigateRR] = useState<boolean>(false);
  const [rrinstanceData, setRRInstanceData] = useState<any>();

  let contractIntiateForm: AddContractForm = {
    vendor_Contract_Id: isDraftData ? 0 : isEditData ? isEditData?.vendor_Contract_Id : 0,
    company_Id: isDraftData ? 0 : isEditData ? isEditData?.company?.company_Id : null,
    vendor_Id: isDraftData ? 0 : isEditData ? isEditData?.vendor?.vendor_Id : null,
    contract_Ref_Number: isDraftData ? 0 : isEditData ? isEditData?.contract_Ref_Number : "",
    contract_Type: isDraftData ? 0 : isEditData ? isEditData?.contract_Type : null,
    project_Id: isDraftData ? 0 : isEditData ? isEditData?.project_Id : 0,
    contract_Template_Id: isDraftData ? 0 : isEditData ? isEditData?.contract_Template_Id : null,    
    hasParentContract: isDraftData ? 0 : isEditData ? isEditData?.hasParentContract : false,
    parent_Id: isDraftData ? 0 : isEditData ? isEditData?.parent_Id : 0,
    isBoardApprovalNeeded: isDraftData ? 0 : isEditData ? isEditData?.isBoardApprovalNeeded : false,
    isPerpetualContract: isDraftData ? 0 : isEditData ? isEditData?.isPerpetualContract : false,
    isStampingNeeded: isDraftData ? 0 : isEditData ? isEditData?.isStampingNeeded : false,
    isCostApprovalNeeded: isDraftData ? 0 : isEditData ? isEditData?.isCostApprovalNeeded : false,
    project_Cost: isDraftData ? 0 : isEditData ? isEditData?.project_Cost : null,
    currency_Id: isDraftData ? 0 : isEditData ? isEditData?.currency_Id : null,
    serviceMappings: isDraftData
      ? 0
      : isEditData
      ? isEditData?.serviceMappings && isEditData?.serviceMappings.length > 0
        ? isEditData?.serviceMappings?.map((data: any) => data?.services?.serviceCatalog_Id)
        : []
      : [],
    process_Type_Id: isDraftData ? 0 : isEditData ? isEditData?.process_Type_Id : 245,
    cloned_Vendor_Id: isDraftData ? 0 : isEditData ? isEditData?.clonedVendor?.vendor_Id : null,
    static_Key_Pointers: "",
    contract_File: "",
    isActive: isDraftData ? 0 : isEditData ? isEditData?.isActive : true,
    workflow_Id: isDraftData ? 0 : isEditData ? isEditData?.workflow_Id : null,    
  };

  const initaiteCOntract = useForm<AddContractForm>({
    defaultValues: contractIntiateForm,
    mode: "onChange",
  });

  const {
    handleSubmit: handleIntiaiteContractForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
    setError,
    clearErrors,
  } = initaiteCOntract;

  const clauseInfoFields = Object.keys(contractIntiateForm);
  const handleNext = async () => {
    const isValid = await initaiteCOntract.trigger();
    let costApproval = getValues("currency_Id")
    const clauseInfoFieldsErrors = clauseInfoFields.filter(
      (field: any) => initaiteCOntract.getFieldState(field).invalid
    );
    if (clauseInfoFieldsErrors.length === 0 || (!costApproval && clauseInfoFieldsErrors.length <= 2 && clauseInfoFieldsErrors.filter((item)=> item === "project_Cost" || item === "currency_Id").length>0)) {
      handleSaveAsDraft();
    }
  };

  const navigate = useNavigate();
  //const [companyOptions, setcompa]
  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      onClose();
    }
  };
  useEffect(() => {
    if (applicationParameterData.length > 0) {
      let contactManagemtnAppParms: any[] = applicationParameterData?.filter(
        (item) => item.Parameter_Group === ContractManagemnt_InternalCode.ContractManagement
      );
      if (contactManagemtnAppParms.length > 0) {
        let prefixValue = contactManagemtnAppParms.filter(
          (data: any) => data.Parameter_Name === ContractManagemnt_InternalCode.ContractMangementPrefix
        )[0].Parameter_Description;
        let suffixValue = contactManagemtnAppParms.filter(
          (data: any) => data.Parameter_Name === ContractManagemnt_InternalCode.ContractMangementSuffix
        )[0].Parameter_Description;
        let paramGroup = ContractManagemnt_InternalCode.ContractManagement;
        let paramGenrateGrp = {
          paramGroup: paramGroup,
          prefix: prefixValue,
          suffix: suffixValue,
        };
        setAppParamGroup(paramGenrateGrp);
      }
    }
  }, [applicationParameterData]);

  useEffect(() => {
    if (workFlowData !== null) {
      setValue("workflow_Id", workFlowData.Id);
    }
  }, [workFlowData]);

  useEffect(() => {
    if (workFlowsData.length > 0) {
      setWorkFlowDataOptions(workFlowsData.filter((data: any) => data.WorkflowCategoryName === "Contract Management"));
    }
  }, [workFlowsData]);

  const CallonpageLoad = () => {
    dispatch(
      getCompanyList({
        FormID: 2,
      })
    );
    dispatch(getVendorDetailsList());
    dispatch(getServiceCatalogList());
    dispatch(getContractType());
    dispatch(getTemplateList());
    dispatch(getWorkList());
    dispatch(getLovList({ Name: LovConfig?.Vendor_Contract_Process_Type }));
    if (contractDetailsData.length === 0) {
      dispatch(getContractList());
    }
    dispatch(getCurrencyList({ FormID: 0 }));
  };
  const changeRole = (newRole: PluginRole) => {
    dispatch(setPluginUserRole(newRole));
  };
  useEffect(() => {
    CallonpageLoad();
    changeRole("sme");
  }, []);
  const { pluginRole } = useSelector((state: any) => state.contractManagementReducer?.pluginLoginUserRole);
  console.log(pluginRole, "pluginRolepluginRole");
  useEffect(() => {
    setContactAction(
      lovTable.filter(
        (item: any) => item.Name === LovConfig?.Vendor_Contract_Process_Type && item.Options !== "N / A"
      ) || []
    );
  }, [lovTable]);

  useEffect(() => {
    let filterTemplates = templateList.filter(
      (item: any) =>
        item.isActive === true &&
        item.contract_Template_Name !== null &&
        item.contract_Template_Name !== undefined &&
        item.contract_Template_Name !== ""
    );
    //setContractTemplates([...filterTemplates]);
  }, [templateList]);

  useEffect(() => {
    if (ActiveServiceCatlogdata.length > 0) {
      callVendorDetails();
    }
  }, [watch("vendor_Id"), ActiveServiceCatlogdata]);

  useEffect(() => {
    let serviceMapping = getValues("serviceMappings") || [];
    if (serviceMapping.length > 0 && templateList.length > 0) {
      let servicemappingValues = [...serviceMapping];
      let templateDropDown = templateList.filter((service: any) => {
        return (
          service.isActive === true &&
          service.contract_Template_Name !== null &&
          service.contract_Template_Name !== undefined &&
          service.contract_Template_Name !== "" &&
          service.servicesId &&
          service.servicesId.length > 0 &&
          service.servicesId.filter((serviceId: number) => servicemappingValues.indexOf(serviceId) > -1).length > 0
        );
      });
      setContractTemplates(templateDropDown);
      console.log(templateDropDown);
      if (onload && (edit || view || clone || renewal)) {
        setOnLoad(false);
        dispatch(updateLoader(false));
      }
    } else {
      if (!onload) {
        setContractTemplates([]);
        setValue("contract_Template_Id", null);
      }
    }
  }, [watch("serviceMappings"), templateList]);

  useEffect(() => {
    if (contractDetailsData.length > 0) {
      let parentContracts = contractDetailsData.map((contract: any) => {
        return {
          Id: contract?.contractId,
          contract_name: contract?.contract_Number + " - " + contract.contractType,
        };
      });
      setParentContract(parentContracts);
    }
  }, [contractDetailsData]);

  useEffect(() => {
    if (CurrencyData && CurrencyData.length > 0) {
      const ActiveCurrencies = CurrencyData.filter((item: any) => item.IsActive === ACTIVE);
      const MergedCurrencies = ActiveCurrencies.map((item: any) => ({
        ...item,
        mergedCurrency: `${item.CurrencyCode} (${item.Symbol}) `,
      }));
      setCurrency(MergedCurrencies);
    }
  }, [CurrencyData]);

  console.log("Company", getValues("company_Id"), "Vendor Values", getValues("vendor_Id"));

  const callVendorDetails = async () => {
    let vendor_Id = getValues("vendor_Id");
    if (vendorId !== null && ActiveServiceCatlogdata.length > 0) {
      const res: any = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: vendor_Id });
      let filterServices = ActiveServiceCatlogdata.filter((serviceItem: any) => {
        let item = res?.data?.VendorServiceMappings?.filter((serviceDetail: any) => {
          return serviceDetail.ServiceCatalogId === serviceItem.ServiceCatalogId;
        });
        return item.length > 0;
      });
      console.log(filterServices);
      setAvailableServices([...filterServices]);
      if (!onload) {
        setValue("serviceMappings", []);
      }
    }
  };

  const handleSaveAsDraft = () => {
    dispatch(updateLoader(true));
    if (edit) {
      let formValue: any = getValues();
      formValue.updated_By = userLoginResponse?.UserName;
      formValue.userId = userLoginResponse?.UserId;
      formValue.moduleName = "Contracts";
      formValue.activity = "Edit";
      formValue.createdBy = userLoginResponse?.UserName;

      // updating Service mapping
      let serviceMappings: serviceMapping[] = [];

      for (let i = 0; i < isEditData?.serviceMappings?.length; i++) {
        let servicemappingId = isEditData?.serviceMappings[i].vC_ServiceMapping_Id;
        let serviceCatalogId = isEditData?.serviceMappings[i]?.services?.serviceCatalog_Id;
        let serviceMapping = {
          vC_ServiceMapping_Id: servicemappingId,
          serviceCatalog_Id: serviceCatalogId,
          isDeleted: false,
          isActive: true,
        };
        if (selectedServices.findIndex((data: any) => data === serviceCatalogId) > -1) {
          serviceMappings.push(serviceMapping);
        } else {
          serviceMapping.isDeleted = true;
          serviceMappings.push(serviceMapping);
        }
      }

      for (let i = 0; i < selectedServices?.length; i++) {
        let newSelectedClauseId = selectedServices[i];
        let serviceMapping = {
          vC_ServiceMapping_Id: 0,
          serviceCatalog_Id: selectedServices[i],
          isDeleted: false,
          isActive: true,
        };
        if (
          isEditData?.serviceMappings.findIndex(
            (data: any) => data?.services?.serviceCatalog_Id === newSelectedClauseId
          ) === -1
        ) {
          serviceMappings.push(serviceMapping);
        }
      }
      formValue.serviceMappings = serviceMappings;
      dispatch(updateContract(formValue));
    } else {
      let formValue: any = getValues();
      formValue.approved_By = userLoginResponse?.UserName;
      formValue.updated_By = userLoginResponse?.UserName;
      formValue.userId = userLoginResponse?.UserId;
      formValue.moduleName = "Contracts";
      formValue.activity = "create";
      formValue.paramGroup = appParamGroup.paramGroup;
      formValue.prefixParamName = appParamGroup.prefix;
      formValue.suffixParamName = appParamGroup.suffix;
      if(!formValue.isCostApprovalNeeded){
        formValue.project_Cost = 0;
        formValue.currency_Id = 0;
      }
      formValue.createdBy = userLoginResponse?.UserName;
      if (clone) {
        formValue.contract_Ref_Number = "";
        formValue.vendor_Contract_Id = 0;
      }     

      let processJson = null;
      if(workFlowDataSequenceData.WorkflowProcessAsJson!=null){
        processJson =   JSON.parse(workFlowDataSequenceData.WorkflowProcessAsJson)[0];
        // formValue.ProcessSeqno= processJson.ProcessSeqno;
        // formValue.ProcessName= processJson.ProcessName;
        // formValue.ProcessType= processJson.ProcessType;
        // formValue.ProcessId= processJson.ProcessId;
        // formValue.ProcessNextStep= processJson.Process_NextStep;
        // formValue.ConditionTobeChecked= processJson.ConditionTobeChecked ? processJson.ConditionTobeChecked : null;
        // formValue.IfYes_GoTo= processJson.IfYes_GoTo ? processJson.IfYes_GoTo : null;
        // formValue.IfNo_GoTo= processJson.IfNo_GoTo ? workFlowData.IfNo_GoTo : null;
        formValue.workflow_Category_Id = workFlowDataSequenceData.WorkflowCategory;
        formValue.process_Type_Id =  processJson.ProcessId;
        formValue.workflowStartDate = dayjs().toDate();       
        formValue.workflowEndDate = dayjs().add(workFlowDataSequenceData.TAT,"day").toDate(); 
        formValue.initiateContractSeqno= processJson.ProcessSeqno;
        formValue.initiateContractProcessId= processJson.ProcessId;
        formValue.projectApprovedBy =   userLoginResponse?.UserName;
        formValue.projectApprovedOn = dayjs().toDate();     
      }
      dispatch(createContract(formValue));
    }
  };

  useEffect(() => {
    if ((isContractDetailsAdded || isContractDetailsUpdated) && !workFlowTrigger) {
      setRRInstanceData(workFLowInstanceData);
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.message,
          isTosterFailed: ToasterMsg.status !== "Success",
        })
      );
      dispatch(updateContractDetailsAddandEdit(false));
      dispatch(updateLoader(false));
      setNavigateRR(true);

      // if (onClose) {
      //   onClose();
      // }
    }
  }, [isContractDetailsAdded, isContractDetailsUpdated]);

  useEffect(() => {
    if (view || edit) {
      let newVendor = vendorDetailsdata.filter((data: any) => data.Vendor_Id !== isEditData?.clauseType?.vendor_Id);
      setNewVendotOptions([...newVendor]);
    }
  }, [view, edit]);

  useEffect(() => {
    let isCostApprovalNeededtemp = getValues("isCostApprovalNeeded");
    if (isCostApprovalNeededtemp) {
      setCostApproval(isCostApprovalNeededtemp);
    } else {
      setCostApproval(false);
    }
  }, [watch("isCostApprovalNeeded")]);

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader: any = new FileReader();
      reader.onloadend = () => resolve(reader.result?.split("base64,")[1]);
      reader.readAsDataURL(blob);
    });
  }

  const contractTemplateId = watch("contract_Template_Id");
  const vendorId = watch("vendor_Id");
  const companyId = watch("company_Id");
  const serviceMappings = watch("serviceMappings") || [];

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  };

  const dynamicFileName = `Preview_Contract_${getCurrentDateTime()}`;

  const handleLoadContract = async (services = selectedServices, filterClause: number[] = []) => {
    dispatch(updateLoader(true));

    try {
      const { clauseList } = await clauseService.getClauseByService(services);

      const filteredClauseList = filterClause.length
        ? clauseList.filter((clause: any) => filterClause.includes(clause.clause_Id))
        : clauseList;

      const orderedClauses = filteredClauseList.map((clause: any, index: any) => ({
        ...clause,
        order: index + 1,
      }));

      setClauses(orderedClauses);
      return orderedClauses;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      dispatch(updateLoader(false));
    }
  };
  const [newContractResponse, setNewResponse] = useState<any[]>([]);
  const formatPreviewContract = async () => {
    const templated_id = String(watch("contract_Template_Id"));

    try {
      const templateData = await dispatch(getTemplateById(templated_id)).unwrap();
      setIsContentData({ ...templateData });

      const serviceCategories = templateData?.ctServiceMappings.map((service: any) => service.serviceCatalog_Id) || [];
      setSelectedServices(serviceCategories);

      if (serviceCategories.length) {
        const clauseIds = templateData.ctClauseMappings.map((clause: any) => clause.clause_Id);
        const getClauseData = await handleLoadContract(serviceCategories, clauseIds);

        if (getClauseData.length > 0) {
          if (templateData) {
            const reqPayload = {
              contractTemplateId: 0,
              contractTemplateName: `${vendorDetailsdata.find((data) => data.Vendor_Id === vendorId)?.Legal_Name}_${
                CompanyTable.find((data) => data.Company_Id === companyId)?.Legal_Name
              }`,
              contractTypeId: watch("contract_Type"),
              serviceTypeId: null,
              serviceGroupId: null,
              serviceCategories: watch("serviceMappings"),
              clauses: clauseIds,
              userId: userLoginResponse?.UserId,
              moduleName: "Contract Template",
              activity: "Create",
              createdBy: userLoginResponse?.UserName,
              source: "Initiate Contract",
              contractId: 0,
              clauseSMEReviewMappings: [],
            };

            const response = await dispatch(createTemplate(reqPayload)).unwrap();
            let newRes = {
              ...templateData,
              contract_Template_Name: response.contract_Template_Name,
              contract_Template_Id: response.contract_Template_Id,
              source: response.source || "initiate_contract",
              contractId: response.contractId,
            };
            const serviceCategories = newRes?.ctServiceMappings.map((service: any) => service.serviceCatalog_Id) || [];
            const NewclauseIds: any = newRes?.ctClauseMappings.map((clause: any) => clause.clause_Id);
            const getClauseData = await handleLoadContract(serviceCategories, NewclauseIds);
            console.log(response, newRes, getClauseData, "ResponsenewResnewRes");
            const clauseData = `<div>${generateClauseHTML(getClauseData)}</div>`;
            localStorage.setItem(
              "ClauseData",
              JSON.stringify({ SMEReviewMapping: getClauseData, TaggingRisk: getClauseData, allData: newRes })
            );
            const dataBlob = await asBlob(clauseData);
            const base64Content = await blobToBase64(dataBlob);

            dispatch(
              savePreviewTemplate({
                text: base64Content,
                updated_By: userLoginResponse?.UserName,
              })
            );
          }
          setIsPreviewContract(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (isTemplateAdded || isTemplateUpdated) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsgTemplate, isTosterFailed: templateToasterStatus }));
      if (templateToasterStatus) {
        dispatch(updateAddandEdit(false));
      }
    }
  }, [isTemplateAdded, isTemplateUpdated]);
  useEffect(()=>{
      let workFLowId= getValues("workflow_Id");
    if(isEditData !== null){ 
      let workData =workFlowsData.filter((data: any) => data.Id ===isEditData.WorkflowId);
      if(workData.length>0){
        setWorkFlowDataSequenceData(workData[0]);
      }     
      
    }else if(workFLowId){
      let workData =workFlowsData.filter((data: any) => data.Id ===workFLowId);
      if(workData.length>0){
        setWorkFlowDataSequenceData(workData[0]);
      }  
    }
  },[isEditData, watch("workflow_Id"),workFlowsData])
  {
    /**
    
    {
    "contract_Template_Id": 68,
    "contract_Type_Id": 2,
    "contract_Template_Name": "Adv ven change_Ernst and Young LLP",
    "service_Type_Id": null,
    "service_Group_Id": null,
    "isActive": true,
    "source": null,
    "contractId": null,
    "toaster": {
        "message": "The Contract Template has been added Successfully",
        "status": "Success"
    },
    "ctClauseMappings": null,
    "ctServiceMappings": null,
    "contractType": null
}*/
  }

  // if (getClauseData.length > 0) {
  //   const clauseData = `<div>${generateClauseHTML(getClauseData)}</div>`;
  //   localStorage.setItem("ClauseData", JSON.stringify({ SMEReviewMapping: getClauseData, TaggingRisk: getClauseData }));
  //   const dataBlob = await asBlob(clauseData);
  //   const base64Content = await blobToBase64(dataBlob);

  //   dispatch(
  //     savePreviewTemplate({
  //       text: base64Content,
  //       updated_By: userLoginResponse?.UserName,
  //     })
  //   );

  //   setIsPreviewContract(true);
  // }

  const isDisabled = contractTemplateId !== null && vendorId !== null && serviceMappings.length !== 0;
  console.log(isPreviewContract, "isPreviewContract");
  return (
    <React.Fragment>
      {!navigateRR && !isPreviewContract && (
        <Box
          sx={{
            position: "relative",
          }}
        >
          {/* <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: theme.palette.background.default,
          zIndex: 1000,
        }}
      > */}
          {!isDisableBreadCrums && (
            <>
              <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
                <CustomBreadcrumbs
                  list={[
                    { value: 0, name: prePageName ? prePageName : "Contract Management" },
                    { value: 1, name: "Initiate Contract" },
                  ]}
                  onchange={closeForm}
                />
              </Box>
              <Stack direction="row" alignItems="center" spacing={2} pb={2}>
                <Typography variant="h6" color={"text.primary"}>
                  {"Initiate Contract"}
                </Typography>
              </Stack>
              <Divider
                sx={{
                  borderBottomWidth: 3,
                }}
              />
            </>
          )}
          {/* </Box> */}

          <Grid
            sx={{
              width: "100%",
              py: 2,
            }}
          >
            <Box
              sx={{
                // px: {
                //   xs: 2,
                //   sm: 4,
                //   md: 2,
                //   lg: 2,
                //   xl: 2,
                // },

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                    <Controller
                      name="company_Id"
                      control={control}
                      defaultValue={0}
                      rules={{
                        required: "Company is required",
                      }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"company_Id"}
                          valueKey={"Company_Id"}
                          optionLabelKey="Legal_Name"
                          options={CompanyTable}
                          label={"Company"}
                          value={field.value}
                          onChange={field.onChange}
                          disabled={view}
                          error={Boolean(errorsloginform.company_Id)}
                          helperText={errorsloginform.company_Id && errorsloginform.company_Id.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                    <Controller
                      name="vendor_Id"
                      control={control}
                      defaultValue={0}
                      rules={{
                        required: "Vendor is required",
                      }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"vendor_Id"}
                          valueKey={"Vendor_Id"}
                          optionLabelKey="Legal_Name"
                          options={vendorDetailsdata}
                          label={"Vendor"}
                          value={field.value}
                          onChange={(value) => {
                            //setOnLoad(false);
                            field.onChange(value);
                          }}
                          disabled={view}
                          error={Boolean(errorsloginform.vendor_Id)}
                          helperText={errorsloginform.vendor_Id && errorsloginform.vendor_Id.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={5}
                    lg={5}
                    xl={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "50px",
                      maxHeight: "53.13px",
                    }}
                  >
                    <CustomButton
                      variant="outlined"
                      name={"View All Contracts"}
                      onClick={() => {
                        setViewAllContracts(true);
                      }}
                      size="small"
                      className={styles.viewAllContractButton}
                      disabled={view || watch("company_Id") === null || watch("vendor_Id") === null}
                    />
                    {isEditData ? (
                      <Typography variant="body1" className={styles.contractNumberStyle}>
                        Contract Number - {isEditData?.contract_Ref_Number}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                    <Controller
                      name="serviceMappings"
                      control={control}
                      defaultValue={[]}
                      rules={{
                        required: "Services is required",
                      }}
                      render={({ field }) => (
                        <CustomMultiSelect
                          optionLabelKey={`ServiceName`}
                          optionIdKey={`ServiceCatalogId`}
                          options={availableServices}
                          readOnly={view}
                          required={true}
                          label={"Select Services"}
                          value={field.value}
                          onChange={(field: any) => {
                            setValue("serviceMappings", field.target.value);
                            if (field.target.value.length > 0) {
                              clearErrors("serviceMappings");
                            }
                          }}
                          error={Boolean(errorsloginform.serviceMappings)}
                          helperText={
                            errorsloginform.serviceMappings && errorsloginform.serviceMappings?.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                    <Controller
                      name="process_Type_Id"
                      control={control}
                      defaultValue={0}
                      rules={{
                        required: "Process Type is required",
                      }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"process_Type_Id"}
                          valueKey={"LovId"}
                          optionLabelKey={`Options`}
                          options={contractAction}
                          label={"Contract Action"}
                          value={field.value}
                          onChange={field.onChange}
                          disabled={view || true}
                          error={Boolean(errorsloginform.process_Type_Id)}
                          helperText={
                            errorsloginform.process_Type_Id && errorsloginform.process_Type_Id.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {watch("process_Type_Id") !== null ? (
                  <React.Fragment>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                        <Controller
                          name="contract_Type"
                          control={control}
                          defaultValue={0}
                          rules={{
                            required: "Contract Type is required",
                          }}
                          render={({ field }) => (
                            <CustomSelect
                              name={"contract_Type"}
                              valueKey={"contractType_Id"}
                              optionLabelKey="contractType_Name"
                              options={contractTypeOptions}
                              label={"Contract Type"}
                              value={field.value}
                              onChange={field.onChange}
                              labelAddNew="Clause Type"
                              disabled={view}
                              error={Boolean(errorsloginform.contract_Type)}
                              helperText={
                                errorsloginform.contract_Type && errorsloginform.contract_Type.message?.toString()
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={3}
                        lg={3}
                        xl={3}
                        style={{ maxHeight: "57.13px" }}
                      >
                        <Controller
                          name="hasParentContract"
                          control={control}
                          render={({ field }) => (
                            <CustomCheckBox
                              isStyle={false}
                              //readOnly={props.view}
                              alignItems="center"
                              label="It has a Parent Contract"
                              onChange={field.onChange}
                              value={field.value}
                              withLabelStyle={true}
                              disabled={view}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                        <Controller
                          name="parent_Id"
                          control={control}
                          defaultValue={0}
                          rules={{
                            required: !watch("hasParentContract") || "Parent is required",
                          }}
                          render={({ field }) => (
                            <CustomSelect
                              name={"parent_Id"}
                              valueKey={"Id"}
                              optionLabelKey="contract_name"
                              required={watch("hasParentContract")}
                              options={parentContracts}
                              label={"Parent Contract"}
                              value={field.value}
                              onChange={field.onChange}
                              disabled={view || !watch("hasParentContract")}
                              error={Boolean(errorsloginform.parent_Id)}
                              helperText={errorsloginform.parent_Id && errorsloginform.parent_Id.message?.toString()}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                      {clone || (isEditData && isEditData?.cloned_Vendor_Id !== 0) ? (
                        <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                          <Controller
                            name="cloned_Vendor_Id"
                            control={control}
                            defaultValue={0}
                            rules={{
                              required: "Vendor is required",
                            }}
                            render={({ field }) => (
                              <CustomSelect
                                name={"vendor_Id"}
                                valueKey={"Vendor_Id"}
                                optionLabelKey="Legal_Name"
                                options={newVendorOptions}
                                label={"New Vendor"}
                                value={field.value}
                                onChange={(value) => {
                                  field.onChange(value);
                                }}
                                disabled={view}
                                error={Boolean(errorsloginform.vendor_Id)}
                                helperText={errorsloginform.vendor_Id && errorsloginform.vendor_Id.message?.toString()}
                              />
                            )}
                          />
                        </Grid>
                      ) : null}
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                        <Controller
                          name="contract_Template_Id"
                          control={control}
                          defaultValue={0}
                          rules={{
                            required: "Contract Template is required",
                          }}
                          render={({ field }) => (
                            <CustomSelect
                              name={"contract_Template_Id"}
                              valueKey={"contract_Template_Id"}
                              optionLabelKey="contract_Template_Name"
                              options={contractTemplates}
                              label={
                                clone || (isEditData && isEditData?.cloned_Vendor_Id !== 0)
                                  ? "Select Existing Contract"
                                  : "Contract Template"
                              }
                              value={field.value}
                              onChange={field.onChange}
                              onAddClick={() => {
                                //setIsAddNewContractTemplate(true);
                                navigate("/addTemplate");
                              }}
                              addField={true}
                              labelAddNew="Contract Template"
                              disabled={view}
                              error={Boolean(errorsloginform.contract_Template_Id)}
                              helperText={
                                errorsloginform.contract_Template_Id &&
                                errorsloginform.contract_Template_Id.message?.toString()
                              }
                              optionText={
                                contractTemplates.length === 0
                                  ? "Please find the possible options available or please use create new contract template option"
                                  : null
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                        <Controller
                          key={`key_initaiteContract`}
                          name="workflow_Id"
                          control={control}
                          defaultValue={0}
                          rules={{
                            required: "WorkFlow is required",
                          }}
                          render={({ field }) => (
                            <CustomSelect
                              key={`${field.value}_key_initaiteContract`}
                              name={"workflow_Id"}
                              valueKey={"Id"}
                              optionLabelKey="WorkflowName"
                              options={workflowDataOptions}
                              label={"WorkFlow"}
                              value={field.value}
                              onChange={field.onChange}
                              disabled={view || workFlowTrigger}
                              error={Boolean(errorsloginform.workflow_Id)}
                              helperText={
                                errorsloginform.workflow_Id && errorsloginform.workflow_Id.message?.toString()
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ pt: 2, display: "flex", flexDirection: "column" }}>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                        <Controller
                          name="isBoardApprovalNeeded"
                          control={control}
                          render={({ field }) => (
                            // <CustomCheckBox
                            //   isStyle={false}
                            //   //readOnly={props.view}
                            //   alignItems="center"
                            //   label="Is Board Approval Needed"
                            //   onChange={field.onChange}
                            //   value={field.value}
                            //   withLabelStyle={false}
                            //   disabled={view}
                            // />

                            <ListItemButton
                              key={"isBoardApprovalNeeded"}
                              role="listitem"
                              onClick={field.onChange}
                              sx={{
                                justifyContent: "flex-start",
                                wordBreak: "break-word",
                                pl: 1,
                              }}
                            >
                              <CustomCheckBox value={field.value} Width={"15%"} />
                              <Typography variant="subtitle2"> {"Is Board Approval Needed"}</Typography>
                            </ListItemButton>
                          )}
                        />
                      </Grid>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                        <Controller
                          name="isPerpetualContract"
                          control={control}
                          render={({ field }) => (
                            // <CustomCheckBox
                            //   isStyle={false}
                            //   //readOnly={props.view}
                            //   alignItems="center"
                            //   label="Is Perpetual Contract"
                            //   onChange={field.onChange}
                            //   value={field.value}
                            //   withLabelStyle={false}
                            //   disabled={view}
                            // />
                            <ListItemButton
                              key={"isPerpetualContract"}
                              role="listitem"
                              onClick={field.onChange}
                              sx={{
                                justifyContent: "flex-start",
                                wordBreak: "break-word",
                                pl: 1,
                              }}
                            >
                              <CustomCheckBox value={field.value} Width={"15%"} />
                              <Typography variant="subtitle2"> {"Is Perpetual Contract"}</Typography>
                            </ListItemButton>
                          )}
                        />
                      </Grid>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                        <Controller
                          name="isStampingNeeded"
                          control={control}
                          render={({ field }) => (
                            // <CustomCheckBox
                            //   isStyle={false}
                            //   //readOnly={props.view}
                            //   alignItems="center"
                            //   label="Is Stamping Needed"
                            //   onChange={field.onChange}
                            //   value={field.value}
                            //   withLabelStyle={false}
                            //   disabled={view}
                            // />
                            <ListItemButton
                              key={"isStampingNeeded"}
                              role="listitem"
                              onClick={field.onChange}
                              sx={{
                                justifyContent: "flex-start",
                                wordBreak: "break-word",
                                pl: 1,
                              }}
                            >
                              <CustomCheckBox value={field.value} Width={"15%"} />
                              <Typography variant="subtitle2"> {"Is Stamping Needed"}</Typography>
                            </ListItemButton>
                          )}
                        />
                      </Grid>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                        <Controller
                          name="isCostApprovalNeeded"
                          control={control}
                          render={({ field }) => (
                            // <CustomCheckBox
                            //   isStyle={false}
                            //   //readOnly={props.view}
                            //   alignItems="center"
                            //   label="Is Cost Approval Needed"
                            //   onChange={field.onChange}
                            //   value={field.value}
                            //   withLabelStyle={false}
                            //   disabled={view}
                            // />
                            <ListItemButton
                              key={"isCostApprovalNeeded"}
                              role="listitem"
                              onClick={(val) => {
                                field.onChange(val);
                                setValue("isCostApprovalNeeded", !getValues("isCostApprovalNeeded"));
                              }}
                              sx={{
                                justifyContent: "flex-start",
                                wordBreak: "break-word",
                                pl: 1,
                              }}
                            >
                              <CustomCheckBox
                                key={"isCostApprovalNeeded"}
                                value={field.value}
                                Width={"15%"}
                                onChange={(field) => {
                                  setValue("isCostApprovalNeeded", field.target.checked);
                                }}
                              />
                              <Typography variant="subtitle2"> {"Is Cost Approval Needed"}</Typography>
                            </ListItemButton>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                        <Controller
                          key={"Project_Cost_vendor_Contract"}
                          name="project_Cost"
                          control={control}
                          rules={{
                            required: !costApproval || "Project Cost is Required",
                          }}
                          render={({ field }) => (
                            <CustomNumberField
                              key={`Project_Cost_vendor_Contract`}
                              name={"ProjectCost"}
                              label={"Project Cost"}
                              value={field.value}
                              onChange={(val) => {
                                field.onChange(+val);
                              }}
                              required={costApproval}
                              disabled={view || !costApproval}
                              isDecimal={true}
                              error={Boolean(errorsloginform.project_Cost)}
                              helperText={
                                errorsloginform.project_Cost && errorsloginform.project_Cost.message?.toString()
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3} xl={3}>
                        <Controller
                          key="currency_Id_Initiate_Contract"
                          name="currency_Id"
                          control={control}
                          rules={{
                            required: !costApproval || "Currency code is Required",
                          }}
                          render={({ field }) => (
                            <CustomAutoComplete
                              optionValueKey={"CurrencyId"}
                              optionLabelKey="mergedCurrency"
                              options={getCurrency || []}
                              value={field.value}
                              onChange={field.onChange}
                              required={costApproval}
                              label={"Code"}
                              key={"CurrencyId"}
                              disabled={view || !costApproval}
                              error={Boolean(errorsloginform.currency_Id)}
                              helperText={
                                errorsloginform.currency_Id && errorsloginform.currency_Id.message?.toString()
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid container sx={{ pt: 2 }}>
                      <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={3}
                        lg={3}
                        xl={3}
                        sx={{ display: "flex" }}
                      >
                        <CustomButton
                          variant="contained"
                          name={"Preview Contract"}
                          onClick={() => {
                            formatPreviewContract();
                            dispatch(updateHeaderMenu(false));
                            dispatch(updateMenu(false));
                            console.log("Preview contract");
                          }}
                          size="small"
                          disabled={!isDisabled}
                          //disabled={true}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Box>
            </Box>
          </Grid>
          {/* <Divider
        sx={{
          borderBottomWidth: 3,
        }}
      /> */}

          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
            py={2}
            visibility={isDisableActionButton ? "hidden" : "visible"}
          >
            <CustomButton
              variant="outlined"
              name={"Save as draft"}
              onClick={() => {
                closeForm(0);
              }}
              size="small"
              disabled={true}
            />
            {/* <CustomButton
          variant="contained"
          name={"Save as Draft"}
          onClick={handleSaveAsDraft}
          disabled={true}
          size="small"
        /> */}
            {view ? null : (
              <CustomButton
                variant="contained"
                name={edit ? "Update" : "Initiate Workflow"}
                onClick={() => {
                  handleNext();
                }}
                size="small"
              />
            )}
          </Stack>
          <CustomDialog
            show={isAddNewClauseType}
            onHide={() => setIsAddNewContractTemplate(false)}
            maxWidth="md"
            header="Add Contract Type Master"
            contentNode={<AddContractTypeMaster onClose={() => setIsAddNewContractTemplate(false)} />}
          />

          <CustomDialog
            show={viewAllContracts}
            onHide={() => setViewAllContracts(false)}
            maxWidth="md"
            header="View Filtered Contracts"
            contentNode={<ContractMaster view={true} />}
          />
          {/* <CustomDialog
            show={isPreviewContract && previewId}
            onHide={() => setIsPreviewContract(false)}
            maxWidth="lg"
            header={dynamicFileName}
            contentNode={
              <OnlyOfficeTextEditor templateId={previewId} fileName={dynamicFileName} editorsMode={"edit"} />
            }
          /> */}
        </Box>
      )}
      {!navigateRR && isPreviewContract && previewId && (
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
            <CustomButton
              variant="outlined"
              name={"Back"}
              onClick={async () => {
                await dispatch(deletePreviewTemplateData(previewId));
                setIsPreviewContract(false);
                dispatch(updateHeaderMenu(true));
                dispatch(updateMenu(true));
              }}
              size="small"
            />
          </Box>
          {previewId && (
            <OnlyOfficeTextEditor
              templateId={previewId}
              fileName={dynamicFileName}
              editorsMode={"edit"}
              isNewContract={true}
            />
          )}
        </Box>
      )}
      {navigateRR && (
         <ContractRolesandResponsibility onClose={() => {
          if(onClose){
            onClose();
          }
        }} isEditData={rrinstanceData} />
      )}
    </React.Fragment>
  );
};

export default InitiateContract;
