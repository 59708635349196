import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { addEditDocument, getDocumentList, updateDocumentAddandEdit } from "../../../store/slices/documentMasterData";
import { getLovList, updateLovAddandEdit } from "../../../store/slices/lovMasterData";
import ProgressBar from "../../../common/progressBar/progressBar";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import { getListOfValues } from "../../../store/slices/ListOfValues";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddListValues } from "../listofValues/AddListValues";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { LovConfig, ToasterActivityConfig } from "../../../config/config";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";

interface FormData {
  Document_Id?: number;
  Document_Purpose: string;
  Document_Name: string;
  DocType_Extn_Id: any;
  Assessment_Type: any;
  IsMandatory: string;
  IsPerpetual: string;
  ExpiryDate: null;
  ShortName: string;
  IsActive: string;
}

export interface DocumentMasterProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  isEditData?: any;
}

export const AddDocumentMaster: React.FC<DocumentMasterProps> = ({ view, closePopUp, edit, isEditData }) => {
  const dispatch = useAppDispatchThunk();
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const [message, setMessage] = useState<boolean>(false);
  const [addPopup, setAddPopup] = useState<boolean>(false);
  const [addPopupHeader, setAddPopupHeader] = useState<string>("");
  const [lovName, setLovName] = useState<string>("");
  const [IsCancel, setIsCancel] = useState<boolean>(false);
  const { DocumentTable, isDocumentAdded, isDocumentDeleted, ToasterMsg, isLoading } = useSelector(
    (state: RootState) => state.DocumentMasterData
  );
  console.log(isEditData, "isEditData");
  const { islovAdded, ToasterMsg: LovToasterMsg } = useSelector((state: RootState) => state.lovMasterData);
  const { lovTable } = useSelector((state: RootState) => state.listOfValues);

  const pharsedDocName = isEditData ? JSON.parse(isEditData?.DocTypeExtnId) : [];
  const optionsArray = pharsedDocName && pharsedDocName.length > 0 && pharsedDocName.map((doc: any) => doc?.LovId);

  const pharsedAssesmentName = isEditData ? JSON.parse(isEditData?.Assessment_Type) : [];
  const optionsAssesmentArray = pharsedAssesmentName && pharsedAssesmentName.length > 0 && pharsedAssesmentName.map((doc: any) => doc?.LovId);
  const format_Date = dayjs(isEditData ? isEditData?.ExpiryDate : null);

  let formData: any = {
    Document_Id: isEditData ? isEditData?.DocumentId : 0,
    Document_Purpose: isEditData ? isEditData?.PurposeId : "",
    Document_Name: isEditData ? isEditData?.DocumentName : "",
    ShortName: isEditData ? isEditData?.ShortName : "",
    ExpiryDate: isEditData ? format_Date : null,
    IsMandatory: isEditData ? (isEditData?.IsMandatory === "Yes" ? true : false) : true,
    IsPerpetual: isEditData ? (isEditData?.IsPerpetual === "Yes" ? true : false) : true,
    DocType_Extn_Id: isEditData ? optionsArray : [],
    Assessment_Type: isEditData ? optionsAssesmentArray : [],
    // Assessment_Type: isEditData ? isEditData?.Assessment_Type : "",
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
  };

  const {
    handleSubmit: handleLoactionForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
    clearErrors,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  console.log(getValues(), "PurposeId");

  const isActiveDisabled = watch("IsActive");

  const handleSubmit = (data: any) => {
    let formData = data;
    dispatch(updateLoader(true));

    // setLoader(true);
    // formData.DocTypeExtnId = JSON.stringify({
    //   LovId: lovTable["Document Ext"]?.data?.find((item: any) => item?.LovId === data?.DocTypeExtnId)?.LovId,
    //   Options: lovTable["Document Type"]?.data?.find((item: any) => item?.LovId === data?.DocTypeExtnId)?.Options,
    // });

    const transformedDocTypeExtnId = formData.DocType_Extn_Id.map((id: any) => {
      const matchingItem = lovTable[LovConfig.Document_Ext].data.find((item: any) => item.LovId === id);
      return matchingItem ? { LovId: matchingItem.LovId, Options: matchingItem.Options } : null;
    }).filter((item: any) => item !== null);
    console.log(transformedDocTypeExtnId, "transformedDocTypeExtnId");
    const docExtenTypes = transformedDocTypeExtnId.map((item: any) => item?.Options.trim()).join(",");

    const transformedAssesmentId = formData.Assessment_Type.map((id: any) => {
      const matchingItem = lovTable["Assessment Type"].data.find((item: any) => item.LovId === id);
      return matchingItem ? { LovId: matchingItem.LovId, Options: matchingItem.Options } : null;
    }).filter((item: any) => item !== null);
    console.log(transformedAssesmentId, "transformedAssesmentId");
    const FormatAssesmentTypes = transformedAssesmentId.map((item: any) => item?.Options.trim()).join(",");

    formData.DocType_Extn_Id = JSON.stringify(transformedDocTypeExtnId);
    formData.Assessment_Type = JSON.stringify(transformedAssesmentId);
    // formData.Options = formData.Options;
    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 12;
    formData.ModuleName = "Document Master";
    // formData.Remarks = "";
    // formData.PurposeId = data?.Purpose;
    // formData.Purpose = lovTable["Purpose"]?.data.find((item: any) => item?.LovId === data?.Purpose)?.Options;
    formData.CreatedBy = user?.UserName;
    formData.Status = 1;

    let JsonDataForDisplay: any = {
      "Document Name": formData.Document_Name,
      "Short Name": formData.ShortName,
      Purpose: lovTable["Purpose"]?.data.find((item: any) => item.LovId == formData?.Document_Purpose)?.Options || "",
      "Document ExtType": docExtenTypes,
      "Assessment Type": FormatAssesmentTypes,
      // "Assessment Type":
      //   lovTable["Assessment Type"]?.data.find((item: any) => item.LovId == formData?.Assessment_Type)?.Options || "",
      "Is Perpetual": formData.IsPerpetual ? "Yes" : "No",
      // "Expiry Date": !formData.IsPerpetual ? formData.ExpiryDate : null,
      "Is Mandatory": formData.IsMandatory ? "Yes" : "No",
      Status: formData.IsActive ? "Active" : "InActive",
      "Modified By": user?.UserName,
    };

    if (edit) {
      formData.LoggedUser = user?.UserName;
      formData.UserId = userLoginResponse?.UserId;
      formData.IsActive = formData.IsActive ? true : false;
      formData.Activity = ToasterActivityConfig.Edit;
      formData.ActionTaken = ToasterActivityConfig.Edit;
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

      dispatch(addEditDocument(formData));
    } else {
      // formData.LoggedUser = user?.UserName;
      formData.ActionTaken = ToasterActivityConfig.create;
      formData.UserId = userLoginResponse?.UserId;
      formData.Activity = ToasterActivityConfig.create;
      formData.IsActive = true;
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

      dispatch(addEditDocument(formData));
    }
    //console.log(formData, "formData");
  };

  const handleFetchValues = () => {
    dispatch(getListOfValues({ Name: LovConfig.Purpose }));
    dispatch(getListOfValues({ Name: LovConfig.Assessment_Type }));
    dispatch(getListOfValues({ Name: LovConfig.Document_Ext }));
  };
  useEffect(() => {
    handleFetchValues();
  }, []);

  useEffect(() => {
    if (isDocumentAdded) {
      dispatch(
        getDocumentList({
          FormID: 2,
        })
      );
      dispatch(updateDocumentAddandEdit(false));
      reset(formData);
      dispatch(updateLoader(false));

      if (closePopUp) {
        closePopUp();
        // setLoader(false);
      }
    }
  }, [isDocumentAdded]);

  useEffect(() => {
    if (islovAdded === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: LovToasterMsg }));
      dispatch(updateLovAddandEdit(false));
      dispatch(getLovList({ Name: lovName }));
    }
  }, [islovAdded]);
  const onAddType = (header: string, fieldName: any, LovName: any) => {
    clearErrors(fieldName);
    setAddPopupHeader(header);
    setLovName(LovName);
    setAddPopup(true);
  };

  const handleCancelPopup = () => {

    if (isDirty) {
      setIsCancel(true);
    } else {
      closePopUp && closePopUp();
    }
  };

  return (
    <>
      <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
        <Grid
          // item
          // item xs={12} sm={12} md={6} lg={6} xl={6}
          sx={{
            backgroundImage: `url()`,
            backgroundRepeat: "no-repeat",
            // backgroundColor: (t) =>
            //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
            // backgroundSize: "cover",
            backgroundPosition: "center",
            // height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "0",
          }}
        >
          {false && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                margin: "0",
              }}
            >
              {!view ? (
                <>
                  {!edit ? (
                    <Typography variant="h6" sx={{ m: 1 }}>
                      Add Document Master
                    </Typography>
                  ) : (
                    <Typography variant="h6" sx={{ m: 1 }}>
                      Edit Document Master
                    </Typography>
                  )}
                </>
              ) : (
                <Typography variant="h6" sx={{ m: 1 }}>
                  View Document Master
                </Typography>
              )}
            </Box>
          )}
          <Grid
            sx={{
              height: "auto",
              width: "100%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              sx={{
                px: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
              <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  // md={6}
                  >
                    <Controller
                      name="Document_Name"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Document Name is Required",
                        validate: {
                          noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                          minLength: (value) => value.length >= 1 || "Minimum 1 characters required",
                          maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                          ValueLimitaion: (value) =>
                            /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value.trim()) ||
                            "Name cannot contain special characters and empty value",
                          // ValueLimitation: (value) => /^[a-zA-Z0-9,.\- ]+$/.test(value.trim()) || "Name cannot contain special characters except for , . - and spaces",
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name={"Document_Name"}
                          required={true}
                          disabled={view || !isActiveDisabled}
                          label={"Document Name"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.Document_Name)}
                          helperText={errorsloginform.Document_Name && errorsloginform.Document_Name.message?.toString()}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  // md={6}
                  >
                    <Controller
                      name="ShortName"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Short Name is Required",
                        validate: {
                          noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                          // noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                          minLength: (value) => value.length >= 1 || "Minimum 1 characters required",
                          maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                          // ValueLimitaion: (value) =>
                          //   /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) ||
                          //   "Name cannot contain special characters and empty value",
                          ValueLimitation: (value) =>
                            /^[a-zA-Z0-9,.\- ]+$/.test(value.trim()) ||
                            "Name cannot contain special characters except for , . - and spaces",
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name={"ShortName"}
                          required={true}
                          disabled={view || !isActiveDisabled}
                          label={"Short Name"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.ShortName)}
                          helperText={errorsloginform.ShortName && errorsloginform.ShortName.message?.toString()}
                        />
                      )}
                    />
                  </Grid>

                  {/* {lovTable["Purpose"]?.data && lovTable["Purpose"]?.data.length > 0 && ( */}
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  // md={6}
                  >
                    <Controller
                      name="Document_Purpose"
                      control={control}
                      rules={{ required: "Purpose is required" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"Purpose"}
                          valueKey={"LovId"}
                          optionLabelKey="Options"
                          // addField={true}
                          onAddClick={() => {
                            onAddType("Add Purpose", "Purpose", "Purpose");
                          }}
                          options={lovTable["Purpose"]?.data}
                          disabled={view || !isActiveDisabled}
                          required={true}
                          label={"Purpose"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.Document_Purpose)}
                          helperText={
                            errorsloginform.Document_Purpose && errorsloginform.Document_Purpose.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                  {/* )} */}
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  // md={6}
                  >
                    <Controller
                      name="DocType_Extn_Id"
                      control={control}
                      rules={{
                        required: "Document Extension is Required",
                        validate: (value) =>
                          (Array.isArray(value) && value.filter((item) => item !== undefined).length > 0) ||
                          "Document Extension is Required",
                      }}
                      render={
                        ({ field }) => (
                          // lovTable["Document Ext"]?.data &&
                          // lovTable["Document Ext"]?.data.length > 0 && (
                          <CustomMultiSelect
                            // addField={true}
                            onAddClick={() => {
                              onAddType("Add Document Extension", "DocTypeExtnId", "Document Ext");
                            }}
                            optionLabelKey={`Options`}
                            optionIdKey={`LovId`}
                            options={lovTable["Document Ext"]?.data}
                            required={true}
                            disabled={view || !isActiveDisabled}
                            label={"Document Extension"}
                            value={field.value}
                            onChange={field.onChange}
                            error={Boolean(errorsloginform.DocType_Extn_Id)}
                            helperText={
                              errorsloginform.DocType_Extn_Id && errorsloginform.DocType_Extn_Id.message?.toString()
                            }
                          />
                        )
                        // )
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  // md={6}
                  >
                    <Controller
                      name="Assessment_Type"
                      control={control}
                      rules={{
                        required: "Document Extension is Required",
                        validate: (value) =>
                          (Array.isArray(value) && value.filter((item) => item !== undefined).length > 0) ||
                          "Assessment Type is Required",
                      }}
                      render={
                        ({ field }) => (
                          // lovTable["Document Ext"]?.data &&
                          // lovTable["Document Ext"]?.data.length > 0 && (
                          <CustomMultiSelect
                            // addField={true}
                            // onAddClick={() => {
                            //   onAddType("Add Document Extension", "DocTypeExtnId", "Document Ext");
                            // }}
                            optionLabelKey={`Options`}
                            optionIdKey={`LovId`}
                            options={lovTable["Assessment Type"]?.data}
                            required={true}
                            disabled={view || !isActiveDisabled}
                            label={"Assessment Type"}
                            value={field.value}
                            onChange={field.onChange}
                            error={Boolean(errorsloginform.Assessment_Type)}
                            helperText={
                              errorsloginform.Assessment_Type && errorsloginform.Assessment_Type.message?.toString()
                            }
                          />
                        )
                        // )
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  // md={6}
                  >
                    <Box sx={{ mt: 1 }}>
                      <Controller
                        name="IsPerpetual"
                        control={control}
                        render={({ field }) => (
                          <CustomToogleSwitch
                            label={"Is Perpetual"}
                            dynamicLabel={"Is Perpetual"}
                            disabled={view || !isActiveDisabled}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  // md={6}
                  >
                    <Box sx={{ mt: 1 }}>
                      <Controller
                        name="IsMandatory"
                        control={control}
                        render={({ field }) => (
                          <CustomToogleSwitch
                            label={"Is Mandatory"}
                            dynamicLabel={"Is Mandatory"}
                            disabled={view || !isActiveDisabled}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "right",
                    mt: 2,
                  }}
                >
                  {view ? (
                    <CustomButton
                      type="reset"
                      name={"Cancel"}
                      onClick={() => {
                        closePopUp && closePopUp();
                      }}
                    />
                  ) : edit ? (
                    <React.Fragment>
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          reset(formData);
                          handleCancelPopup();
                        }}
                      />
                      <CustomButton
                        type="reset"
                        disabled={!isDirty}
                        name={"Update"}
                        onClick={handleLoactionForm(handleSubmit)}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          reset(formData);
                          handleCancelPopup();
                        }}
                      />
                      <CustomButton
                        type="reset"
                        disabled={!isDirty}
                        name={"Submit"}
                        onClick={handleLoactionForm(handleSubmit)}
                      />
                    </React.Fragment>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomDialog
          show={addPopup}
          onHide={() => {
            setAddPopup(false);
          }}
          maxWidth={"md"}
          minHeight={"50vh"}
          header={addPopupHeader}
          contentNode={
            <>
              <AddListValues
                closePopUp={() => {
                  setAddPopup(false);
                  handleFetchValues();
                }}
                lovID={lovName}
              />
            </>
          }
        />

        {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
      </Grid>
      <ModalPopUp
        onDeleteMethod={() => closePopUp && closePopUp()}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />
    </>
  );
};
