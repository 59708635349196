import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Tooltip,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PasswordIcon from "@mui/icons-material/Password";
import LockResetIcon from "@mui/icons-material/LockReset";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Sidenavbar from "../navbars/SideNavBarNew";
import { Link, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import { logoutUser } from "../../store/slices/authorization";
import { useSelector } from "react-redux";
import { updateMenu, updatethemeMode } from "../../store/slices/menu";
// import { Search } from "@mui/icons-material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import headerstyle from "./header.module.scss";
import headerlogo from "../../assets/headerlogo.png";
import { useTheme } from "@mui/material/styles";
import { ACTIVITY } from "../../constants/app.constants";
import { addEventLog, addEventLogRequestParam } from "../../store/slices/eventLog";
import Notifications from "../notifications/Notifications";
import CustomSvg from "../../common/CustomSvg";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import ImageHandler from "./imageHandler/ImageHandler";
import * as mime from "react-native-mime-types";
import { DownLoadAFile } from "../../store/slices/fileHandler";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  border: "1px solid",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset",
  borderColor: alpha(theme.palette.common.black, 0.15),
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 250,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const StyledNotificationMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 250,
    overflow: "hidden",
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const Header = () => {
  const [menuList, setMenuList] = useState(["UserName", "Admin"]);
  const [isMenuOpen, setisMenuOpen] = useState(false);
  const [imageDialog, setImageDialog] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [isNotificationMenuOpen, setisNotificationMenuOpen] = React.useState<null | HTMLElement>(null);
  const [notificationCount, setNotificationCount] = useState(0);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const theme = useTheme();

  const { isAuthenticated, user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { isShown } = useSelector((state: RootState) => state.menuShown);
  const { downloadFile } = useSelector((state: RootState) => state.fileHandler);

  const { themeMode, show } = useSelector((state: RootState) => state.menu);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenNotificationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setisNotificationMenuOpen(event.currentTarget);
  };
  const handleCloseNotificationMenu = () => {
    setisNotificationMenuOpen(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerToggle = () => {
    dispatch(updateMenu(!show));
  };

  const themeChange = (themeMode: any) => {
    if (themeMode == "dark") {
      dispatch(updatethemeMode("light"));
    } else {
      dispatch(updatethemeMode("dark"));
    }
  };

  const handleChangePassword = () => {
    navigate("/changepassword");
  };
  const [blob, setBlob] = useState<string>(
    user?.ProfilePicture !== "" ? "data:image/jpeg;base64," + user?.ProfilePicture : ""
  );
  const profileUrl = user?.ProfileUrl || "";
  const profilePicture = sessionStorage.getItem("profilePicture");

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (profilePicture) {
        try {
          const fileUrl = profilePicture;
          const data = await dispatch(DownLoadAFile({ fileUrl })).unwrap();
          if (data) {
            const objectURL = "data:image/jpeg;base64," + data;
            setBlob(objectURL);
          }
        } catch (error) {
          console.error("Error fetching profile picture:", error);
        }
      } else if (user?.ProfilePicture !== "") {
        const objectURL = "data:image/jpeg;base64," + user?.ProfilePicture;

        setBlob(objectURL);
      } else {
        setBlob("");
      }
    };

    fetchProfilePicture();
  }, [profilePicture, user?.ProfilePicture, user]);
  useEffect(() => {
    if (user?.ProfileUrl) {
      sessionStorage.setItem("profilePicture", user?.ProfileUrl);
    }
  }, []);

  return (
    <Box
      sx={{
        height: "60px",
        backgroundColor: "background.default",
        "&.MuiPaper-elevation4": {
          boxShadow: "0px 4px 16px 0px rgba(103, 103, 103, 0.16)",
        },
      }}
      // position="static"
    >
      {/* <Container maxWidth="xl" >
        <Toolbar disableGutters> */}
      {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className={headerstyle.sliding_border} sx={{ display: "flex" }}>
          {isShown && (
            <Tooltip title="Menu" arrow>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { xs: "", md: "" }, color: "#ffff" }}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={headerlogo} alt="Logo" style={{ width: "70px", height: "27px", objectFit: "contain" }} />
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Search sx={{ fontSize: "20px", color: "text.primary" }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} />
        </Search>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: "flex", gap: 1, paddingRight: "20px" }}>
         {isShown && <IconButton onClick={handleOpenNotificationMenu} sx={{ fontSize: "20px", color: "text.primary" }}>
            <Badge
              // sx={{
              //   color: "#0000007d",
              // }}
              badgeContent={notificationCount}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: theme.palette.primary.main,
                  color: "#fff",
                  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                },
              }}
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>}
          <Tooltip title="Toggle Theme" arrow>
            <IconButton onClick={() => themeChange(themeMode)} sx={{ fontSize: "20px", color: "text.primary" }}>
              {themeMode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {/* <Avatar alt={user?.UserName} src="/static/images/avatar/2.jpg" /> */}
              <Avatar alt={user?.UserName} src={blob || "/static/images/avatar/2.jpg"} />
            </IconButton>
          </Tooltip>

          <StyledMenu
            sx={{ mt: "45px", padding: "0px !important" }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Box
              sx={{
                minWidth: "200px",
                paddingX: "10px",
                paddingY: "10px",
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <Avatar alt={user?.UserName} src="/static/images/avatar/2.jpg" /> */}
              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  "&:hover .overlay": {
                    opacity: 1,
                    cursor: "pointer",
                  },
                }}
              >
                {/* {user?.ProfilePicture !== "" ? (
                  <Avatar alt={user?.UserName} src={user?.ProfileUrl} />
                ) : (
                  <Avatar alt={user?.UserName} src="/static/images/avatar/2.jpg" />
                )} */}
                <Avatar alt={user?.UserName} src={blob || "/static/images/avatar/2.jpg"} />

                <Box
                  className="overlay"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: 0,
                    borderRadius: "50%",
                    transition: "opacity 0.3s",
                    paddingTop: "0px",
                  }}
                  onClick={() => {
                    setImageDialog(true);
                    handleCloseUserMenu();
                  }}
                >
                  <CustomSvg size={"24px"} color={"#ffff"} name={"camera"} />
                </Box>
              </Box>

              <Box sx={{ paddingLeft: "10px" }}>
                <Box sx={{ color: "#ffff" }}>
                  <Typography>
                    {user?.UserName} {`(${user?.UserRole})`}
                  </Typography>
                </Box>
                <Box sx={{ color: "#ffff" }}>
                  <Typography>{user?.UserEmail}</Typography>
                </Box>
              </Box>
            </Box>

            <MenuItem key={"ChangePassword"} sx={{ paddingY: "10px" }} onClick={handleChangePassword}>
              <LockResetIcon fontSize="small" />{" "}
              <Typography fontSize="14px" sx={{ paddingLeft: "10px" }}>
                {/* <Link to="/changepassword" style={{ textDecoration: 'none', color: theme.palette.text.primary }}>ChangePassword</Link> */}
                Change Password
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                const addEventLogPayload: addEventLogRequestParam = {
                  ModuleName: "Authentication",
                  Action: ACTIVITY.LOGOUT,
                  Description: "Logout",
                  UserId: userLoginResponse && userLoginResponse.UserId ? userLoginResponse.UserId : 0,
                  CreatedBy: user?.UserName || "",
                };
                dispatch(addEventLog(addEventLogPayload));
                dispatch(logoutUser(false));
                dispatch({ type: "LOGOUT" });

                // Clear any additional data such as cookies or local storage here
              }}
              key={"logout-button-menu"}
              sx={{ paddingY: "10px" }}
            >
              <LogoutIcon fontSize="small" />{" "}
              <Typography fontSize="14px" sx={{ paddingLeft: "10px" }}>
                Logout
              </Typography>
            </MenuItem>
          </StyledMenu>

          <StyledNotificationMenu
            sx={{ mt: "45px", padding: "0px !important" }}
            anchorEl={isNotificationMenuOpen}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(isNotificationMenuOpen)}
            onClose={handleCloseNotificationMenu}
          >
            <Notifications
              notificationCount={notificationCount}
              setNotificationCount={setNotificationCount}
              onClose={handleCloseNotificationMenu}
            />
          </StyledNotificationMenu>
        </Box>
      </Box>
      {/* </Toolbar>
      </Container> */}

      <Drawer
        open={isMenuOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",

            backgroundColor: "sidebar.background",
            color: "sidebar.textColor",
          },
        }}
      >
          {/* {/* <Sidenavbar handleOpen={function (data: any): void {
          throw new Error("Function not implemented.");
        } } setIsBackDropIsOpen={undefined} /> */} 
        {/* {/* <Sidenavbar handleOpen={function (data: any): void {
          throw new Error("Function not implemented.");
        } } setIsBackDropIsOpen={undefined} /> */} 
      </Drawer>
      <CustomDialog
        show={imageDialog}
        onHide={() => {
          setImageDialog(false);
        }}
        maxWidth={"sm"}
        header={"Change your profile picture"}
        contentNode={
          <>
            <ImageHandler
              profileUrl={profileUrl}
              imageData={blob || ""}
              onClose={() => {
                setImageDialog(false);
              }}
            />
          </>
        }
      />
    </Box>
  );
};
export default Header;
