import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AnyMxRecord } from "dns";
import contractRolesAndResponsibilityService from "../services/contractRolesAndResponsibility";

export interface contractRolesAndResponsibilityData {
  contractRolesAndResponsibilityData: any[];
  isLoading: boolean;
  isAddEditRolesAndResonsibilityLoading: boolean;
  isRolesAndResponsibilityAdded: boolean;
  isRolesAndResponsibilityUpdated: boolean;
  isRolesAndResponsibilityDeleted: boolean;
  RolesAndResonsibilitytoasterMsg: { message: string; status: string };
  RolesAndResonsibilityInstanceId: any;
  ContractRolesAndResponsibilityversionHistory: {
    ContractRolesAndResponsibilityversionHistory: any[];
    RolesAndResponsibilityVHeader: any[];
  };
  workFLowInstanceData: any;
}

export const initialState: contractRolesAndResponsibilityData = {
  contractRolesAndResponsibilityData: [],
  isLoading: false,
  isAddEditRolesAndResonsibilityLoading: false,
  isRolesAndResponsibilityAdded: false,
  isRolesAndResponsibilityUpdated: false,
  isRolesAndResponsibilityDeleted: false,
  RolesAndResonsibilitytoasterMsg: { message: "", status: "" },
  RolesAndResonsibilityInstanceId: "",
  ContractRolesAndResponsibilityversionHistory: {
    ContractRolesAndResponsibilityversionHistory: [],
    RolesAndResponsibilityVHeader: [],
  },
  workFLowInstanceData:null
};

export const getRolesAndResponsibilityList = createAsyncThunk(
  "getRolesAndResponsibilityList",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.getRolesAndResponsiblityList(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addRolesAndResponsibility = createAsyncThunk(
  "addRolesAndResponsibility",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
       const response = await contractRolesAndResponsibilityService.addRoleAndResponsiblity(requesrParams);
       return response.data;
      // const toaster = { toaster: { message: "Roles and Responsibility Added SuccessFully", status: "Success" } };
      // return toaster;
    } catch (e: any) {
      return rejectWithValue({ message: "Roles and Responsibility Added SuccessFully", status: "Success" });
      //rejectWithValue({message:e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage})
    }
  }
);

export const getGetVCWorkflowProcessesData = createAsyncThunk(
  "getGetVCWorkflowProcessesData",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.getGetVCWorkflowProcesses(requesrParams);
      return response;      
    } catch (e: any) {      
      rejectWithValue({message:e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage})
    }
  }
);


export const updateRolesAndResponsibility = createAsyncThunk(
  "updateRolesAndResponsibility",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.updateRoleAndResponsiblity(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteRolesAndResponsibility = createAsyncThunk(
  "deleteRolesAndResponsibility",
  async (RolesAndResponsibilityId: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.deleteRoleAndResponsiblity(RolesAndResponsibilityId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const contractRolesAndResponsibilitySlice = createSlice({
  name: "rolesAndResponsibility",
  initialState,
  reducers: {
    updaterolesandresponsAddandEdit: (state, action) => {
      state.isRolesAndResponsibilityAdded = false;
      state.isRolesAndResponsibilityUpdated = false;
      state.isRolesAndResponsibilityDeleted = false;
      state.RolesAndResonsibilityInstanceId = "";
    },

    updaterolesandresponsresposeData: (state, action) => {
      state.RolesAndResonsibilityInstanceId = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRolesAndResponsibilityList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRolesAndResponsibilityList.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractRolesAndResponsibilityData = action.payload
          ? action.payload.map((user: any) => {
              user.IsActive = user.IsActive === true ? "Active" : "Inactive";
              return user;
            })
          : [];
        state.isLoading = false;
      })
      .addCase(getRolesAndResponsibilityList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addRolesAndResponsibility.pending, (state) => {
        state.isLoading = true;
        state.isAddEditRolesAndResonsibilityLoading = true;
      })
      .addCase(addRolesAndResponsibility.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action, "rraction");

        state.isRolesAndResponsibilityAdded = true;
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action?.payload?.toasterMessage;
        //state.RolesAndResonsibilityInstanceId = action.payload?.ResponseProjectDetails;
      })
      .addCase(addRolesAndResponsibility.rejected, (state) => {
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
      })
      .addCase(updateRolesAndResponsibility.pending, (state) => {
        state.isLoading = true;
        state.isAddEditRolesAndResonsibilityLoading = true;
      })
      .addCase(updateRolesAndResponsibility.fulfilled, (state, action: PayloadAction<any>) => {
        state.isRolesAndResponsibilityUpdated = true;
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action.payload.Message;
      })
      .addCase(updateRolesAndResponsibility.rejected, (state) => {
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
      })
      .addCase(deleteRolesAndResponsibility.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRolesAndResponsibility.fulfilled, (state, action: PayloadAction<any>) => {
        state.isRolesAndResponsibilityDeleted = true;
        state.isLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action.payload.Message;
      })
      .addCase(deleteRolesAndResponsibility.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getGetVCWorkflowProcessesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGetVCWorkflowProcessesData.fulfilled, (state, action: PayloadAction<any>) => {        
        state.isLoading = false;
        state.workFLowInstanceData = action.payload;
      })
      .addCase(getGetVCWorkflowProcessesData.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

export const { updaterolesandresponsAddandEdit, updaterolesandresponsresposeData } =
  contractRolesAndResponsibilitySlice.actions;
export default contractRolesAndResponsibilitySlice.reducer;
