import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import Divider from "@mui/material/Divider";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { compare, REGULATION, textFieldWidth } from "../../../utils/constants";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { useTheme } from "@mui/material/styles";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import SearchServices from "../SearchService/SearchServices";
import Editor from "../../../common/editor/Editor";
import { getThirdPartyList } from "../../../store/slices/thirdPartyData";
import { useSelector } from "react-redux";
import { getUsersList } from "../../../store/slices/userData";
import { LovConfig } from "../../../config/config";
import { Controller, useForm, useFormContext } from "react-hook-form";
import {
  addorupdateSubClauses,
  clearData,
  createClause,
  getClauseTypeList,
  updateClause,
  updateClauseDetailsAddandEdit,
} from "../../../store/contractManagement/slices/clauseDataSlice";
import { getDepartmentList } from "../../../store/slices/departmentData";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import { getTemplateList } from "../../../store/contractManagement/slices/templateData";
import { getLovList } from "../../../store/slices/lovMasterData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import AddClauseTypeMaster from "../ClauseTypeMaster/AddClauseTypeMaster";
import { updateClauseMasterDetailsAddandEdit } from "../../../store/contractManagement/slices/clauseMasterSlice";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import SubClause from "./SubClause";
import styles from "./addClause.module.scss";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";

export interface AddCaluseProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: boolean;
  ModuleId?: number | undefined;
  isDisableBreadCrums?: boolean;
  isDisableActionButton?: boolean;
  onSaveClause?: (savedClause: any) => void;
  active?:boolean;
}

export interface AddClauseForm {
  clauseType_Id: number;
  regulator_Id?: number;
  regulatory_Reference?: string;
  clause_Header: string;
  clause: string;
  service_Type_Id?: number;
  service_Group_Id?: number;
  department_Id?: number;
  contract_Template_id?: number;
  isActive?: boolean;
  actionTaken?: string;
  remarks?: string;
  templateMappings?: number[];
  serviceMappings?: number[];
  isNonNegotiableClause?: boolean;
  sla?: boolean;
  pm?: boolean;
  riskId: number;
}

interface serviceMapping {
  clause_ServiceMapping_Id: number;
  serviceCatalog_Id: number;
  isDeleted: boolean;
  isActive: boolean;
}

interface templateMapping {
  clause_CTMapping_Id: number;
  contract_Template_Id: number;
  isDeleted: boolean;
}

const AddClause: React.FC<AddCaluseProps> = ({
  view,
  isEditData,
  edit,
  masterId,
  onClose,
  isDraftData,
  ModuleId,
  isDisableBreadCrums,
  isDisableActionButton,
  onSaveClause,
  active= true
}) => {
  const dispatch = useAppDispatchThunk();
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const theme = useTheme();
  const [editorContent, setEditorContent] = useState<any>();
  const [discard, setDiscard] = useState<boolean>(false);
  const { thirdPartyData } = useSelector((state: RootState) => state.thirdPartyData);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { templateList } = useSelector((state: any) => state.contractManagementReducer.templateData);
  const {
    clauseTypeList,
    isClauseDetailsAdded,
    clauseToasterStatus,
    isClauseDetailsUpdated,
    clauseToasterMsg,
    clauseData,
    subClause,
  }: any = contractManagementReducer.clauseData;
  const { isclauseMasterDetailsAdded }: any = contractManagementReducer.clauseMasterData;
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [regulatorData, setRegulatorData] = useState<any[]>([]);
  const [depatmentDropdownData, setDepartmentDropDownData] = useState<any[]>([]);
  const [regulatorFileds, setRegulatorFields] = useState<boolean>(false);
  const [contractTemplates, setContractTemplates] = useState<any[]>([]);
  const [riskValues, setRiskDropValues] = useState<any[]>([]);
  const [riskCategory, setRiskCategory] = useState<any[]>([]);
  const [selectedClauseType, setSelectedClauseType] = useState<any>();
  const [updatedservicesMappings, setUpdatedServicesMappings] = useState<any>([]);
  const [updatedTemplates, setUpdatedtemplates] = useState<any>([]);
  const [isAddNewClauseType, setIsAddNewClauseType] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [enableAddSubClause, setEnableAddSubClause] = useState<boolean>(false);
  const [subClausesData, setSubClausesData] = useState<any>([]);
  const [Option, setOption] = useState("");
  const [isSubEditData, setIsSubEditData] = useState<any>(null);
  const [isDeleteData, setIsDeleteData] = useState<any>("");
  const columns = {
    table_name: "Sub Clause",
    isAction: true,
    columns: [
      {
        Field: "clause_Order",
        FieldType: "string",
        DisplayName: "Sr.",
      },
      {
        Field: "clause_Header",
        FieldType: "string",
        DisplayName: "Header",
      },
      // {
      //   Field: "clause",
      //   FieldType: "string",
      //   DisplayName: "Sub Clause",
      // },
      // {
      //   Field: "clause",
      //   FieldType: "string",
      //   DisplayName: "Clause",
      // },
      {
        Field: "sme",
        FieldType: "string",
        DisplayName: "SME",
      },
      {
        Field: "sla",
        FieldType: "string",
        DisplayName: "SLA",
      },
      {
        Field: "pm",
        FieldType: "string",
        DisplayName: "PM",
      },
      {
        Field: "riskId",
        FieldType: "string",
        DisplayName: "Risk Level",
      },
      {
        Field: "riskMappings",
        FieldType: "string",
        DisplayName: "Risk Applicable",
      },
      {
        Field: "clause_Contract_Header",
        FieldType: "string",
        DisplayName: "SCH For Contract",
      },
    ],
  };
  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      onClose();
    }
  };

  const thisdparty =() =>{
      if(thirdPartyData.length>0){
        const filteredRegionalData = thirdPartyData.filter((item: any) => item.ThirdPartyTypeValue === LovConfig.Regulator);
        setRegulatorData(filteredRegionalData.filter((item: any) => item.IsActive === "Active"));
      }
  }

  const department =()=>{
    const filteredDepartmentData = dataTable.filter((item: any) => item.Parent_LevelValue === "Level 1");
    setDepartmentDropDownData(filteredDepartmentData.filter((item: any) => item.IsActive === "Active"));  
  }

  const template = ()=>{
    let filterTemplates = templateList.filter(
      (item: any) =>
        item.isActive === true &&
        item.contract_Template_Name !== null &&
        item.contract_Template_Name !== undefined &&
        item.contract_Template_Name !== ""
    );
    setContractTemplates([...filterTemplates]);
  }

  const listOfValue = ()=>{
    if(lovTable.length>0){
      setRiskDropValues(
        lovTable.filter((item: any) => item.Name === LovConfig?.RiskCategoryI && item.Options !== "N / A") || []
      );
  
      setRiskCategory(
        lovTable.filter((item: any) => item.Name === LovConfig?.RiskCategoryIII && item.Options !== "N / A") || []
      );
    }    
  }

  useEffect(() => {
    thisdparty()
  }, [thirdPartyData]);

  useEffect(() => {
    department()
    }, [dataTable]);

  useEffect(() => {
    template()
  }, [templateList]);

  useEffect(() => {
    listOfValue()
  }, [lovTable]);

  const mapData = (subCaluses: any[]) => {
    let subCalusesmodifiedData = subCaluses.map((data: any) => {
      let departmentValue = depatmentDropdownData.filter(
        (departmentData: any) => departmentData.DepartmentId === data?.department_Id || departmentData.DepartmentId === data?.department?.departmentId
      );
      let riskId = riskValues.filter((risk: any) => risk.LovId === data?.riskId || data?.risk?.lov_Id);
      let riskCategoryfilterValues = riskCategory.filter((riskCat: any) => {
        return (data?.riskMappings?.length > 0 && data?.riskMappings?.indexOf(riskCat.LovId) > -1) || (data?.riskMappings?.length>0 && typeof(data?.riskMappings[0]) === "object" && data?.riskMappings?.map((data:any)=>data?.riskCategory?.lov_Id).length>0 && data?.riskMappings.map((data:any)=>data?.riskCategory?.lov_Id).indexOf(riskCat?.LovId)> -1)
      });

      return {
        clause_Order: data.clause_Order,
        clause_Header: data.clause_Header,
        clause: data.clause,
        sme: departmentValue.length > 0 ? departmentValue[0].DepartmentName : "",
        sla: data.sla ? "Yes" : "No",
        pm: data.pm ? "Yes" : "No",
        riskId: riskId.length > 0 ? riskId[0].Options : "",
        riskMappings:
          riskCategoryfilterValues.length > 0
            ? riskCategoryfilterValues.map((filterRiskCat: any) => filterRiskCat.Options).join(",")
            : "",
        clause_Contract_Header: data.clause_Contract_Header,
      };
    });
    if (subCalusesmodifiedData.length > 0) {
      setValue("isSubClause", false);
    } else {
      setValue("isSubClause", false);
    }
    setSubClausesData([...subCalusesmodifiedData]);
  };

  const active_Inactive_Clause = () => {
    let subClauses = getValues("subClauses");
    if (subClauses !== undefined && subClauses.length > 0) {
      subClauses = subClauses.filter((data: any) => data.clause_Order !== isDeleteData.clause_Order);
      mapData([...subClauses]);
      setValue("subClauses", [...subClauses]);
      setIsDeleteData("");
      setOption("");
    }
  };

  useEffect(() => {
    if (subClause !== null) {
      let subClauses = getValues("subClauses");
      if (subClauses !== undefined && subClauses.length > 0 && Option === "edit") {
        let findIndex = -1;
        findIndex = subClauses.findIndex((data: any) => data.clause_Order === isSubEditData.clause_Order);
        if (findIndex > -1) {
          subClauses[findIndex] = subClause;
        } else {
          subClauses.push(subClause);
        }
        subClauses.sort((a: any, b: any) => compare(a, b, "clause_Order"));
        mapData([...subClauses]);
        setValue("subClauses", [...subClauses]);
      }else if(subClauses !== undefined && subClauses.length>0){
        let values = [...subClauses];
        values.push(subClause);
        mapData(values);
        setValue("subClauses", values);
      } else {
        let values = [subClause];
        mapData(values);
        setValue("subClauses", values);
      }
      hideAddNewSubClause();
      dispatch(addorupdateSubClauses(null));
    }
  }, [subClause]);

  useEffect(() => {
    let subClauses = getValues("subClauses");
    if (subClauses.length > 0) {
      Promise.all([ department(),listOfValue()]).then((data) => { 
        //mapData([...subClauses]);       
      });
    }
  }, []);

  useEffect(()=>{
    let subClauses = getValues("subClauses");
    if(subClauses.length > 0 && depatmentDropdownData.length>0 && riskValues.length>0 && riskCategory.length>0){
      mapData([...subClauses]);       
    }
  },[depatmentDropdownData,riskValues,riskCategory])



  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    formState: { errors: errorsloginform, isDirty },
  } = useFormContext();

  const updateEditorContent = (content: string) => {
    setValue("clause", content);
    setEditorContent(content);
  };

  useEffect(() => {
    if (isclauseMasterDetailsAdded) {
      dispatch(updateClauseMasterDetailsAddandEdit(false));
      dispatch(getClauseTypeList());
    }
  }, [isclauseMasterDetailsAdded]);

  const enableAddNewSubClause = () => {
    setEnableAddSubClause(true);
    setOption("add");
  };

  const hideAddNewSubClause = () => {
    setEnableAddSubClause(false);
    setOption("");
    setIsSubEditData(null);
  };

  useEffect(() => {
      let regulatorField = getValues("regulator_Id");
    if ((view || edit) && clauseTypeList.length > 0) {
      let regulatorValue = clauseTypeList.filter(
        (item: any) => item.clauseType_Id === isEditData?.clauseType?.clauseType_Id
      );
      if (regulatorValue.length > 0 && regulatorValue[0].clauseType_Name === REGULATION) {
        setRegulatorFields(true);
      } else {
        setRegulatorFields(false);
      }
      setSelectedClauseType(regulatorValue[0]);
      if (isEditData?.serivceMapping?.length > 0) {
        let values = isEditData?.serivceMapping?.map((data: any) => data?.services?.serviceCatalog_Id);
        setSelectedServices(values);
      }
      // if (regulatorValue.length>0 && !regulatorValue[0].isServiceMappingRequired) {
      //   dispatch(updateLoader(false));
      // }
      mapData(isEditData?.subClauses?.length > 0 ? isEditData?.subClauses : []);
    }
    else if(regulatorField && regulatorField!=null){
      let regulatorValue = clauseTypeList.filter(
        (item: any) => item.clauseType_Id === isEditData?.clauseType?.clauseType_Id
      );
      if (regulatorValue.length > 0 && regulatorValue[0].clauseType_Name === REGULATION) {
        setRegulatorFields(true);
      } else {
        setRegulatorFields(false);
      }
    }    
    if (edit) {
      setIsActive(isEditData?.isActive);
    }
     
  }, [edit, view, clauseTypeList]);

  const handleOpen = async (data: any, option: string) => {
    if (option === "edit" || option === "view") {
      let subClauses = getValues("subClauses");
      let subClausesItem = subClauses.filter(
        (subClauseItem: any) => subClauseItem.clause_Order === data.clause_Order
      )[0];
      setIsSubEditData({ ...subClausesItem });
      setEnableAddSubClause(true);
      setOption(option);
    } else if (option === "subdelete") {
      setOption(option);
      setIsDeleteData(data);
    }
    //dispatch(updateLoader(true));
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        rowGap: "15px",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="clauseType_Id"
            control={control}
            defaultValue={0}
            rules={{
              required: "Clause Type is required",
            }}
            render={({ field }) => (
              <CustomSelect
                name={"clauseType_Id"}
                valueKey={"clauseType_Id"}
                optionLabelKey="clauseType_Name"
                options={clauseTypeList}
                label={"Clause Type"}
                value={field.value}
                required={true}
                onChange={(field) => {
                  setValue("clauseType_Id", field);
                  let regulatorValue = clauseTypeList.filter((item: any) => item.clauseType_Id === field);
                  if (regulatorValue.length > 0 && regulatorValue[0].clauseType_Name === REGULATION) {
                    setRegulatorFields(true);
                  } else {
                    setRegulatorFields(false);
                  }
                  setSelectedClauseType(regulatorValue[0]);
                  clearErrors("clauseType_Id");
                }}
                onAddClick={() => {
                  setIsAddNewClauseType(true);
                }}
                addField={true}
                labelAddNew="Clause Type"
                disabled={view || !isActive}
                error={Boolean(errorsloginform.clauseType_Id)}
                helperText={errorsloginform.clauseType_Id && errorsloginform.clauseType_Id.message?.toString()}
              />
            )}
          />
        </Grid>
        {regulatorFileds ? (
          <React.Fragment>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              <Controller
                name="regulator_Id"
                control={control}
                defaultValue={0}
                rules={{
                  required: "Regulator is required",
                }}
                render={({ field }) => (
                  <CustomSelect
                    required
                    name={"regulator_Id"}
                    valueKey={"ThirdPartyId"}
                    optionLabelKey="ThirdPartyName"
                    options={regulatorData}
                    label={"Regulator"}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={view || !isActive}
                    error={Boolean(errorsloginform.regulator_Id)}
                    helperText={errorsloginform.regulator_Id && errorsloginform.regulator_Id.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={textFieldWidth.xs}>
              <Grid
                item
                xs={textFieldWidth.xs}
                // sx={{
                //   pr: 1.5,
                // }}
              >
                <Controller
                  name="regulatory_Reference"
                  control={control}
                  rules={{
                    required: "Regulatory Reference is required",
                    validate: {
                      noLeadingSpaces: (value) => !/^\s/.test(value ? value : "") || "Leading spaces are not allowed",
                      maxLength: (value) => (value && value.length <= 100) || "Maximum 100 characters allowed",
                      ValueLimitaion: (value) =>
                        /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value ? value : "") ||
                        "Name cannot contain special characters and space",
                    },
                  }}
                  render={({ field }) => (
                    <CustomMultilineTextField
                      label={"Regulatory Reference"}
                      value={field.value}
                      rows={5}
                      required={true}
                      readOnly={view || !isActive}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.regulatory_Reference)}
                      helperText={
                        errorsloginform.regulatory_Reference && errorsloginform.regulatory_Reference.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>{" "}
          </React.Fragment>
        ) : null}
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="clause_Header"
            control={control}
            defaultValue={""}
            rules={{  
              required: "Clause Header is required",
              validate: {
                noLeadingSpaces: (value) => !/^\s/.test(value ? value : "") || "Leading spaces are not allowed",
                maxLength: (value) => (value && value.length <= 100) || "Maximum 100 characters allowed",
                ValueLimitaion: (value) =>{
                  const regex = /^[^0-9].*$/;
                  return regex.test(value) || "Clause Header cannot start with numbers";
                },
              },
            }}
            render={({ field }) => (
              <CustomTextField
                name={"clause_Header"}
                required={true}
                readOnly={view || !isActive}
                label={"Clause Header"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsloginform.clause_Header)}
                helperText={errorsloginform.clause_Header && errorsloginform.clause_Header.message?.toString()}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="clause_Contract_Header"
            control={control}
            defaultValue={""}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <CustomTextField
                name={"clause_Contract_Header"}
                required={false}
                readOnly={view || !isActive}
                label={"Clause Header for Contract"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsloginform.clause_Contract_Header)}
                helperText={
                  errorsloginform.clause_Contract_Header && errorsloginform.clause_Contract_Header.message?.toString()
                }
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={textFieldWidth.sm}

          // sm={textFieldWidth.sm}
          // md={textFieldWidth.md}
          // lg={textFieldWidth.lg}
          // xl={textFieldWidth.xl}
        >
          <Grid
            item
            xs={textFieldWidth.xs}

            // sm={textFieldWidth.sm}
            // md={textFieldWidth.md}
            // lg={textFieldWidth.lg}
            // xl={textFieldWidth.xl}
          >
            <Controller
              name="clause"
              control={control}
              defaultValue={""}
              rules={{
                required: "Clause Details is required",
                validate: {
                  ValueLimitaion: (value) => value !== "<p></p>" || "Clause Details is required",
                },
              }}
              render={({ field }) => (
                <Editor
                  updateEditorContent={updateEditorContent}
                  editorContent={field.value}
                  placeHolder={"Clause"}
                  view={view || !isActive}
                  error={Boolean(errorsloginform.clause)}
                  helperText={
                    errorsloginform.clause && errorsloginform.clause.message?.toString()
                      ? errorsloginform.clause.message?.toString()
                      : ""
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "self-start",
              paddingTop: "10px",
            }}
          >
            <Controller
              name="isSMEReviewRequired"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="Is SME Review Required"
                  onChange={(e) => {
                    setValue("isSMEReviewRequired", e.target.checked);
                    if(e.target.checked === false){
                      setValue("department_Id", 0)
                    }
                    field.onChange(e.target.checked);
                  }}
                  value={field.value}
                  //withLabelStyle={true}
                  disabled={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="department_Id"
            control={control}
            defaultValue={0}
            rules={{
              required: watch("isSMEReviewRequired") === true ? "Department is required" : false,
            }}
            render={({ field }) => (
              <CustomSelect
                name={"department"}
                valueKey={"DepartmentId"}
                optionLabelKey={`DepartmentName`}
                options={depatmentDropdownData}
                label={"Select SME"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsloginform.department_Id)}
                helperText={errorsloginform.department_Id && errorsloginform.department_Id.message?.toString()}
                required={watch("isSMEReviewRequired") === true ? true : false}
                disabled={(watch("isSMEReviewRequired") === true ? false : true) || view || !isActive}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="templateMappings"
            control={control}
            defaultValue={[]}
            rules={{
              required: "Contract Template is required",
            }}
            render={({ field }) => (
              <CustomMultiSelect
                optionLabelKey={`contract_Template_Name`}
                optionIdKey={`contract_Template_Id`}
                options={contractTemplates}
                readOnly={view || !isActive}
                required={true}
                label={"Contract Template"}
                value={field.value}
                onChange={(field: any) => {
                  setValue("templateMappings", field.target.value);
                  if (field.target.value.length > 0) {
                    clearErrors("templateMappings");
                  }
                  // const dname = field.target.value.map(
                  //   (id: number) => dataTable.find((data: any) => data.DepartmentId === id).contract_Template_Name
                  // );
                  // setValue("templateMappings", dname.toString());
                }}
                error={Boolean(errorsloginform.templateMappings)}
                helperText={errorsloginform.templateMappings && errorsloginform.templateMappings?.message?.toString()}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
          sx={{ pt: "0" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Controller
              name="sla"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="Is a SLA Clause"
                  onChange={(e) => {
                    setValue("sla", e.target.checked);
                    field.onChange(e.target.checked);
                  }}
                  value={field.value}
                  //withLabelStyle={true}
                  disabled={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
          sx={{ pt: "0" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Controller
              name="pm"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="Is a PM Clause"
                  onChange={(e) => {
                    setValue("pm", e.target.checked);
                    field.onChange(e.target.checked);
                  }}
                  value={field.value}
                  //withLabelStyle={true}
                  disabled={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "self-start",
              paddingTop: "10px",
            }}
          >
            <Controller
              name="isNonNegotiableClause"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="Is it a Non Negotiable Clause"
                  onChange={(e) => {
                    setValue("isNonNegotiableClause", e.target.checked);
                    field.onChange(e.target.checked);
                  }}
                  value={field.value}
                  //withLabelStyle={true}
                  disabled={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "self-start",
              paddingTop: "10px",
            }}
          >
            <Controller
              name="isRiskApplicable"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="Is the Risk applicable"
                  onChange={(e) => {
                    setValue("isRiskApplicable", e.target.checked);
                    if (e.target.checked === false) {
                      setValue("riskMappings", []);
                      setValue("riskId", 0);
                    }
                    field.onChange(e.target.checked);
                  }}
                  value={field.value}
                  //withLabelStyle={true}
                  disabled={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>

        {watch("isRiskApplicable") === true ? (
          <React.Fragment>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
              sx={{ pt: "0" }}
            >
              <Controller
                name="riskMappings"
                control={control}
                defaultValue={0}
                rules={{
                  required: "Risk Applicable is required",
                }}
                render={({ field }) => (
                  <CustomMultiSelect
                    optionLabelKey={`Options`}
                    optionIdKey={`LovId`}
                    options={riskCategory}
                    readOnly={view || !isActive}
                    required={true}
                    label={"Risk Applicable"}
                    value={field.value}
                    onChange={(field: any) => {
                      setValue("riskMappings", field.target.value);
                      if (field.target.value.length > 0) {
                        clearErrors("riskMappings");
                      }
                      // const dname = field.target.value.map(
                      //   (id: number) => dataTable.find((data: any) => data.DepartmentId === id).contract_Template_Name
                      // );
                      // setValue("templateMappings", dname.toString());
                    }}
                    error={Boolean(errorsloginform.riskMappings)}
                    helperText={errorsloginform.riskMappings && errorsloginform.riskMappings?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
              sx={{ pt: "0" }}
            >
              <Controller
                name="riskId"
                control={control}
                defaultValue={0}
                rules={{
                  required: "Risk is required",
                }}
                render={({ field }) => (
                  <CustomSelect
                    name={"riskId"}
                    valueKey={"LovId"}
                    optionLabelKey={`Options`}
                    options={riskValues}
                    label={"Risk"}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsloginform.riskId)}
                    helperText={errorsloginform.riskId && errorsloginform.riskId.message?.toString()}
                    disabled={view || !isActive}
                    required={true}
                  />
                )}
              />
            </Grid>
          </React.Fragment>
        ) : null}
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "self-start",
              paddingTop: "10px",
            }}
          >
            <Controller
              name="hasSubClause"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="It has Sub Clause(s)"
                  onChange={(e) => {
                    setValue("hasSubClause", e.target.checked);
                    field.onChange(e.target.checked);
                  }}
                  value={field.value}
                  //withLabelStyle={true}
                  disabled={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>

        {watch("hasSubClause") === true ? (
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "self-start",
                //paddingTop: "8px",
              }}
            >
              <CustomButton
                variant="outlined"
                name="+ Add New Sub Clause"
                onClick={enableAddNewSubClause}
                size="small"
              />
            </Box>
          </Grid>
        ) : null}

        {watch("hasSubClause") === true && subClausesData.length > 0 ? (
          <Grid item xs={12} sx={{ py: 1 }}>
            {/* <Box className={styles.gridCss} sx={{ display: "flex", flexDirection: "column" }}>
              <Controller
                name="subClauses"
                control={control}
                rules={{ required: getValues("hasSubClause") ===true ? "Atleast one subclass needs to be added" : false}}
                render={({ field }) => (
                  <React.Fragment>
                  <MaterialDynamicGrid
                    data={columns}
                    rows={subClausesData}
                    handleAction={handleOpen}
                    handleAdd={() => enableAddNewSubClause()}
                    enableZoomIcon={false}
                    showhistory={false}
                    // isDraftView={false}
                    // draftCount={clauseDetailsDraftData.length}
                    // isEnableDraft={false}
                    // handleIsDraft={(val: any) => setisShowDraft(val)}
                    showAdd={true}
                    showDelete={true}
                    showView={true}
                    showEdit={true}
                    showExports={false}
                    userId={userLoginResponse?.UserId || 0}
                    pageName="Sub Clauses"
                    isActivekey="IsActive"
                  />
                  {errorsloginform.subClauses && errorsloginform.subClauses.message?.toString() ? <p className={styles.helperTextError} >{errorsloginform.subClauses && errorsloginform.subClauses.message?.toString()}</p>: null}
                  </React.Fragment>
                )}
              />
            </Box> */}
            <Box className={styles.gridCss} sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={subClausesData}
                handleAction={handleOpen}
                handleAdd={() => enableAddNewSubClause()}
                enableZoomIcon={false}
                showhistory={false}
                // isDraftView={false}
                // draftCount={clauseDetailsDraftData.length}
                // isEnableDraft={false}
                // handleIsDraft={(val: any) => setisShowDraft(val)}
                showAdd={!view}
                showDelete={false}
                showSubDelete={!view}
                showView={true}
                showEdit={!view}
                showExports={false}
                userId={userLoginResponse?.UserId || 0}
                pageName="Sub Clauses"
                isActivekey="IsActive"
              />
            </Box>
          </Grid>
        ) : null}
      </Grid>

      <CustomDialog
        show={isAddNewClauseType}
        onHide={() => setIsAddNewClauseType(false)}
        maxWidth="md"
        header="Add Clause Type Master"
        contentNode={<AddClauseTypeMaster onClose={() => setIsAddNewClauseType(false)} />}
      />

      <CustomDialog
        show={enableAddSubClause}
        onHide={() => hideAddNewSubClause()}
        maxWidth="md"
        header={Option === "edit" ? "Update Sub Clause" : Option === "view" ? "View Sub Clause" : "Add New Sub Clause"}
        contentNode={
          <SubClause
            isadd={Option === "add" ? true : false}
            isNonNegotiableClause={getValues("isNonNegotiableClause") === true ? true : false}
            sla={getValues("sla") === true ? true : false}
            pm={getValues("pm") === true ? true : false}
            isRiskApplicable={getValues("isRiskApplicable") === true ? true : false}
            isSMEReviewRequired={getValues("isSMEReviewRequired") === true ? true : false}
            department_Id={getValues("department_Id") !== undefined ? getValues("department_Id") : null}
            riskId={getValues("riskId") !== undefined ? getValues("riskId") : null}
            riskMappings={getValues("riskMappings") !== undefined ? getValues("riskMappings") : []}
            onClose={() => hideAddNewSubClause()}
            subClauses={getValues("subClauses") !== undefined ? getValues("subClauses") : []}
            view={Option == "view" ? true : false}
            edit={Option == "edit" ? true : false}
            isEditData={isSubEditData}
          />
        }
      />

      <ModalPopUp
        onDeleteMethod={() => active_Inactive_Clause()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to Delete the Sub Class`}
        ActionName={"Delete"}
      />
    </Box>
  );
};

export default AddClause;
