import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import PageMainHeading from "../../common/CommonHeadings/PageMainHeading";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { VendorConsentWithdrawalForm } from "./VendorConsentWithdrawalForm";
import { getWithdrawConsentList } from "../../store/slices/consentFormData";

const columns: any = {
    table_name: "Vendor Withdrawal",
    isAction:  true,
    columns: [
    
      {
        Field: "Group_Name",
        FieldType: "string",
        DisplayName: "Vendor Group Name",
      },
      {
        Field: "Legal_Name",
        FieldType: "string",
        DisplayName: "Vendor Name",
      },
      {
        Field: "Contact_Number",
        FieldType: "string",
        DisplayName: "Registered Contact No",
      },
      {
        Field: "EmailId",
        FieldType: "string",
        DisplayName: "Registered Email Id",
      },
      {
        Field: "isWorkflowInitiated",
        FieldType: "string",
        DisplayName: "Is Work flow Initiated",
        
      },
      {
        Field: "PAN",
        FieldType: "string",
        DisplayName: "PAN",
      },
      {
        Field: "TAN",
        FieldType: "string",
        DisplayName: "TAN",
      },
      {
        Field: "CIN",
        FieldType: "string",
        DisplayName: "CIN",
      },
      {
        Field: "Jurisdiction_Value",
        FieldType: "string",
        DisplayName: "Applicable Jurisdiction",
      },
      {
        Field: "Constitution_Value",
        FieldType: "string",
        DisplayName: "Constitution",
      },
   
     
    ],
  };


export const VendorWithdrawal : React.FC<any> = ({ }) => {
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
      (state: RootState) => state.loggerData
    );
    const {vendorConsentWithdrawList  } = useSelector(
      (state: RootState) => state.vendorConsentData
    );
    const dispatch = useAppDispatch();
    const [permssionPage ,setPermissionForpage  ] = useState<any>()
    const [ withdrawList ,setisWithdrawList  ] = useState<any>([])
    const [ withdrawData ,setisWithdrawData  ] = useState<any>("")
    const [ isWithdraw ,setIswithdraw  ] = useState<boolean>(false)

    useEffect(() => {

      onpageloadingApi()
    },[])

    const onpageloadingApi = () =>{
      dispatch(getWithdrawConsentList());
    }
    useEffect(() => {
      setisWithdrawList(vendorConsentWithdrawList)
    },[vendorConsentWithdrawList])
    useEffect(() => {
        if (currentPagePermissionData !== null) {
          setPermissionForpage(currentPagePermissionData);
        }
      }, [currentPagePermissionData]);



      const handleOpen = (data: any, option: any) => {
        if (option === "run") {
          setIswithdraw(true);
          //console.log(data , 'data list');
          
          setisWithdrawData(data);
        } 
      }
    const CloseForm = () => {
      setIswithdraw(false)
      onpageloadingApi()
    }
    return (
        <>

<Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <PageMainHeading title={permssionPage?.MenuName} />
            </Box>
          
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={withdrawList}
                handleAction={handleOpen}
                enableZoomIcon={true}
                disableselect={false}
                showAdd={false}
                showDelete={false}
                showEdit={false}
                showView={false}
                showhistory={false}
                showRun={false}
                showWithdraw={true}
                showExports={permssionPage?.IsExport === 1}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                pageName="Vendor Withdrawal"
                // isRunDisableByValue={[{ key: "Status", value: "Not Started" }]}
                IswithdrawalActivecontract={[{key:"IsAnyActiveContract", value:true}]}
              />
            </Box>
          </Stack>     
          </Box>     

          <CustomDialog
        show={isWithdraw}
        onHide={() => CloseForm()}
        maxWidth={"md"}
        header={`Withdraw Consent`}
        contentNode={
          <>
            {" "}
            <VendorConsentWithdrawalForm
              view={ false}
              edit={true}
              onClose={() => CloseForm()}
              isEditData={withdrawData}
              ModuleId={permssionPage?.MenuId}
            />{" "}
          </>
        }
      />
        </>
    )
}