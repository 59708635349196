import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormHelperText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BasicTableStyle from "../../../common/dynamicDataGrid/basicTable.module.scss";
import PreviewStyle from "./vendorpreview.module.scss";
import { AQMMappingDatadublicate } from "../../../mockData/mockData";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import {
  ApplicationParameterName,
  AQMResponder,
  Default_threshold,
  LovConfig,
  vendorAssetStatus,
  vendorassetview,
  WorkflowCondition,
} from "../../../config/config";
import CustomSvg from "../../../common/CustomSvg";
import { useTheme } from "@mui/material/styles";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import { AssessmentDetailContext } from ".";
import { AssessmentVendorDetailContext } from "../../../vendorPortal/aqmResponse/AqmResponse";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import CustomFileUploader from "../../../common/dynamicFileUploader/CustomFileUploader";
import { fileHandlerUpdate, UploadAFile } from "../../../store/slices/fileHandler";
import { updateLoader } from "../../../store/slices/loader";
import FilteViewer from "../../../common/fileViewer/FilteViewer";
import useValidation, { checkstepAvailable } from "./AssessmentValidation";
import validateField from "./AssessmentValidation";
import fileHandlerService from "../../../store/services/fileHandlerService";
import * as mime from "react-native-mime-types";
import { getLovList } from "../../../store/slices/lovMasterData";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { getRiskRateList } from "../../../store/slices/riskRateMasterData";
import { updatethresholdforVendorAssessment } from "../../../store/slices/vendorAssessment";
type UserType = "vendor" | "SME" | "SO" | "DO" | "SMEE";
export interface VOBPreviewProps {
  IsUser: UserType;
  currentProcessData?: any;
  isPreviewMode?: any;
  processStepsList?: any;
  isEditData: any
}

export const VOBPreview: React.FC<VOBPreviewProps> = ({
  IsUser,
  currentProcessData,
  isPreviewMode,
  processStepsList,
  isEditData,
}) => {
  const dispatch = useAppDispatch();
  const context = useContext(AssessmentDetailContext);
  const Vendorcontext = useContext(AssessmentVendorDetailContext);
  const theme = useTheme();
  const { themeMode } = useSelector((state: RootState) => state.menu);
    const { RiskRateDataActive } = useSelector((state: RootState) => state.RiskRateData);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { isFileUpload, uploadData, isFileUploadLoading } = useSelector((state: RootState) => state.fileHandler);
  const { VendorAssessmentdata , thresholdforVendorAssessment , isVendorAssessmentthreshhold} = useSelector((state: RootState) => state.vendorAssessmentData);
  const [viewpermissionPage, setviewpermissionPage] = useState<number>(0);
  const [view, setview] = useState<boolean>(false);
  const [DOcheck, setDOcheck] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
  });
  const [allExpanded, setAllExpanded] = useState(false);
  const [ShowanswerSection, setShowanswerSection] = useState(false);
  const [ShowriskanswerSection, setShowriskanswerSection] = useState(false);
  const [applicableRiskData, setApplicableRiskData] = useState<any[]>([]);
  const [ApproveList, setApproveList] = useState<any[]>([]);
  const [ResponderLV, setResponderLV] = useState<any[]>([]);
  const [isfileUpload, setIsfileUpload] = useState<boolean>(false);
  const [isfileUploadData, setIsfileUploadData] = useState<any>("");
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [isfilePreview, setIsfilePreview] = useState<boolean>(false);
  const [isfilePreviewurl, setIsfilePreviewurl] = useState<any>("");
  const [attachmentFile, setAttachmentFile] = useState<any>("");
  const [ThresholdData, setThresholdData] = useState<any>("");

  useEffect(() => {
    if(!isPreviewMode && applicableRiskData && applicableRiskData.length > 0 && ThresholdData){
      console.log(ThresholdData , 'ThresholdData data');
      
       syncCalculation();
    }
  
  }, [applicableRiskData , ThresholdData , isPreviewMode ]);



  const findthreshold = () => {
    const data = (isEditData && isEditData?.ThresholdValue) ? isEditData?.ThresholdValue : applicationParameterData ? applicationParameterData
      .filter((param: any) => param?.Parameter_Name === ApplicationParameterName.Threshold && param?.IsActive === "Active")
      .map((param) => param.Parameter_Value) : Default_threshold ;
    return  Number(data) 
  };

  useEffect(() => {
      const getData = findthreshold();
      dispatch(updatethresholdforVendorAssessment({ value : getData }));

  }, [applicationParameterData , isEditData]);


 useEffect(() => {
   
  if(thresholdforVendorAssessment){
    setThresholdData(thresholdforVendorAssessment);
  }
},[thresholdforVendorAssessment])

  useEffect(() => {
    let sec = vendorassetview[IsUser];
    setviewpermissionPage(sec);
    if (sec === 1 || sec === 5) {
      setShowanswerSection(true);
      setShowriskanswerSection(false);
    }
    if (sec === 2 || sec === 5) {
      setDOcheck(true);
    }
    if (sec === 2 || sec === 4) {
      setview(true);
    }
    if(sec === 3 || sec === 4 || sec === 6){
      setShowanswerSection(true);
      setShowriskanswerSection(true);
    }
  }, [IsUser]);

  useEffect(() => {
    if (RiskRateDataActive) {
      setApplicableRiskData(RiskRateDataActive || []);
    }
  }, [RiskRateDataActive]);

  const onchangeAnserSectionShow = () => {
    setShowanswerSection((pre) => !pre);
    setShowriskanswerSection((pre) => !pre);
  };

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(
      getRiskRateList({
        FormID: 2,
      })
    );
  }, []);
  useEffect(() => {
    toggleAllExpandFirst();
  }, []);

  useEffect(() => {
    setApproveList(lovTable.filter((item: any) => item.Name === LovConfig?.Pre_Approval) || []);
    setResponderLV(lovTable.filter((item: any) => item.Name === LovConfig?.AQ_Responder) || []);
  }, [lovTable]);

  useEffect(() => {}, [isFileUploadLoading]);
  useEffect(() => {
    dispatch(updateLoader(isFileUploadLoading));
  }, [isFileUploadLoading]);

  useEffect(() => {
    if (isFileUpload && isfileUploadData && isfileUploadData?.cat_index >= 0) {
      handleCommonValueChange(isfileUploadData?.cat_index, isfileUploadData.quest_index, uploadData, "Attachment");
      setIsfileUploadData(null);
      setIsfileUpload(false);
      dispatch(fileHandlerUpdate(false));
    }
  }, [isFileUpload]);

  if (
    (!context && (IsUser === "SME" || IsUser === "SO" || IsUser === "DO")) ||
    (!Vendorcontext && IsUser === "vendor")
  ) {
    return null;
  }

  const findResponder = (Res_id: any) => {
    return ResponderLV.find((item) => item.LovId == Res_id)?.Options || "";
  };

  const onButtonClick = async (bloburl: any) => {
    if (bloburl) {
      const fileName = decodeURIComponent(bloburl.substring(bloburl.indexOf("_") + 1));
      const response: any = await fileHandlerService.DownLoadAFile({ fileUrl: bloburl });
      const { data } = response;
      const mimeType = mime.lookup(fileName.substring(fileName.lastIndexOf(".") + 1));
      setFileName(fileName as string);
      setIsfilePreviewurl(`data:${mimeType};base64,${data}`);
      setIsfilePreview(true);
    }
  };

  const { selectedCatagoryPreview, validationErrors, setselectedCatagoryPreview, setvalidationErrors } =
    IsUser === "vendor" ? Vendorcontext! : context!;

  // if(selectedCatagoryPreview){
  //   useEffect(() => {
  //     if (selectedCatagoryPreview) {
  //       const initialExpandedState = selectedCatagoryPreview.reduce((acc, item, index) => {
  //         acc[index] = true;  // Set all items to expanded (true) initially
  //         return acc;
  //       }, {});
  //       setExpanded(initialExpandedState);
  //     }

  //   }, [selectedCatagoryPreview]);
  // }

  const handleAccordionChange = (index: number) => {
    setExpanded((prevExpanded) => ({ ...prevExpanded, [index]: !prevExpanded[index] }));
  };

  const statusCheck = (id: any) => {
    return ApproveList.find((item) => item.LovId == id)?.Options || "";
  };

  const toggleAllExpandFirst = () => {
    if (selectedCatagoryPreview && selectedCatagoryPreview.length > 0) {
      const newExpandedState: { [key: number]: boolean } = {};
      selectedCatagoryPreview.forEach((_: any, index: any) => {
        newExpandedState[index] = true;
      });
      setExpanded(newExpandedState);
    }
  };

  const toggleAll = () => {
    const newState = !allExpanded;
    setAllExpanded(newState);
    const newExpandedState: { [key: number]: boolean } = {};
    selectedCatagoryPreview.forEach((_: any, index: any) => {
      newExpandedState[index] = newState;
    });
    setExpanded(newExpandedState);
  };

  const validatecatagory = (item: any) => {
    return null;
  };

  const catagoryScoreChangeOverall = (item: any, questionIndex: any, catagoryIndex: any, field: any) => {
    setselectedCatagoryPreview((prevData: any) => {
      let newData = [...prevData];
      let category = { ...newData[catagoryIndex] };
      let question = { ...category.Questions[questionIndex] };
      question.ChoicesScore = item;
      question.ApplicableRisksScore = question.ApplicableRisksScore.map((risk: any) => {
        return { ...risk, Score: item };
      });
      category.Questions[questionIndex] = question;
      newData[catagoryIndex] = category;
      return newData;
    });
    setvalidationErrors(validateField(catagoryIndex, questionIndex, field, item, validationErrors));
    syncCalculation();
  };

  const catagoryScoreChange = (item: any, questionIndex: any, riskId: any, catagoryIndex: any) => {
    setselectedCatagoryPreview((prevData: any) => {
      let newData = [...prevData];
      let category = newData[catagoryIndex];
      let question = category.Questions[questionIndex];
      let riskScore = question.ApplicableRisksScore.find((risk: any) => risk.RiskId === riskId);
      if (riskScore) {
        riskScore.Score = item;
      } else {
        console.error(`Risk ID ${riskId} not found in ApplicableRisksScore`);
      }

      return newData;
    });
    syncCalculation();
  };

  const handleCommonValueChange = (categoryIndex: any, questionIndex: any, newValue: any, field: string) => {
    setselectedCatagoryPreview((prevData: any) => {
      const newData = [...prevData];
      newData[categoryIndex].Questions[questionIndex][field] = newValue;
      return newData;
    });

    setvalidationErrors(validateField(categoryIndex, questionIndex, field, newValue, validationErrors));
  };

  const giveCalculationValue = (id: any) => {
    try {
      let findRiskWeightage = applicableRiskData.find((item: any) => item?.RiskCategory_Id == id)?.Weightage || "";

      if (findRiskWeightage && findRiskWeightage != undefined && findRiskWeightage != null) {
        return findRiskWeightage || 0;
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const syncCalculation = () => {
    let updatedValue = selectedCatagoryPreview.map((item: any) => {
      if (item.Questions) {
        item.Questions = item.Questions.map((q_item: any, index: any) => {
          if (q_item.Answer_Type === true) {
            let lowScoreValue = 0;
            let actualScoreValue = 0;

            q_item.ApplicableRisks.forEach((risk_item: any) => {
              let RiskDefaultValue = giveCalculationValue(risk_item);

              let lowScore =
                q_item?.ApplicableRisk_LowScore.find((selChoice: any) => selChoice.RiskId == risk_item)?.Score || 0;
              let actualScore =
                q_item?.ApplicableRisksScore.find((selChoice: any) => selChoice.RiskId == risk_item)?.Score || 0;

              lowScoreValue = lowScoreValue + Number(lowScore) * Number(RiskDefaultValue);

              actualScoreValue = actualScoreValue + Number(actualScore) * Number(RiskDefaultValue);
            });

            // lowscore mean best score

            let lowScoreValuewithwtg = (lowScoreValue * Number(q_item.Ques_Wtg)) / 100;
            let actualScoreValuewithwtg = (actualScoreValue * Number(q_item.Ques_Wtg)) / 100;
            //  console.log( 'best : '  , lowScoreValuewithwtg , 'actual :' , actualScoreValuewithwtg ,  'finalValue 1' );
            // round of the value
            // lowScoreValuewithwtg  =    Number(lowScoreValuewithwtg.toFixed(2));
            lowScoreValuewithwtg = Number(Math.floor(lowScoreValuewithwtg * 100) / 100);
            // actualScoreValuewithwtg =  Number(actualScoreValuewithwtg.toFixed(2)) ;
            actualScoreValuewithwtg = Number(Math.floor(actualScoreValuewithwtg * 100) / 100);

            console.log(
              "best : ",
              lowScoreValuewithwtg,
              "actual :",
              actualScoreValuewithwtg,
              "finalValue 1 after round off"
            );
            let finalValue = actualScoreValuewithwtg - lowScoreValuewithwtg;
            // console.log( finalValue , 'finalValue 2' );
            let percentage: any = 0;
            if (lowScoreValuewithwtg === 0) {
              percentage = 0;
            } else {
              percentage = (finalValue / lowScoreValuewithwtg) * 100;
            }
            // let percentage : any = (finalValue / lowScoreValuewithwtg) * 100;
            // console.log( percentage , 'finalValue 3');
            let final_To_DP = q_item.IsAddInDo;

            if (percentage >= ThresholdData) {
              final_To_DP = true;
            }else{
              final_To_DP = false;
            }

            // console.log("best Score : " + lowScoreValue + "; best Value : " + lowScoreValuewithwtg);
            // console.log("Actual Score : " + actualScoreValue + "; Actual Value : " + actualScoreValuewithwtg);
            // console.log("finalValue: " + finalValue);
            // console.log("percentage: " + percentage);

            return {
              ...q_item,
              Best_Score: lowScoreValuewithwtg,
              Actual_Score: actualScoreValuewithwtg,
              Deviation: percentage.toFixed(2),
              IsAddInDo: final_To_DP,
            };
          }

          return q_item;
        });
      }

      return item;
    });

    setselectedCatagoryPreview(updatedValue);
  };

  const fileUploadClickOpen = (cat_index: any, quest_index: any) => {
    setIsfileUpload(true);
    setIsfileUploadData({ cat_index: cat_index, quest_index: quest_index });
    setAttachmentFile("");
  };
  const fileUploadClickClose = () => {
    setIsfileUpload(false);
    setIsfileUploadData(null);
    setAttachmentFile("");
  };

  const findResponseQuestionAll = (Response: any) => {
    let val = false;

    selectedCatagoryPreview.map((item: any, index: number) => {
      if ((item.Catagory_Id === Response && item?.Questions) || (item.IsAddInDo && viewpermissionPage === 4)) {
        item.Questions.map((q_item: any, index: number) => {
          let getValue = findResponseQuestion(q_item?.Respondor);

          if (getValue) {
            val = getValue;
          }
        });
      }
    });

    return val;
  };
  const findResponseQuestion = (item: any) => {
    let Response = item;
    // let IsAddInDo = item.IsAddInDo

    if (Response === AQMResponder?.Vendor && viewpermissionPage === 1) {
      return true;
    }
    if (Response === AQMResponder?.SME && (viewpermissionPage === 2 || viewpermissionPage === 5)) {
      return true;
    }
    if (
      (Response === AQMResponder?.Vendor || Response === AQMResponder?.SME || Response === AQMResponder?.SO) &&
      viewpermissionPage === 3
    ) {
      return true;
    }
    //  || (IsAddInDo && viewpermissionPage === 4)
    if (
      (Response === AQMResponder?.Vendor || AQMResponder?.SME || Response === AQMResponder?.SO) &&
      viewpermissionPage === 4
    ) {
      return true;
    }
    return true;
    //  return false
  };

  const SelectedFileUpload = (data: any) => {
    if (data) {
      setAttachmentFile(data);
    }
  };

  const uploadFile = () => {
    if (attachmentFile) {
      const blob = attachmentFile.pop();
      let formData = new FormData();
      formData.append("fileUploadRequest", blob);
      dispatch(UploadAFile(formData));
    }
  };

  const expandedKeys = Object.keys(expanded).filter((key) => expanded[Number(key)]);

  return (
    <>
      {viewpermissionPage !== 0 && selectedCatagoryPreview && selectedCatagoryPreview?.length > 0 ? (
        <>
          {/* -------------------- tab1 ---------------- */}
          <Box className={BasicTableStyle.tab_outer} sx={{ width: "100%", overflowX: "auto" }}>
            <Table sx={{ width: "100%", overflowX: "scroll" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    // sx={{ minWidth: "300px" }}
                    sx={{
                      minWidth: "300px",
                      position: "sticky",
                      left: 0,
                      backgroundColor: theme.palette.background.paper, // Ensure the background color matches the rest of the header
                      zIndex: "4 !important", // Ensure it stays on top of other columns
                    }}
                    className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                  >
                    {" "}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Questions{" "}
                      <IconButton sx={{padding:'0px'}} onClick={() => toggleAll()}>
                        {expandedKeys?.length === selectedCatagoryPreview?.length ? (
                          <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"chevron-down"} />
                        ) : (
                          <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"chevron-up"} />
                        )}{" "}
                      </IconButton>
                    </Box>
                  </TableCell>
                  {viewpermissionPage !== 1 && viewpermissionPage !== 5 && (
                    <TableCell
                      sx={{ minWidth: "200px " }}
                      className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                    >
                      {" "}
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        Best Score for all Risk{" "}
                        {viewpermissionPage !== 1 && viewpermissionPage !== 5 && (
                          <IconButton sx={{padding:'0px'}} onClick={() => onchangeAnserSectionShow()}>
                            <CustomSvg
                              color={`${theme.palette.text.secondary}`}
                              size={"20px"}
                              name={"chevrons-right"}
                            />{" "}
                          </IconButton>
                        )}{" "}
                      </Box>
                    </TableCell>
                  )}

                  {/* -----------Answer Section ------------- */}
                  {ShowanswerSection ? (
                    <TableCell
                      sx={{ minWidth: "300px " }}
                      className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                    >
                      {" "}
                      Response{" "}
                    </TableCell>
                  ) : null}
                  {ShowriskanswerSection &&
                    applicableRiskData.map((risk_item) => (
                      <TableCell
                        sx={{ minWidth: "150px " }}
                        className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                      >
                        {" "}
                        {risk_item?.RiskCategory}{" "}
                      </TableCell>
                    ))}
                  {/* -----------Answer Section end ------------- */}

                  {viewpermissionPage !== 1 && viewpermissionPage !== 5 && (
                    <TableCell
                      sx={{ minWidth: "100px " }}
                      className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                    >
                      Actual Score{" "}
                    </TableCell>
                  )}
                  {viewpermissionPage !== 1 && viewpermissionPage !== 5 && (
                    <TableCell
                      sx={{ minWidth: "100px " }}
                      className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                    >
                      {" "}
                      Threshold{" "}
                    </TableCell>
                  )}
                  <TableCell
                    sx={{ width: "150px !important" }}
                    className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                  >
                    {" "}
                    Attachment{" "}
                  </TableCell>
                  {viewpermissionPage !== 1 && viewpermissionPage !== 5 && (
                    <TableCell
                      sx={{ minWidth: "200px " }}
                      className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                    >
                      {" "}
                      Responder{" "}
                    </TableCell>
                  )}
                  {viewpermissionPage !== 1 &&
                    viewpermissionPage !== 5 &&
                    checkstepAvailable("SME", processStepsList) && (
                      <TableCell
                        sx={{ minWidth: "150px " }}
                        className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                      >
                        {" "}
                        SME Review status{" "}
                      </TableCell>
                    )}
                  {viewpermissionPage !== 1 &&
                    viewpermissionPage !== 5 &&
                    checkstepAvailable("SME", processStepsList) && (
                      <TableCell
                        sx={{ minWidth: "300px " }}
                        className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                      >
                        {" "}
                        SME Remarks{" "}
                      </TableCell>
                    )}
                  {viewpermissionPage !== 1 && viewpermissionPage !== 5 && viewpermissionPage !== 2 && (
                    <TableCell
                      sx={{ minWidth: "300px " }}
                      className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                    >
                      {" "}
                      SO Remarks{" "}
                    </TableCell>
                  )}
                  {viewpermissionPage !== 1 &&
                    viewpermissionPage !== 5 &&
                    viewpermissionPage !== 2 &&
                    currentProcessData?.ConditionName != WorkflowCondition?.doApproval &&
                    checkstepAvailable("Do", processStepsList) && (
                      <TableCell
                        sx={{ minWidth: "150px " }}
                        className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                      >
                        {" "}
                        DO Review status{" "}
                      </TableCell>
                    )}
                  {viewpermissionPage !== 1 &&
                    viewpermissionPage !== 5 &&
                    viewpermissionPage !== 2 &&
                    currentProcessData?.ConditionName != WorkflowCondition?.doApproval &&
                    checkstepAvailable("Do", processStepsList) && (
                      <TableCell
                        sx={{ minWidth: "300px " }}
                        className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                      >
                        {" "}
                        Action{" "}
                      </TableCell>
                    )}
                  {(viewpermissionPage === 2 || viewpermissionPage === 3 || viewpermissionPage === 6) &&
                    (currentProcessData?.ConditionName == WorkflowCondition?.doApproval || viewpermissionPage === 6) &&
                    checkstepAvailable("Do", processStepsList) && (
                      <TableCell
                        sx={{ minWidth: "100px " }}
                        className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                      >
                        {" "}
                        Send To DO{" "}
                      </TableCell>
                    )}
                  {((viewpermissionPage === 3 && currentProcessData?.ConditionName != WorkflowCondition?.doApproval) ||
                    viewpermissionPage === 4 ||
                    viewpermissionPage === 6) && (
                    <TableCell
                      sx={{ minWidth: "100px " }}
                      className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                    >
                      {" "}
                      Send it for PM?{" "}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedCatagoryPreview.map((item: any, index: number) => {
                  const hasError = validatecatagory(item) !== null;
                  const isItemChanged = item?.onChange && item?.onChange === true;
                  const showError = (hasError && isItemChanged) || hasError;

                  return findResponseQuestionAll(item.Catagory_Id) ? (
                    <>
                      <TableRow key={`${index + 1} - Parameters`}>
                        <TableCell
                        
                          className={BasicTableStyle.tab_data}
                          // sx={{ borderRight: "none" }}
                          sx={{
                            minWidth: "300px",
                            position: "sticky",
                            left: 0,
                            backgroundColor: theme.palette.background.paper, // Ensure the background color matches the rest of the header
                            zIndex: 1, // Ensure it stays on top of other columns
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {item.Options}{" "}
                            <IconButton sx={{padding:'0px'}} onClick={() => handleAccordionChange(index)}>
                              {!!expanded[index] ? (
                                <CustomSvg
                                  color={`${theme.palette.text.secondary}`}
                                  size={"20px"}
                                  name={"chevron-down"}
                                />
                              ) : (
                                <CustomSvg
                                  color={`${theme.palette.text.secondary}`}
                                  size={"20px"}
                                  name={"chevron-up"}
                                />
                              )}{" "}
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      {!!expanded[index] &&
                        item.Questions.map((q_item: any, q_index: number) =>
                          findResponseQuestion(q_item?.Respondor) ? (
                            <TableRow
                              key={`${index + 1 + q_index} - Questions`}
                              sx={{
                                backgroundColor:
                                  themeMode === "dark" ? `${theme.palette.background.default}` : "#F7F7F7",
                              }}
                            >
                              <TableCell
                                sx={{
                                  paddingLeft: "20px !important",
                                  minWidth: "300px",
                                  position: "sticky",
                                  left: 0,
                                  backgroundColor:
                                    themeMode === "dark" ? `${theme.palette.background.default}` : "#F7F7F7 !important",
                                  zIndex: 3, 
                                }}
                                className={BasicTableStyle.tab_data}
                              >
                                {q_item.Question}{" "}
                              </TableCell>
                              {viewpermissionPage !== 1 && viewpermissionPage !== 5 && (
                                <TableCell className={BasicTableStyle.tab_data} align="center">
                                  {q_item?.Best_Score}
                                </TableCell>
                              )}
                           
                              {/* -----------Answer Section ------------- */}
                              {ShowanswerSection ? (
                                <TableCell
                                  key={`${index + 1 + q_index} - Questions ChoicesScore`}
                                  className={BasicTableStyle.tab_data}
                                  align="center"
                                >
                                  {q_item?.Answer_Type == false ? (
                                    <CustomMultilineTextField
                                      key={`${index + 1 + q_index} - Questions ChoicesScore freetext`}
                                      label={""}
                                      readOnly={view || isPreviewMode}
                                      rows={0}
                                      maxRows={4}
                                      value={q_item?.Response_Received}
                                      onChange={(value) =>
                                        handleCommonValueChange(index, q_index, value, "Response_Received")
                                      }
                                      error={Boolean(validationErrors[`${index}-${q_index}-Response_Received`])}
                                      helperText={validationErrors[`${index}-${q_index}-Response_Received`]}
                                    />
                                  ) : (
                                    <CustomSelect
                                      key={`${index + 1 + q_index} - Questions ChoicesScore multichoice`}
                                      name={""}
                                      valueKey={"Score"}
                                      optionLabelKey="Attribute_Response"
                                      options={q_item?.choices}
                                      required={true}
                                      label={""}
                                      readOnly={view || isPreviewMode}
                                      value={q_item?.ChoicesScore}
                                      onChange={(value) =>
                                        catagoryScoreChangeOverall(value, q_index, index, "ChoicesScore")
                                      }
                                      error={Boolean(validationErrors[`${index}-${q_index}-ChoicesScore`])}
                                      helperText={validationErrors[`${index}-${q_index}-ChoicesScore`]}
                                    />
                                  )}
                                </TableCell>
                              ) : null}

                              {ShowriskanswerSection &&
                                applicableRiskData.map((risk_item) => (
                                  <>
                                    {q_item?.Answer_Type == true &&
                                    q_item?.ApplicableRisks.includes(risk_item?.RiskCategory_Id) ? (
                                      <TableCell
                                        key={`${index + 1 + q_index} - Questions ChoicesScore each risk`}
                                        className={` ${PreviewStyle.headerWidth} ${BasicTableStyle.tab_data}`}
                                      >
                                        <CustomSelect
                                          name={""}
                                          valueKey={"Score"}
                                          optionLabelKey="Attribute_Response"
                                          options={q_item?.choices}
                                          required={true}
                                          label={""}
                                          readOnly={true}
                                          value={
                                            q_item?.ApplicableRisksScore.find(
                                              (score_item: any) => score_item.RiskId == risk_item.RiskCategory_Id
                                            )?.Score
                                          }
                                          onChange={(value) =>
                                            catagoryScoreChange(value, q_index, risk_item.RiskCategory_Id, index)
                                          }
                                        />
                                      </TableCell>
                                    ) : (
                                      <TableCell className={` ${PreviewStyle.headerWidth} ${BasicTableStyle.tab_data}`}>
                                        {" "}
                                        <CustomSelect
                                          name={""}
                                          valueKey={"Score"}
                                          optionLabelKey="Attribute_Response"
                                          options={[]}
                                          required={true}
                                          label={""}
                                          disabled={true}
                                          value={""}
                                        />
                                      </TableCell>
                                    )}
                                  </>
                                ))}

                              {/* -----------Answer Section end------------- */}
                              {viewpermissionPage !== 1 && viewpermissionPage !== 5 && (
                                <TableCell className={BasicTableStyle.tab_data} align="center">
                                  {" "}
                                  {q_item?.Actual_Score}{" "}
                                </TableCell>
                              )}

                              {viewpermissionPage !== 1 && viewpermissionPage !== 5 && (
                                <TableCell className={BasicTableStyle.tab_data} align="center">
                                  {" "}
                                  {q_item?.Deviation}
                                  {" %"}
                                </TableCell>
                              )}
                              <TableCell
                                key={`${index + 1 + q_index} - Questions Document section`}
                                className={BasicTableStyle.tab_data}
                              >
                                <Box>
                                  <Box sx={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
                                    <Tooltip title="Upload">
                                      <IconButton
                                        disabled={isPreviewMode}
                                        onClick={() => fileUploadClickOpen(index, q_index)}
                                      >
                                        <CustomSvg
                                          color={`${theme.palette.text.secondary}`}
                                          size={"20px"}
                                          name={"upload"}
                                        />{" "}
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View">
                                      <IconButton
                                        onClick={() => {
                                          onButtonClick(q_item?.Attachment);
                                          // setIsfilePreviewurl(q_item?.Attachment);
                                          // setIsfilePreview(true);
                                        }}
                                        disabled={!q_item?.Attachment}
                                      >
                                        <CustomSvg
                                          color={
                                            !q_item?.Attachment
                                              ? `${theme.palette.text.secondary}`
                                              : `${theme.palette.info.main}`
                                          }
                                          size={"20px"}
                                          name={"view"}
                                        />{" "}
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Delete">
                                      <IconButton
                                        disabled={isPreviewMode}
                                        onClick={() => handleCommonValueChange(index, q_index, "", "Attachment")}
                                      >
                                        <CustomSvg
                                          color={`${theme.palette.text.secondary}`}
                                          size={"20px"}
                                          name={"delete"}
                                        />{" "}
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                  <FormHelperText
                                    error={Boolean(validationErrors[`${index}-${q_index}-Attachment`])}
                                    id="my-helper-text"
                                  >
                                    {validationErrors[`${index}-${q_index}-Attachment`]}
                                  </FormHelperText>
                                </Box>
                              </TableCell>
                              {viewpermissionPage !== 1 && viewpermissionPage !== 5 && (
                                <TableCell className={BasicTableStyle.tab_data} align="center">
                                  {" "}
                                  {findResponder(q_item?.Responder)}
                                  {findResponder(q_item?.Responder) === AQMResponder?.SME
                                    ? ` [${q_item?.SME_Name}]`
                                    : ""}
                                </TableCell>
                              )}
                              {viewpermissionPage !== 1 &&
                                viewpermissionPage !== 5 &&
                                checkstepAvailable("SME", processStepsList) && (
                                  <TableCell
                                    key={`${index + 1 + q_index} - Questions SME Review section`}
                                    className={`${BasicTableStyle.tab_data} ${
                                      statusCheck(q_item?.SME_ReviewStatus) == vendorAssetStatus.approve
                                        ? PreviewStyle.approveSec
                                        : statusCheck(q_item?.SME_ReviewStatus) == vendorAssetStatus.reject
                                        ? PreviewStyle.rejectedSec
                                        : ""
                                    }`}
                                    align="center"
                                  >
                                    {viewpermissionPage === 2 || viewpermissionPage === 3 ? (
                                      <CustomSelect
                                        name={""}
                                        key={`${index + 1 + q_index} - Questions SME Review`}
                                        valueKey={"LovId"}
                                        optionLabelKey="Options"
                                        options={ApproveList}
                                        required={true}
                                        label={""}
                                        readOnly={isPreviewMode}
                                        value={q_item?.SME_ReviewStatus}
                                        onChange={(value) =>
                                          handleCommonValueChange(index, q_index, value, "SME_ReviewStatus")
                                        }
                                        error={Boolean(validationErrors[`${index}-${q_index}-SME_ReviewStatus`])}
                                        helperText={validationErrors[`${index}-${q_index}-SME_ReviewStatus`]}
                                      />
                                    ) : (
                                      <Box>{statusCheck(q_item?.SME_ReviewStatus)}</Box>
                                    )}
                                  </TableCell>
                                )}
                              {viewpermissionPage !== 1 &&
                                viewpermissionPage !== 5 &&
                                checkstepAvailable("SME", processStepsList) && (
                                  <TableCell
                                    key={`${index + 1 + q_index} - Questions SME Remark section`}
                                    className={BasicTableStyle.tab_data}
                                    align="center"
                                  >
                                    {viewpermissionPage === 2 ? (
                                      <CustomMultilineTextField
                                        // name={"Answer"}
                                        key={`${index + 1 + q_index} - Questions SME Remark`}
                                        rows={0}
                                        maxRows={4}
                                        required={true}
                                        label={""}
                                        readOnly={isPreviewMode}
                                        value={q_item?.SME_Remarks}
                                        onChange={(value) =>
                                          handleCommonValueChange(index, q_index, value, "SME_Remarks")
                                        }
                                        error={Boolean(validationErrors[`${index}-${q_index}-SME_Remarks`])}
                                        helperText={validationErrors[`${index}-${q_index}-SME_Remarks`]}
                                      />
                                    ) : (
                                      q_item?.SME_Remarks
                                    )}
                                  </TableCell>
                                )}
                              {viewpermissionPage !== 1 && viewpermissionPage !== 5 && viewpermissionPage !== 2 && (
                                <TableCell
                                  key={`${index + 1 + q_index} - Questions SO Remark section`}
                                  className={BasicTableStyle.tab_data}
                                  align="center"
                                >
                                  {" "}
                                  {viewpermissionPage === 3 ? (
                                    <CustomMultilineTextField
                                      // name={"Answer"}
                                      key={`${index + 1 + q_index} - Questions SO Remark`}
                                      rows={0}
                                      maxRows={4}
                                      required={true}
                                      label={""}
                                      readOnly={isPreviewMode}
                                      value={q_item?.SO_Remarks}
                                      onChange={(value) => handleCommonValueChange(index, q_index, value, "SO_Remarks")}
                                      error={Boolean(validationErrors[`${index}-${q_index}-SO_Remarks`])}
                                      helperText={validationErrors[`${index}-${q_index}-SO_Remarks`]}
                                    />
                                  ) : (
                                    q_item?.SO_Remarks
                                  )}
                                </TableCell>
                              )}
                              {/* do reView is show only Do screen and Do Step is available in Workflow and after do completed show so */}
                              {viewpermissionPage !== 1 &&
                                viewpermissionPage !== 5 &&
                                viewpermissionPage !== 2 &&
                                currentProcessData?.ConditionName != WorkflowCondition?.doApproval &&
                                checkstepAvailable("Do", processStepsList) && (
                                  <TableCell
                                    className={`${BasicTableStyle.tab_data} ${
                                      statusCheck(q_item?.DO_ReviewStatus) == vendorAssetStatus.approve
                                        ? PreviewStyle.approveSec
                                        : statusCheck(q_item?.DO_ReviewStatus) == vendorAssetStatus.reject
                                        ? PreviewStyle.rejectedSec
                                        : ""
                                    }`}
                                    align="center"
                                  >
                                    {" "}
                                    {viewpermissionPage === 4 ? (
                                      <CustomSelect
                                        name={""}
                                        valueKey={"LovId"}
                                        optionLabelKey="Options"
                                        options={ApproveList}
                                        readOnly={isPreviewMode}
                                        required={true}
                                        label={""}
                                        value={q_item?.DO_ReviewStatus}
                                        onChange={(value) =>
                                          handleCommonValueChange(index, q_index, value, "DO_ReviewStatus")
                                        }
                                        error={Boolean(validationErrors[`${index}-${q_index}-DO_ReviewStatus`])}
                                        helperText={validationErrors[`${index}-${q_index}-DO_ReviewStatus`]}
                                      />
                                    ) : (
                                      statusCheck(q_item?.DO_ReviewStatus)
                                    )}
                                  </TableCell>
                                )}
                              {/* do remark is show only Do screen and Do Step is available in Workflow and after do completed show so */}
                              {viewpermissionPage !== 1 &&
                                viewpermissionPage !== 5 &&
                                viewpermissionPage !== 2 &&
                                currentProcessData?.ConditionName != WorkflowCondition?.doApproval &&
                                checkstepAvailable("Do", processStepsList) && (
                                  <TableCell className={BasicTableStyle.tab_data} align="center">
                                    {viewpermissionPage === 4 ? (
                                      <CustomMultilineTextField
                                        // name={"Answer"}
                                        key={`${index + 1 + q_index} - Questions  Do remark`}
                                        rows={0}
                                        maxRows={4}
                                        required={true}
                                        label={""}
                                        readOnly={isPreviewMode}
                                        value={q_item?.DO_Remarks}
                                        onChange={(value) =>
                                          handleCommonValueChange(index, q_index, value, "DO_Remarks")
                                        }
                                        error={Boolean(validationErrors[`${index}-${q_index}-DO_Remarks`])}
                                        helperText={validationErrors[`${index}-${q_index}-DO_Remarks`]}
                                      />
                                    ) : (
                                      q_item?.DO_Remarks
                                    )}
                                  </TableCell>
                                )}
                              {(viewpermissionPage === 2 || viewpermissionPage === 3 || viewpermissionPage === 6) &&
                                (currentProcessData?.ConditionName == WorkflowCondition?.doApproval ||
                                  viewpermissionPage === 6) &&
                                checkstepAvailable("Do", processStepsList) && (
                                  <TableCell className={BasicTableStyle.tab_data} align="center">
                                    {" "}
                                    <CustomCheckBox
                                      key={`${index + 1 + q_index} - Questions is Do`}
                                      Width={"10%"}
                                      label=""
                                      isStyle={false}
                                      readOnly={view || DOcheck || isPreviewMode}
                                      value={q_item?.IsAddInDo}
                                      onChange={(event) =>
                                        handleCommonValueChange(index, q_index, event.target.checked, "IsAddInDo")
                                      }
                                    />{" "}
                                  </TableCell>
                                )}
                              {((viewpermissionPage === 3 &&
                                currentProcessData?.ConditionName != WorkflowCondition?.doApproval) ||
                                viewpermissionPage === 4 ||
                                viewpermissionPage === 6) && (
                                <TableCell className={BasicTableStyle.tab_data} align="center">
                                  {" "}
                                  <CustomCheckBox
                                    key={`${index + 1 + q_index} - Questions  is PM check`}
                                    Width={"10%"}
                                    label=""
                                    isStyle={true}
                                    readOnly={(viewpermissionPage !== 4 && viewpermissionPage !== 3) || isPreviewMode}
                                    value={q_item?.IsAddInPM}
                                    onChange={(event) =>
                                      handleCommonValueChange(index, q_index, event.target.checked, "IsAddInPM")
                                    }
                                  />{" "}
                                </TableCell>
                              )}
                            </TableRow>
                          ) : null
                        )}
                    </>
                  ) : null;
                })}
              </TableBody>
            </Table>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <Typography>No Assessment data Available</Typography>
          </Box>
        </>
      )}
      <CustomDialog
        show={isfileUpload}
        onHide={() => {
          fileUploadClickClose();
        }}
        maxWidth={"sm"}
        header={"Upload Files"}
        contentNode={
          <>
            <CustomFileUploader
              onFileUpload={(value) => {
                SelectedFileUpload(value);
              }}
              maxFileSize={5242880}
              acceptFormats={[".png", ".jpeg", ".jpg", ".pdf", ".docx"]}
            />
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "right",
                mt: 2,
              }}
            >
              <>
                <CustomButton
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    fileUploadClickClose();
                  }}
                />

                <CustomButton
                  variant="contained"
                  disabled={!attachmentFile}
                  name={"Save"}
                  onClick={() => uploadFile()}
                />
              </>
            </Box>
          </>
        }
      />

      <CustomDialog
        show={isfilePreview}
        onHide={() => {
          setIsfilePreview(false);
        }}
        key={"VOB File Upload"}
        maxWidth={"lg"}
        header={"File Preview"}
        contentNode={
          <>
            <FilteViewer URL={isfilePreviewurl} fileName={fileName} />
          </>
        }
      />
    </>
  );
};
