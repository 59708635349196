import { AxiosError, AxiosResponse } from "axios";
import { EVENT_LOGS } from "./endpoints/eventLogs";
import { axiosInstance } from "./config/axiosConfig";
import { IEventLogRequest } from "../../models/eventLogs.types";

const addEventLog = (requestBody: any): Promise<any> => {
  return axiosInstance
    .post(EVENT_LOGS.AddEventLog, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Adding Event Log" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};

const getEventLogsByFilter = (requestBody: IEventLogRequest): Promise<any> => {
  return axiosInstance
    .post(EVENT_LOGS.GetEventLogsByFilter, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Retrieving Event Log" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};

const eventLogService = {
  addEventLog,
  getEventLogsByFilter
};

export default eventLogService;
